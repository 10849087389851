import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchHersheysTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/hersheys/getTemplates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchHersheysStoresAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheys/getMasterData?templateId=" + id
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const filterHersheysStoresAction = ({
  value,
  region,
  city,
  status,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/hersheys/filterAudits?templateId=${value}&region=${region}&city=${city}&status=${status}`
      )
      .then(function (response) {
        dispatch({
          type: types.FILTER_HERSHEYS_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(false);
      })
      .catch(function (error) {
        dispatch({
          type: types.FILTER_HERSHEYS_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure(true);
      });
  };
};

export const AssignAuditsAction = ({ assignData, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v1/hersheys/multiAssignAudits",
        assignData
      )
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchHersheysAuditAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/hersheys/getAudit?storeId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveHersheysAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/hersheys/submit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_HERSHEYS_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadHersheysReportAction = ({
  templateId,
  emailIds,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/report/hersheys?templateId=${templateId}&emailIds=${emailIds}`,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        onSuccess();
        // FileSaver.saveAs(response.data, "HersheysAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const downloadHersheysAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheys/report/export?auditId=" + auditId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchAuditorsDetailAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/hersheys/auditors")
      .then(function (response) {
        dispatch({
          type: types.FETCH_AUDITORSDETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_AUDITORSDETAILS_FAILIURE_ACTION,
          payload: error,
        });
      });
  };
};

export const getAuditReports = ({
  templateId,
  startDate,
  endDate,
  email,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/hersheys/bulkReportDownload?templateId=${templateId}&startDate=${startDate}&endDate=${endDate}&email=${email}`
      )
      .then((response) => {
        // FileSaver.saveAs(response.data, "hersheys.zip");
        dispatch({
          type: types.get_audit_reports_success,
          payload: response.data,
        });
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: types.get_audit_reports_failure,
          payload: error,
        });
        onFailure();
      });
  };
};

export const getAuditCount = (templateId, startDate, endDate) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/hersheys/auditsCount?templateId=${templateId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        dispatch({
          type: types.get_audit_count_success,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.get_audit_count_failure,
          payload: error,
        });
      });
  };
};

export const fetchHersheysVisibilityTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/hersheysVisibility/getTemplates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};


export const fetchHersheysVisibilityStoresAction = ({ id, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheysVisibility/getMasterData?templateId=" + id
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const HerysheysVisibilityAssignAuditsAction = ({ assignData, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v1/hersheysVisibility/assignAudits",
        assignData
      )
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchHersheysVisibilityAuditAction = (id) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/hersheysVisibility/getAudit?storeId=" + id)
      .then(function (response) {
        dispatch({
          type: types.FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_HERSHEYS_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveHersheysVisibilityAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/hersheysVisibility/submit", audit)
      .then(function (response) {
        dispatch({
          type: types.SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_HERSHEYS_AUDIT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadHersheysVisibilityAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheysVisibility/pdfReport?auditId=" + auditId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.pdf");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};


export const downloadHersheysVisibilityReportAction = ({
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/hersheysVisibility/report?templateId=" + templateId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        onSuccess();
        FileSaver.saveAs(response.data, "HersheysVisibilityAuditReport.xlsx");
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};