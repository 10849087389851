import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isAdmin } from "./../util";
import Dashboard from "./Dashboard.jsx";
import TanishqOrNaturals from "./TanishqOrNaturals";
import AgentAudits from "./AgentAudits.jsx";
import DaimlerAuditsByMonth from "./Daimler/DaimlerAuditsByMonth";
import VolkswagenAuditsByMonth from "./Volkswagen/VolkswagenAuditsByMonth";
import PandG from "./procter_and_gamble/PandG";
import Preethi from "./preethi_photo/Preethi.jsx";
import Godrej from "./godrej";
import Yamaha from "./Yamaha/Yamaha";
import Furlenco from "./Furlenco/Furlenco";
import DiamlerKeyTemplate from './Diamler_key_management/DiamlerKeyTemplates'
import JandJTemplatesView from "./johnsonAndJohnson/JandJTemplatesView";
import UdaanTemplates from "./udaan/UdaanTemplates";
import Hersheys from "./hersheys/Hersheys";
import HersheysCompetition from "./hersheys_competition/HersheysCompetition";
import HVTemplates from "./Hersheys_Visibility/HVTemplates";
import BikanoTemplates from "./Bikano_Damages/BikanoTemplates";
import MrRetialIndex from "./MrRetail/MrRetialIndex";
import CannonIndex from "./Cannon/CannonIndex";
import OlaTemplates from "./OLA/OlaTemplates";
import DTCTemplates from "./DTC/Templates";
import PGDTemplates from "./PGD/Templates";
import Bank from "./Bank/Bank";
import Template from "./HershysChecklist/Template";
import { changeTheme } from "../actions/index";
import { selectMenuAction, logoutAction } from "../actions/index";
import { Segment, Sidebar, Menu } from "semantic-ui-react";

import TabViewTimeSheet from "./TimeSheet/TabViewTimeSheet";
import {
  getMainBackground,
  getMatchingTint,

} from "../ThemeHandlers/theme";
import "../styles/Theme.css";
import ClientDashboards from "./Dashboard/ClientDashboard.jsx";

import HorizontalNavbarContent from "./commonViews/HorizontalNavbarContent";
import FormMenuItem from "./commonViews/FormMenuItem";
import PGCTemplates from "./PGC/PGCTemplates";



class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: true,
      color: "",
    };
  }

  handleItemClick = (e, { name }) => {
    this.props.selectMenu(name);
  };

  logout = () => {
    this.props.logout();
  };

  componentWillMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    // console.log("Orgs List : ",this.props.auth.orgs);
  }

  componentDidMount() {
    if (localStorage.getItem("themeColor") !== null) {
      this.props.changeTheme(localStorage.getItem("themeColor"));
    }
    if (this.props.auth.color !== undefined) {
      this.setState({ color: this.props.auth.color });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(nextProps.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  toggleSidebar = () => {
    this.setState({ sideBarVisible: !this.state.sideBarVisible });
  };

  openControlPanel = () => {
    this.props.history.push(`/controlpanel`);
  };

  colorBlueChange = () => {
    this.props.changeTheme("blue");
    localStorage.setItem("themeColor", "blue");
  };

  colorTealChange = () => {
    this.props.changeTheme("orange");
    localStorage.setItem("themeColor", "orange");
  };

  colorYellowChange = () => {
    this.props.changeTheme("yellow");
    localStorage.setItem("themeColor", "yellow");
  };

  render() {
    var activeItem = this.props.home.selectedMenu || "";
    var pusherStyle = {
      height: "100vh",
      backgroundColor: getMatchingTint(this.state.color),

      overflow: "auto",
      width: "90%",
      border: 0,
    };
    if (!this.state.sideBarVisible) {
      pusherStyle.width = "100%";
    }
    var theme = "BLUE_THEME";
    var sideBarTheme = "blue";

    if (this.state.color === "blue") {
      theme = "BLUE_THEME";
      sideBarTheme = "blue";
    } else if (this.state.color === "yellow") {
      theme = "TEAL_THEME";
      sideBarTheme = "yellow";
    } else if (this.state.color === "orange") {
      theme = "ORANGE_THEME";
      sideBarTheme = "orange";
    }
    let menuOptions = [];
    if (isAdmin(this.props.auth)) {
      menuOptions.push(
        {
          name: "Control Panel",
          click: this.openControlPanel,
        },
        { name: "logout", click: this.logout }
      );
    } else {
      menuOptions.push({ name: "logout", click: this.logout });
    }

    return (
      <div>
        <div className={theme} style={{ backgroundColor: "#FFF" }}>
          <HorizontalNavbarContent
            toggleSidebar={this.toggleSidebar}
            ColorToBlue={this.colorBlueChange}
            colorToTeal={this.colorTealChange}
            colorToYellow={this.colorYellowChange}
            itemOptions={menuOptions}
            auth={this.props.auth}
          />
        </div>

        <Sidebar.Pushable
          as={Segment}
          style={{
            marginTop: -15,
            display: "flex",
            borderRadius: 0,
            height: "calc(100% - 70px)",
          }}
        >
          <Sidebar
            className={`${sideBarTheme}`}
            style={{
              backgroundColor: getMainBackground(this.state.color),
            }}
            as={Menu}
            visible={this.state.sideBarVisible}
            activeIndex="0"
            animation="slide along"
            width="thin"
            icon="labeled"
            vertical
          >
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5a0c1fd4709f16d6fe232142").length === 1 && (
              <FormMenuItem
                name={"audits"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"check"}
                title={"Audits"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5b4efae7b89f85c29de80f08").length === 1 && (
              <FormMenuItem
                name={"daimler"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"car"}
                title={"Daimler"}
              />
            )
            }

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5ac5ce8b3788c53c19c155e4").length === 1 && (
              <FormMenuItem
                name={"volkswagen"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"car"}
                title={"Volkswagen"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5b96aca9b443696fdd455599").length === 1 && (
              <FormMenuItem
                name={"pandg"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"certificate"}
                title={"P & G"}
              />
            )
            }
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5bd2aeeb301cb0c3d9cc7ccc").length === 1 && (
              <FormMenuItem
                name={"damages"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"recycle"}
                title={"Britannia Damages"}
              />
            )
            }

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5d1b5358d7646014cd5e0ed6").length === 1 && (
              <FormMenuItem
                name={"godrej"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"google"}
                title={"Godrej"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5dc8fe8db8e59747497e2ef4").length === 1 && (
              <FormMenuItem
                name={"DiamlerKeyTemplates"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"key"}
                title={"Daimler Key Management"}
              />
            )
            }

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5de7a27f0cd45a4cb475819e").length === 1 && (
              <FormMenuItem
                name={"Furlenco"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping"}
                title={"Furlenco"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5e788041781f3d7b2c7dffc3").length === 1 && (
              <FormMenuItem
                name={"johnsonAndJohnson"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping"}
                title={"Johnson & Johnson"}
              />
            )}

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5eec7fe7ce787bdb7bff1867").length === 1 && (
              <FormMenuItem
                name={"grb"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"glide g"}
                title={"GRB"}
              />
            )} */}

            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f0e8b94ce787bdb7b1c6a21").length === 1 && (
              <FormMenuItem
                name={"udaan"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping fast"}
                title={"Udaan"}
              />
            )} */}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f59b94ad524acc50845fc79").length === 1 && (
              <FormMenuItem
                name={"hersheys"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping fast"}
                title={"Hersheys"}
              />
            )}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "62d4ffac4f6d91f78fd5439c").length === 1 && (
              <FormMenuItem
                name={"hersheys competition"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shipping fast"}
                title={"Hersheys Competition"}
              />
            )}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5f6c3b52d524acc508509b31").length === 1 && (
              <FormMenuItem
                name={"mrRetail"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"shopping basket"}
                title={"Mr-Retail"}
              />
            )}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5fb204f4d524acc508839783").length === 1 && (
              <FormMenuItem
                name={"cannon"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"camera"}
                title={"Cannon"}
              />
            )}
            {/* {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "5fbcbcd07cc708707aed2410").length === 1 && (
              <FormMenuItem
                name={"bank"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"inr"}
                title={"ATM"}
              />
            )} */}
            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "61bc1a124f2dc40fd89cbab3").length === 1 && (
              <FormMenuItem
                name={"hershysChecklist"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"calendar check outline"}
                title={"Hersheys Checklist"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "6225953724d137f8d12a4b79").length === 1 && (
              <FormMenuItem
                name={"hershysVisibility"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"calendar check outline"}
                title={"Hersheys Visibility Audit"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "6233347024d137f8d13bad0b").length === 1 && (
              <FormMenuItem
                name={"ola"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"taxi"}
                title={"OLA"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "62614d7c24d137f8d15a965f").length === 1 && (
              <FormMenuItem
                name={"dtc"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"certificate"}
                title={"DTC Claim Pickup"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "627de508e98b9f31430872da").length === 1 && (
              <FormMenuItem
                name={"pgd"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"doctor"}
                title={"Doctor Visit Survey"}
              />
            )}


            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "62726f1ea106f39f3c0ff0af").length === 1 && (
              <FormMenuItem
                name={"bikano"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"circle"}
                title={"Bikano Damages"}
              />
            )}

            {this.props.auth.orgs && this.props.auth.orgs.filter((o) => o === "62b17fc34be7d77f50408804").length === 1 && (
              <FormMenuItem
                name={"pgc"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"doctor"}
                title={"P&G Health Audit"}
              />
            )}


            {this.props.auth.role !== "viewer" && this.props.auth.timesheetAccess !== undefined && this.props.auth.timesheetAccess !== "" && (
              <FormMenuItem
                name={"time"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"time"}
                title={"Time Sheets"}
              />
            )}
            {this.props.auth.access === "dashboard" && (
              <FormMenuItem
                name={"clientDashboards"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"dashboard"}
                title={"Client Dashboard"}
              />
            )}

            {this.props.auth.access === "dashboard" && (
              <FormMenuItem
                name={"dashboard"}
                activeItem={activeItem}
                color={this.state.color}
                itemClick={this.handleItemClick}
                iconName={"dashboard"}
                title={"Common Dashboard"}
              />
            )}


          </Sidebar>
          <Sidebar.Pusher style={pusherStyle}>
            <Segment
              basic
              style={{
                display: "flex",
                flex: 1,
                padding: "0px 0px 0px 0px",
                margin: 5
              }}
            >
              {this.props.auth.access == "dashboard" && (
                <div>
                  {activeItem === "dashboard" && (
                    <Dashboard access={"common_dashboard"} />
                  )}
                </div>
              )}

              {activeItem === "audits" && (<TanishqOrNaturals client={"Tanishq"} />)}
              {activeItem === "agentVerification" && <AgentAudits />}
              {/* {activeItem === "volkswagen" && (<VolkswagenAuditsByMonth />)} */}
              {activeItem === "daimler" && (<DaimlerAuditsByMonth />)}
              {activeItem === "pandg" && (<PandG />)}
              {activeItem === "damages" && (<TanishqOrNaturals client={"Damages"} />)}
              {/* {activeItem === "preethi" && <Preethi />} */}
              {activeItem === "godrej" && (<Godrej />)}
              {/* {activeItem === "yamaha" && (
                <Yamaha org={"5d84a0f20b230667c5cbc601"} />
              )} */}
              {activeItem === "DiamlerKeyTemplates" && (<DiamlerKeyTemplate />)}

              {/* {activeItem === "Furlenco" && (
                <Furlenco org={"5de7a27f0cd45a4cb475819e"} />
              )} */}
              {activeItem === "johnsonAndJohnson" && (
                <JandJTemplatesView org={"5e788041781f3d7b2c7dffc3"} />
              )}
              {/* {activeItem === "Naturals" && (<TanishqOrNaturals client={"Naturals"} />)} */}
              {activeItem === "clientDashboards" && (
                <ClientDashboards color={this.state.color} />
              )}
              {activeItem === "grb" && (<TanishqOrNaturals client={"grb"} />)}
              {/* {activeItem === "udaan" && (
                <UdaanTemplates org={"5f0e8b94ce787bdb7b1c6a21"} />
              )} */}
              {activeItem === "hersheys" && (
                <Hersheys org={"5f59b94ad524acc50845fc79"} />
              )}
              {activeItem === "hersheys competition" && (
                <HersheysCompetition org={"62d4ffac4f6d91f78fd5439c"} />
              )}
              {activeItem === "mrRetail" && (
                <MrRetialIndex org={"5f6c3b52d524acc508509b31"} />
              )}
              {activeItem === "cannon" && (
                <CannonIndex org={"5fb204f4d524acc508839783"} />
              )}
              {/* {activeItem === "bank" && (
                <Bank org={"5fbcbcd07cc708707aed2410"} />
              )} */}
              {activeItem === "hershysChecklist" && (
                <Template org={"61bc1a124f2dc40fd89cbab3"} />
              )}
              {activeItem === "hershysVisibility" && (
                <HVTemplates org={"6225953724d137f8d12a4b79"} />
              )}
              {activeItem === "ola" && (
                <OlaTemplates org={"6233347024d137f8d13bad0b"} />
              )}
              {activeItem === "dtc" && (
                <DTCTemplates org={"62614d7c24d137f8d15a965f"} />
              )}
              {activeItem === "pgd" && (
                <PGDTemplates org={"62614d7c24d137f8d15a965f"} />
              )}
              {activeItem === "bikano" && (
                <BikanoTemplates org={"62726f1ea106f39f3c0ff0af"} />
              )}

              {activeItem === "pgc" && (
                <PGCTemplates org={"62b17fc34be7d77f50408804"} />
              )}

              {activeItem === "time" && <TabViewTimeSheet />}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout: logoutAction,
      selectMenu: selectMenuAction,
      changeTheme: changeTheme,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
