import React from "react";
import {
  Header,
  Dropdown,
  Input,
  Form,
  TextArea,
  Radio,
  Segment
} from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import moment from "moment";

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import TextInput from "../commonViews/TextInput";
class DaimlerAudit extends React.Component {
  constructor(props) {
    super(props);

    var soldInvoice = this.props.audit.soldInvoice || {};


    var dealer = this.props.dealer || {};
    var dealerAssessment = dealer.dealerAssessment || {};
    this.state = {
      invoiceDate: this.props.audit.invoiceDate ? new Date(this.props.audit.invoiceDate) : null,
      invoiceNo: soldInvoice.no || "",
      gatePassDate: this.props.audit.gatePassDate
        ? new Date(this.props.audit.gatePassDate)
        : "",
      gatePassNo: this.props.audit.gatePassNo || "",
      vehicleStatus: this.props.audit.questions[0].answer,
      storeRoom: this.props.audit.storeRoom,
      notes: this.props.audit.notes,
      isInvoiceShown: dealerAssessment.isInvoiceShown,
      vehicleCondition: this.props.audit.vehicleCondition,
      odometerReading: this.props.audit.odometerReading,
      gatePassUploaded: this.props.audit.gatepassCopyUploaded,
      eventName: this.props.audit.eventName,
      eventLocation: this.props.audit.eventLocation,
      noOfDaysSent: this.props.audit.daysSent,
      expectedReturn: this.props.audit.expectedReturnDate,
      doDate: this.props.audit.doDate
        ? new Date(this.props.audit.doDate)
        : null,
      doFinancier: this.props.audit.doFinancier,
      customerName: this.props.audit.customerName,
      finalPaymentFromCustomer: this.props.audit.paymentReceivedFromCustDate
        ? new Date(this.props.audit.paymentReceivedFromCustDate)
        : null,
      paymentToDfsi: this.props.audit.paymentToDFSIDate,
      doCopyUploaded: this.props.audit.doCopyUploaded,
      form21Uploaded: this.props.audit.form21Available,
      form22Uploaded: this.props.audit.form22Available,
      hypothecationDetails: this.props.audit.hypothecationDetails
    };

    this.props.newAudit.vehicleStatus = this.state.vehicleStatus;
    this.props.newAudit.storeRoom = this.state.storeRoom;
    this.props.newAudit.invoiceNo = this.state.invoiceNo;
    this.props.newAudit.invoiceDate = this.state.invoiceDate;
    this.props.newAudit.notes = this.state.notes;
    this.props.newAudit.isInvoiceShown = this.state.isInvoiceShown;
    this.props.newAudit.vehicleCondition = this.state.vehicleCondition;
    this.props.newAudit.odometerReading = this.state.odometerReading;
    this.props.newAudit.gatepassCopyUploaded = this.state.gatePassUploaded;
    this.props.newAudit.eventName = this.state.eventName;
    this.props.newAudit.eventLocation = this.state.eventLocation;
    this.props.newAudit.daysSent = this.state.noOfDaysSent;
    this.props.newAudit.expectedReturnDate = this.state.expectedReturn;
    this.props.newAudit.doDate = this.state.doDate;
    this.props.newAudit.doFinancier = this.state.doFinancier;
    this.props.newAudit.customerName = this.state.customerName;
    this.props.newAudit.paymentReceivedFromCustDate = this.state.finalPaymentFromCustomer;
    this.props.newAudit.paymentToDFSIDate = this.state.paymentToDfsi;
    this.props.newAudit.doCopyUploaded = this.state.doCopyUploaded;
    this.props.newAudit.form21Available = this.state.form21Uploaded;
    this.props.newAudit.form22Available = this.state.form22Uploaded;
    this.props.newAudit.hypothecationDetails = this.state.hypothecationDetails;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });

  }

  handleStoreRoomChanged = (data) => {
    this.setState({ storeRoom: data.value });
    this.props.newAudit.storeRoom = data.value;
  };

  onInvoiceNoChanged = (data) => {
    this.setState({ invoiceNo: data.target.value });
    this.props.newAudit.invoiceNo = data.target.value;
  };

  onInvoiceDateChange = value => {
    this.setState({ invoiceDate: value });
    this.props.newAudit.invoiceDate = value;
  };

  onGatePassNoChanged = (data) => {
    this.setState({ gatePassNo: data.target.value });
    this.props.audit.gatePassNo = data.target.value;
  };

  onGatePassDateChange = value => {
    this.setState({ gatePassDate: value });
    this.props.audit.gatePassDate = value;
  };


  onNotesChange = (e, data) => {
    this.setState({ notes: data.value });
    this.props.newAudit.notes = data.value;
  };


  onInvoiceRadioButtonChanged = (e, data) => {
    this.setState({ isInvoiceShown: data.checked });
    this.props.newAudit.isInvoiceShown = data.checked;
  };


  onVehicleConditionChange = (e, data) => {
    this.setState({ vehicleCondition: data.value });
    this.props.newAudit.vehicleCondition = data.value;
  };

  onOdometerChange = data => {
    this.setState({ odometerReading: data.target.value });
    this.props.newAudit.odometerReading = data.target.value;
  };

  onGatepassUploadedChange = (e, data) => {
    this.setState({ gatePassUploaded: data.value });
    this.props.audit.gatepassCopyUploaded = data.value;
  };

  onEventNameChange = data => {
    this.setState({ eventName: data.target.value });
    this.props.newAudit.eventName = data.target.value;
  };

  onEventLocationChange = data => {
    this.setState({ eventLocation: data.target.value });
    this.props.newAudit.eventLocation = data.target.value;
  };

  onNoOfDaysSentChange = data => {
    this.setState({ noOfDaysSent: data.target.value });
    this.props.newAudit.daysSent = data.target.value;
  };

  onExpectedReturnChange = data => {
    this.setState({ expectedReturn: data.target.value });
    this.props.newAudit.expectedReturnDate = data.target.value;
  };

  onDoDateChange = data => {
    this.setState({ doDate: data });
    this.props.newAudit.doDate = data;
  };

  onDoFinancierChange = data => {
    this.setState({ doFinancier: data.target.value });
    this.props.newAudit.doFinancier = data.target.value;
  };

  onCustomerNameChange = data => {
    this.setState({ customerName: data.target.value });
    this.props.audit.customerName = data.target.value;
  };

  onPaymentFromCustomerChange = data => {
    this.setState({ finalPaymentFromCustomer: data });
    this.props.audit.paymentReceivedFromCustDate = data;
  };

  onPaymentToDfsiChange = data => {
    this.setState({ paymentToDfsi: data.target.value });
    this.props.newAudit.paymentToDFSIDate = data.target.value;
  };

  onDoCopyUploadChange = (e, data) => {
    this.setState({ doCopyUploaded: data.value });
    this.props.newAudit.doCopyUploaded = data.value;
  };

  onFrom21AvailableChange = (e, data) => {
    this.setState({ form21Uploaded: data.value });
    this.props.newAudit.form21Available = data.value;
  };

  onForm22AvailableChange = (e, data) => {
    this.setState({ form22Uploaded: data.value });
    this.props.newAudit.form22Available = data.value;
  };

  onHypothecationChange = (e, data) => {
    this.setState({ hypothecationDetails: data.value });
    this.props.newAudit.hypothecationDetails = data.value;
  };

  handleOtherLocationChange = data => {
    this.setState({ storeRoom: data.target.value });
    this.props.newAudit.storeRoom = data.target.value;
  };

  uploadPhoto = data => {
    this.props.newAudit.photo = data.target.files;
  };

  isDisableIfFound = data => {
    if (this.props.audit.vehicleCondition !== 4) {
      if (this.state.vehicleStatus === 4) {
        return data != "" ? true : false;
        //   if (data != "") {
        //     return true;
        //   } else {
        //     return false;
        //   }
      }
    }
  };

  handleVehicleStatusChanged = (e, data) => {
    this.setState({ vehicleStatus: data.value });
    this.props.newAudit.vehicleStatus = data.value;
  };

  parseDate = (str, format, locale) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    // if (DateUtils.isDate(parsed)) {
    //   return parsed;
    // }
    return undefined;
  }

  formatDate = (date, format, locale) => {
    return dateFnsFormat(date, format, { locale });
  }



  render() {
    var audit = this.props.audit;



    var cooperationScaleOptions = [
      {
        text: "1 - Satisfactory",
        value: "1 - Satisfactory"
      }
    ];

    var vehicleStatusOptions = [
      {
        text: "Demo",
        value: 1
      },
      {
        text: "Transit",
        value: 2
      },
      {
        text: "Exhibition",
        value: 3
      },
      {
        text: "Not found - Sold",
        value: 4
      },
      {
        text: "Found",
        value: 5
      },
      {
        text: "Other reasons",
        value: 6
      }
    ];

    var storeRoomOptions = [
      { text: "Showroom", value: "Showroom" },
      { text: "Yard", value: "Yard" },
      { text: "Others", value: "Others" }
    ];
    return (
      <React.Fragment>
        <Segment.Group>
          <Segment>
            <Input
              type="file"
              content="Upload additional Photos"
              icon="cloud upload"
              labelPosition="right"
              onChange={this.uploadPhoto}
              multiple
            />
          </Segment>
        </Segment.Group>
        <Segment.Group>
          <Segment>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              {audit.questions[0].question}
            </span>
            <Dropdown
              style={{ marginLeft: "22px" }}
              onChange={this.handleVehicleStatusChanged}
              placeholder="Select your vehicle status"
              defaultValue={this.state.vehicleStatus}
              options={vehicleStatusOptions}
            />
          </Segment>
          <Segment>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>Storeroom</span>
            <Dropdown
              style={{ marginLeft: "22px" }}
              onChange={this.handleStoreRoomChanged}
              placeholder="Storeroom"
              defaultValue={
                this.state.storeRoom === "Showroom" ||
                  this.state.storeRoom === "Yard"
                  ? this.state.storeRoom
                  : "Others"
              }
              options={storeRoomOptions}
              disabled={this.isDisableIfFound(this.props.audit.storeRoom)}
            />
          </Segment>
          {this.state.storeRoom != "Showroom" && this.state.storeRoom != "Yard"
            ?
            <TextInput styles={{
              marginLeft: "22px",
              width: "220px",
              marginBottom: "10px"
            }}
              placeholder={"Other locations.."}
              value={this.state.storeRoom}
              onChangeTextInput={(e) => { this.handleOtherLocationChange(e) }}
            />
            // <Input
            //     style={{
            //       marginLeft: "22px",
            //       width: "220px",
            //       marginBottom: "10px"
            //     }}
            //     onChange={this.handleOtherLocationChange}
            //     placeholder="Other locations.."
            //     value={this.state.storeRoom}
            //   />
            : null}
          <Segment>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Vehicle Condition:{" "}
              </span>
              <span style={{ marginLeft: "22px" }}>
                <Radio
                  label={"Good"}
                  name={"vehicleCondition"}
                  value={"good"}
                  checked={this.state.vehicleCondition === "good"}
                  onChange={this.onVehicleConditionChange}
                  disabled={this.isDisableIfFound(
                    this.props.audit.vehicleCondition
                  )}
                />
                <Radio
                  label={"Damage"}
                  name={"vehicleCondition"}
                  value={"damage"}
                  checked={this.state.vehicleCondition === "damage"}
                  onChange={this.onVehicleConditionChange}
                  disabled={this.isDisableIfFound(
                    this.props.audit.vehicleCondition
                  )}
                />
              </span>
            </div>
          </Segment>
          <Segment>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Odometer Reading:{" "}
              </span>
              <TextInput
                styles={{
                  marginLeft: "22px", width: "120px"
                }}
                placeholder={"Odometer reading.."}
                value={this.state.odometerReading}
                onChangeTextInput={(e) => { this.onOdometerChange(e) }}
                disabled={this.isDisableIfFound(
                  this.props.audit.odometerReading
                )}
              />
              {/* <Input
                style={{ marginLeft: "22px", width: "120px" }}
                onChange={this.onOdometerChange}
                placeholder="Odometer reading: "
                value={this.state.odometerReading}
                disabled={this.isDisableIfFound(
                  this.props.audit.odometerReading
                )}
              /> */}
            </div>
          </Segment>
          {this.state.vehicleStatus === 3 &&
            <Segment>
              <Segment>
                <div>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Gate Pass No
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    placeholder={"Gate Pass No.."}
                    value={this.state.gatePassNo}
                    onChangeTextInput={(e) => { this.onGatePassNoChanged(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onGatePassNoChanged}
                    placeholder="Gate Pass No"
                    value={this.state.gatePassNo}
                  /> */}
                </div>
                <div style={{ marginTop: 15 }}>
                  <span
                    style={{
                      marginRight: "9px",
                      fontSize: 15,
                      fontWeight: "bold"
                    }}
                  >
                    Gate Pass Date
                  </span>
                  <DayPickerInput
                    formatDate={formatDate}
                    style={{ marginLeft: 22 }}
                    parseDate={parseDate}
                    format="ll"
                    placeholder="Select a Date"
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                    }}
                    value={
                      this.state.gatePassDate &&
                      formatDate(moment(this.state.gatePassDate), "ll")
                    }
                    onDayChange={this.onGatePassDateChange}
                  />
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Gate Pass Uploaded:
                  </span>
                  <span style={{ marginLeft: "22px" }}>
                    <Radio
                      label={"Yes"}
                      name={"gatePassUploaded"}
                      value={true}
                      checked={this.state.gatePassUploaded === true}
                      onChange={this.onGatepassUploadedChange}
                    />
                    <Radio
                      label={"No"}
                      name={"gatePassUploaded"}
                      value={false}
                      checked={this.state.gatePassUploaded === false}
                      onChange={this.onGatepassUploadedChange}
                    />
                  </span>
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Event name:
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.eventName}
                    onChangeTextInput={(e) => { this.onEventNameChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onEventNameChange}
                    value={this.state.eventName}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Event Location:
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.eventLocation}
                    onChangeTextInput={(e) => { this.onEventLocationChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onEventLocationChange}
                    value={this.state.eventLocation}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    No. of days it was sent for:
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.noOfDaysSent}
                    onChangeTextInput={(e) => { this.onNoOfDaysSentChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onNoOfDaysSentChange}
                    value={this.state.noOfDaysSent}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Expected return:
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.expectedReturn}
                    onChangeTextInput={(e) => { this.onExpectedReturnChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onExpectedReturnChange}
                    value={this.state.expectedReturn}
                  /> */}
                </div>
              </Segment>
            </Segment>}
          {this.state.vehicleStatus === 4 &&
            <Segment>
              <Segment>
                <div>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Sold Invoice No
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.invoiceNo}
                    placeholder={"Invoice No"}

                    onChangeTextInput={(e) => { this.onInvoiceNoChanged(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onInvoiceNoChanged}
                    placeholder="Invoice No"
                    value={this.state.invoiceNo}
                  /> */}
                </div>
                <div style={{ marginTop: 15 }}>
                  <span
                    style={{
                      marginRight: "9px",
                      fontSize: 15,
                      fontWeight: "bold"
                    }}
                  >
                    Sold Invoice Date
                  </span>
                  <DayPickerInput
                    formatDate={formatDate}
                    style={{ marginLeft: 22 }}
                    parseDate={parseDate}
                    format="ll"
                    placeholder="Select a Date"
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                    }}
                    value={
                      this.state.invoiceDate &&
                      formatDate(moment(this.state.invoiceDate), "ll")
                    }
                    onDayChange={this.onInvoiceDateChange}
                  />
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    DO Date:{" "}
                  </span>
                  <DayPickerInput
                    formatDate={formatDate}
                    style={{ marginLeft: 22 }}
                    parseDate={parseDate}
                    format="ll"
                    placeholder="Select a Date"
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                    }}
                    value={
                      this.state.doDate &&
                      formatDate(moment(this.state.doDate), "ll")
                    }
                    onDayChange={this.onDoDateChange}
                  />
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    DO Financier:{" "}
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.doFinancier}

                    onChangeTextInput={(e) => { this.onDoFinancierChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onDoFinancierChange}
                    value={this.state.doFinancier}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Customer Name:{" "}
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.customerName}

                    onChangeTextInput={(e) => { this.onCustomerNameChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onCustomerNameChange}
                    value={this.state.customerName}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Final payment received from customer/retail financier:{" "}
                  </span>
                  <DayPickerInput
                    formatDate={formatDate}
                    style={{ marginLeft: 22 }}
                    parseDate={parseDate}
                    format="ll"
                    placeholder="Select a Date"
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                    }}
                    value={
                      this.state.finalPaymentFromCustomer &&
                      formatDate(moment(this.state.finalPaymentFromCustomer), "ll")
                    }
                    onDayChange={this.onPaymentFromCustomerChange}
                  />
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Last Credit Amount as per Ledger:{" "}
                  </span>
                  <TextInput
                    styles={{
                      marginLeft: "22px", width: "120px"
                    }}
                    value={this.state.paymentToDfsi}

                    onChangeTextInput={(e) => { this.onPaymentToDfsiChange(e) }}

                  />
                  {/* <Input
                    style={{ marginLeft: "22px", width: "120px" }}
                    onChange={this.onPaymentToDfsiChange}
                    value={this.state.paymentToDfsi}
                  /> */}
                </div>
              </Segment>
              <Segment>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 15, fontWeight: "bold" }}>
                    Payment Remarks:{" "}
                  </span>
                  <span style={{ marginLeft: "22px" }}>
                    <Radio
                      label={"Payment done and date captured"}
                      name={"doCopyUploaded"}
                      value={"Payment done and date captured"}
                      checked={
                        this.state.doCopyUploaded ===
                        "Payment done and date captured"
                      }
                      onChange={this.onDoCopyUploadChange}
                    />
                    <br />
                    <Radio
                      label={"Payment not done by Customer to dealer"}
                      name={"doCopyUploaded"}
                      value={"Payment not done by Customer to dealer"}
                      checked={
                        this.state.doCopyUploaded ===
                        "Payment not done by Customer to dealer"
                      }
                      onChange={this.onDoCopyUploadChange}
                    />
                    <br />
                    <Radio
                      label={"Dealer Not Provided details"}
                      name={"doCopyUploaded"}
                      value={"Dealer Not Provided details"}
                      checked={
                        this.state.doCopyUploaded ===
                        "Dealer Not Provided details"
                      }
                      onChange={this.onDoCopyUploadChange}
                    />
                    <br />
                  </span>
                </div>
              </Segment>
            </Segment>}
          <Segment>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>Remarks</span>
            <br />
            <Form>
              <TextArea
                style={{
                  marginRight: "22px",
                  marginTop: "12px",
                  width: "100%"
                }}
                onChange={this.onNotesChange}
                value={this.state.notes}
              />
            </Form>
          </Segment>
          <Segment>
            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              Back office Details:{" "}
            </span>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Form 21:{" "}
              </span>
              <span style={{ marginLeft: "22px" }}>
                <Radio
                  label={"Available"}
                  name={"form21"}
                  value={true}
                  checked={this.state.form21Uploaded === true}
                  onChange={this.onFrom21AvailableChange}
                />
                <Radio
                  label={"Not Available"}
                  name={"form21"}
                  value={false}
                  checked={this.state.form21Uploaded === false}
                  onChange={this.onFrom21AvailableChange}
                />
              </span>
            </div>
          </Segment>
          <Segment>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Form 22:{" "}
              </span>
              <span style={{ marginLeft: "22px" }}>
                <Radio
                  label={"Available"}
                  name={"form22"}
                  value={true}
                  checked={this.state.form22Uploaded === true}
                  onChange={this.onForm22AvailableChange}
                />
                <Radio
                  label={"Not Available"}
                  name={"form22"}
                  value={false}
                  checked={this.state.form22Uploaded === false}
                  onChange={this.onForm22AvailableChange}
                />
              </span>
            </div>
          </Segment>
          <Segment>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 15, fontWeight: "bold" }}>
                Hypothecation Details - DFSI: :{" "}
              </span>
              <span style={{ marginLeft: "22px" }}>
                <Radio
                  label={"Available"}
                  name={"hypothecationDetails"}
                  value={true}
                  checked={this.state.hypothecationDetails === true}
                  onChange={this.onHypothecationChange}
                />
                <Radio
                  label={"Not Available"}
                  name={"hypothecationDetails"}
                  value={false}
                  checked={this.state.hypothecationDetails === false}
                  onChange={this.onHypothecationChange}
                />
              </span>
            </div>
          </Segment>

          <Segment style={{ display: "none" }}>
            <Header as="h3" block>
              {" "}Dealer Assessment{" "}
            </Header>
            <div>
              <span
                style={{ marginRight: "22px" }}
                style={{ fontSize: 15, fontWeight: "bold" }}
              >
                Invoice Shown{" "}
              </span>
              <Radio
                style={{ marginLeft: "50px" }}
                toggle
                label="No/Yes"
                onChange={this.onInvoiceRadioButtonChanged}
                checked={this.state.isInvoiceShown}
              />
            </div>
            <br />
            <div style={{ marginTop: "5px" }}>
              <span
                style={{ marginRight: "22px" }}
                style={{ fontSize: 15, fontWeight: "bold" }}
              >
                Cooperation Scale{" "}
              </span>
              <Dropdown
                style={{ marginLeft: "22px" }}
                onChange={this.onCooperationScaleChanged}
                placeholder="Select Cooperation Scale"
                defaultValue={this.state.cooperationScale}
                options={cooperationScaleOptions}
              />
            </div>
          </Segment>
        </Segment.Group>
      </React.Fragment>
    );
  }
}
export default DaimlerAudit;
