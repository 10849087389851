import axios from "axios";
import config from "../config.js";

export const API_URL = config["api_url"];

axios.defaults.baseURL = API_URL;

export const SELECT_MENU_ACTION = "select_menu";
export const CHANGE_COLOR = "CHANGE_COLOR";
export const LOGIN_SUCCESS_ACTION = "login_success";
export const LOGIN_FAILURE_ACTION = "login_failure";
export const FORGOT_PASSWORD_SUCCESS_ACTION = "forgot_password_success";
export const FORGOT_PASSWORD_FAILURE_ACTION = "forgot_password_failure";
export const LOGOUT_ACTION = "logout";

export const RESET_PASSWORD_SUCCESS_ACTION = "reset_password_success";
export const RESET_PASSWORD_FAILURE_ACTION = "reset_password_failure";

//userDetails
export const FETCH_USERDETAILS_SUCCESS_ACTION = "fetch_user_details_success";
export const FETCH_USERDETAILS_FAILIURE_ACTION = "fetch_user_details_failiure";
export const EDIT_USERDETAILS_SUCCESS_ACTION = "edit_user_details_success";
export const EDIT_USERDETAILS_FAILIURE_ACTION = "edit_user_details_failiure";
export const FETCH_ORGSDETAILS_SUCCESS_ACTION = "fetch_orgs_details_success";
export const FETCH_ORGSDETAILS_FAILIURE_ACTION = "fetch_orgs_details_failiure";
export const FETCH_DEALERSDETAILS_SUCCESS_ACTION =
  "fetch_dealer_details_success";
export const FETCH_DEALERSDETAILS_FAILIURE_ACTION =
  "fetch_dealer_details_failiure";
export const ADD_USERSDETAILS_SUCCESS_ACTION = "add_users_details_success";
export const ADD_USERSDETAILS_FAILIURE_ACTION = "add_users_details_failiure";
export const CHANGE_PASSWORD_SUCCESS_ACTION = "change_password_success_action";
export const CHANGE_PASSWORD_FAILURE_ACTION = "change_password_failure_action";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_USER_ACTION = "clear_user_action";

export const MODAL_OPEN = "modal_open";
export const MODAL_CLOSE = "modal_close";

export const FETCH_AUDITS_SUCCESS_ACTION = "fetch_audits_success";
export const FETCH_AUDITS_FAILURE_ACTION = "fetch_audits_failure";
export const ALL_READY_FETCHED_AUDITS_ACTION = "all_ready_fetched_audits";
export const SEARCH_AUDITS_SUCCESS_ACTION = "search_audits_success";
export const ALL_READY_SEARCHED_AUDITS_ACTION =
  "all_ready_fetched_search_audits";
export const CLEAR_SEARCHED_AUDITS_ACTION = "clear_search_audits_results";
export const DOWNLOAD_AUDITS_DETAILS_SUCCESS_ACTION =
  "DOWNLOAD_AUDITS_DETAILS_SUCCESS_ACTION";
export const DOWNLOAD_AUDITS_DETAILS_FAILURE_ACTION =
  "DOWNLOAD_AUDITS_DETAILS_FAILURE_ACTION";

export const FETCH_NATURALS_AUDITS_SUCCESS_ACTION = "fetch_audits_success";
export const FETCH_NATURALS_AUDITS_FAILURE_ACTION = "fetch_audits_failure";
export const ALL_READY_FETCHED_NATURALS_AUDITS_ACTION =
  "all_ready_fetched_audits";
export const SEARCH_NATURALS_AUDITS_SUCCESS_ACTION = "search_audits_success";
export const ALL_READY_SEARCHED_NATURALS_AUDITS_ACTION =
  "all_ready_fetched_search_audits";
export const CLEAR_SEARCHED_NATURALS_AUDITS_ACTION =
  "clear_search_audits_results";
export const DOWNLOAD_NATURALS_REPORT_PHOTO_SUCCESS_ACTION =
  "naturals_report_success";
export const DOWNLOAD_NATURALS_REPORT_PHOTO_FAILURE_ACTION =
  "naturals_reports_failure";
export const FETCH_AGENT_AUDITS_SUCCESS_ACTION = "fetch_agent_audits_success";
export const FETCH_AGENT_AUDITS_FAILURE_ACTION = "fetch_agent_audits_failure";

export const UPDATE_AUDIT_SUCCESS_ACTION = "update_audit_success";
export const UPDATE_AUDIT_FAILURE_ACTION = "update_audit_failure";

export const UPDATE_NATURALS_AUDIT_SUCCESS_ACTION = "update_audits_success";
export const UPDATE_NATURALS_AUDIT_FAILURE_ACTION = "update_audits_failure";

export const DOWNLOAD_REPORT_SUCCESS_ACTION = "download_report_success";
export const DOWNLOAD_REPORT_FAILURE_ACTION = "download_report_failure";

export const DOWNLOAD_NATURALS_REPORT_SUCCESS_ACTION =
  "download_report_success";
export const DOWNLOAD_NATURALS_REPORT_FAILURE_ACTION =
  "download_report_failure";

export const DOWNLOAD_REPORT_PHOTO_SUCCESS_ACTION =
  "download_report_photo_success";
export const DOWNLOAD_REPORT_PHOTO_FAILURE_ACTION =
  "download_report_photo_failure";

export const DOWNLOAD_AGENT_VERIFICATION_REPORT_SUCCESS_ACTION =
  "download_agent_verification_report_success";
export const DOWNLOAD_AGENT_VERIFICATION_REPORT_FAILURE_ACTION =
  "download_agent_verification_report_failure";

export const DOWNLOAD_AGENT_REPORT_SUCCESS_ACTION =
  "download_agent_report_success";
export const DOWNLOAD_AGENT_REPORT_FAILURE_ACTION =
  "download_agent_report_failure";

export const MAIL_REPORT_SUCCESS_ACTION = "mail_report_success";
export const MAIL_REPORT_FAILURE_ACTION = "mail_report_failure";

export const MAIL_NATURALS_REPORT_SUCCESS_ACTION = "mail_report_success";
export const MAIL_NATURALS_REPORT_FAILURE_ACTION = "mail_report_failure";

export const DOWNLOAD_AGENT_STATUS_CHANGE_SUCCESS_ACTION =
  "agent_change_status_success";
export const DOWNLOAD_AGENT_STATUS_CHANGE_FAILURE_ACTION =
  "agent_change_status_failure";

export const FETCH_DEALERS_SUCCESS_ACTION = "fetch_dealers_success";
export const FETCH_DEALERS_FAILURE_ACTION = "fetch_dealers_failure";
export const CLEAR_DEALERS_ACTION = "clear_dealers";
export const CLEAR_VOLKSWAGEN_DEALERS = "clear_volkswagen_dealers";
export const CLEAR_DEALERS_VEHICLE = "clear_dealers_vehicle";

export const FETCH_VEHICLES_SUCCESS_ACTION = "fetch_vehicles_success";
export const FETCH_VEHICLES_FAILURE_ACTION = "fetch_vehicles_failure";
export const CLEAR_VEHICLES_ACTION = "clear_vehicles";

export const FETCH_AUDIT_IMAGES_SUCCESS_ACTION = "fetch_audit_images_success";
export const FETCH_AUDIT_IMAGES_FAILURE_ACTION = "fetch_audit_images_failure";

export const FETCH_NATURALS_AUDIT_IMAGES_SUCCESS_ACTION =
  "fetch_audit_images_success";
export const FETCH_NATURALS_AUDIT_IMAGES_FAILURE_ACTION =
  "fetch_audit_images_failure";

export const FETCH_EXCESS_VEHICLES_SUCCESS_ACTION =
  "fetch_excess_vehicles_success";
export const FETCH_EXCESS_VEHICLES_FAILURE_ACTION =
  "fetch_excess_vehicles_failure";
export const UPDATE_EXCESS_VEHICLES_SUCCESS_ACTION =
  "fetch_excess_vehicles_success";
export const UPDATE_EXCESS_VEHICLES_FAILURE_ACTION =
  "fetch_excess_vehicles_failure";

export const FETCH_DAIMLER_TEMPLATES_SUCCESS_ACTION =
  "fetch_daimler_templates_success";
export const FETCH_DAIMLER_TEMPLATES_FAILURE_ACTION =
  "fetch_daimler_templates_failure";

export const FETCH_DAIMLER_DEALERS_SUCCESS_ACTION =
  "fetch_daimler_dealers_success";
export const FETCH_DAIMLER_DEALERS_FAILURE_ACTION =
  "fetch_daimler_dealers_failure";

export const DOWNLOAD_DAIMLER_REPORT_SUCCESS_ACTION =
  "download_daimler_report_success";
export const DOWNLOAD_DAIMLER_REPORT_FAILURE_ACTION =
  "download_daimler_report_failure";

export const REPORT_AUDIT_NOT_FOUND = "report_audit_not_found";

export const UPLOAD_PHOTO_SUCCESS = "upload_photo_success";
export const UPLOAD_PHOTO_FAILURE = "upload_photo_failure";
export const FETCH_PANDGAGENTS_SUCCESS_ACTION = "fetch_pandgagents_success";
export const FETCH_PANDGAGENTS_FAILURE_ACTION = "fetch_pandgagents_failure";

export const FETCH_PANDGDISTRIBUTERS_SUCCESS_ACTION =
  "fetch_pandgdistributers_success";
export const FETCH_PANDGDISTRIBUTERS_FAILURE_ACTION =
  "fetch_pandgdistributers_failure";

export const FETCH_PANDGMANAGERS_SUCCESS_ACTION = "fetch_pandgmanagers_success";
export const FETCH_PANDGMANAGERS_FAILURE_ACTION = "fetch_pandgmanagers_failure";

export const FETCH_PANDGLOCATIONS_SUCCESS_ACTION =
  "fetch_pandglocations_success";
export const FETCH_PANDGLOCATIONS_FAILURE_ACTION =
  "fetch_pandglocations_failure";

export const FETCH_PANDGGETALLAUDITS_SUCCESS_ACTION =
  "fetch_pandggetallaudits_success";
export const FETCH_PANDGGETALLAUDITS_FAILURE_ACTION =
  "fetch_pandggetallaudits_failure";

export const DOWNLOAD_PANDGGETAUDITEXPORT_SUCCESS_ACTION =
  "download_pandggetauditexport_success";
export const DOWNLOAD_PANDGGETAUDITEXPORT_FAILURE_ACTION =
  "download_pandggetauditexport_failure";

export const UPDATE_PANDGPOSTSUBMIT_SUCCESS_ACTION =
  "update_pandgpostsubmit_success";
export const UPDATE_PANDGPOSTSUBMIT_FAILURE_ACTION =
  "update_pandgpostsubmit_failure";

export const FETCH_DAIMLER_CLIENT_TEMPLATES_SUCCESS_ACTION =
  "fetch_daimler_client_templates_success";
export const FETCH_DAIMLER_CLIENT_TEMPLATES_FAILURE_ACTION =
  "fetch_daimler_client_templates_failure";

export const FETCH_DAIMLER_CLIENT_DEALERS_SUCCESS_ACTION =
  "fetch_daimler_client_dealers_success";
export const FETCH_DAIMLER_CLIENT_DEALERS_FAILURE_ACTION =
  "fetch_daimler_client_dealers_failure";

export const DOWNLOAD_DAIMLER_CLIENT_REPORT_SUCCESS_ACTION =
  "download_client_report_success";
export const DOWNLOAD_DAIMLER_CLIENT_REPORT_FAILURE_ACTION =
  "download_client_report_failure";

export const FETCH_DAIMLER_CLIENT_VEHICLES_SUCCESS_ACTION =
  "fetch_daimler_client_vehicles_success";
export const FETCH_DAIMLER_CLIENT_VEHICLES_FAILURE_ACTION =
  "fetch_daimler_client_vehicles_failure";

export const FETCH_DAIMLER_ADMIN_TEMPLATES_SUCCESS_ACTION =
  "fetch_daimler_admin_templates_success";
export const FETCH_DAIMLER_ADMIN_TEMPLATES_FAILURE_ACTION =
  "fetch_daimler_admin_templates_failure";
export const FETCH_DAIMLER_SIGNOFFURL_SUCCESS_ACTION =
  "FETCH_DAIMLER_SIGNOFFURL_SUCCESS_ACTION";
export const FETCH_DAIMLER_SIGNOFFURL_FAILURE_ACTION =
  "FETCH_DAIMLER_SIGNOFFURL_FAILURE_ACTION";

export const SELECT_PANDG_AUDIT_FROM_LEFT_PANE =
  "select_pandg_audit_from_left_pane";

export const INITIATE_UPLOAD = "initiate_upload";
export const DISMISS_LOG = "dismiss_log";
export const UPLOAD_DAIMLER_USER_CSV_SUCCESS =
  "upload_daimler_user_csv_success";
export const UPLOAD_DAIMLER_USER_CSV_FAILURE =
  "upload_daimler_user_csv_failure";
export const UPLOAD_DAIMLER_DEALER_CSV_SUCCESS =
  "upload_daimler_dealer_csv_success";
export const UPLOAD_DAIMLER_DEALER_CSV_FAILURE =
  "upload_daimler_dealer_csv_failure";
export const UPLOAD_DAIMLER_VEHICLE_CSV_SUCCESS =
  "upload_daimler_vehicle_csv_success";
export const UPLOAD_DAIMLER_VEHICLE_CSV_FAILURE =
  "upload_daimler_vehicle_csv_failure";
export const CHANGE_DEALER_APPROVAL = "change_dealer_approval";
export const FETCH_VEHICLES_COUNTS_SUCCESS_ACTION =
  "FETCH_VEHICLES_COUNTS_SUCCESS_ACTION";
export const FETCH_VEHICLES_COUNTS_FAILURE_ACTION =
  "FETCH_VEHICLES_COUNTS_FAILURE_ACTION";

export const UPLOAD_VOLKSWAGEN_USER_CSV_SUCCESS =
  "upload_volkswagen_user_csv_success";
export const UPLOAD_VOLKSWAGEN_USER_CSV_FAILURE =
  "upload_volkswagen_user_csv_failure";
export const UPLOAD_VOLKSWAGEN_DEALER_CSV_SUCCESS =
  "upload_volkswagen_dealer_csv_success";
export const UPLOAD_VOLKSWAGEN_DEALER_CSV_FAILURE =
  "upload_volkswagen_dealer_csv_failure";
export const UPLOAD_VOLKSWAGEN_VEHICLE_CSV_SUCCESS =
  "upload_volkswagen_vehicle_csv_success";
export const UPLOAD_VOLKSWAGEN_VEHICLE_CSV_FAILURE =
  "upload_volkswagen_vehicle_csv_failure";

// Damages
export const FETCH_DAMAGES_TEMPLATE_SUCCESS_ACTION =
  "fetch_damages_template_success";
export const FETCH_DAMAGES_TEMPLATE_FAILURE_ACTION =
  "fetch_damages_template_failure";

export const FETCH_DAMAGES_AUDITS_SUCCESS_ACTION =
  "fetch_damages_audits_success";
export const FETCH_DAMAGES_AUDITS_FAILURE_ACTION =
  "fetch_damages_audits_failure";

export const SAVE_DAMAGES_AUDIT_SUCCESS_ACTION = "save_damages_audit_success";
export const SAVE_DAMAGES_AUDIT_FAILURE_ACTION = "save_damages_audit_failure";
export const DOWNLOAD_DAMAGES_REPORT_SUCCESS_ACTION =
  "download_damages_report_success";
export const DOWNLOAD_DAMAGES_REPORT_FAILURE_ACTION =
  "download_damages_report_failure";
export const DOWNLOAD_DAMAGES_AUDIT_REPORT_SUCCESS_ACTION =
  "download_damages_audit_report_success";
export const DOWNLOAD_DAMAGES_AUDIT_REPORT_FAILURE_ACTION =
  "download_damages_audit_report_failure";
export const FETCH_DAMAGES_TRACKER_MASTER_SUCCESS_ACTION =
  "FETCH_DAMAGES_TRACKER_MASTER_SUCCESS_ACTION";
export const FETCH_DAMAGES_TRACKER_MASTER_FAILURE_ACTION =
  "FETCH_DAMAGES_TRACKER_MASTER_FAILURE_ACTION";
export const SAVE_TRACKER_AUDIT_SUCCESS_ACTION =
  "SAVE_TRACKER_AUDIT_SUCCESS_ACTION";
export const SAVE_TRACKER_AUDIT_FAILURE_ACTION =
  "SAVE_TRACKER_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_DAMAGES_TRACKER_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_DAMAGES_TRACKER_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_DAMAGES_TRACKER_REPORT_FAILURE_ACTION =
  "DOWNLOAD_DAMAGES_TRACKER_REPORT_FAILURE_ACTION";
export const UPLOAD_PVSHEET_SUCCESS = "UPLOAD_PVSHEET_SUCCESS";
export const UPLOAD_PVSHEET_FAILURE = "UPLOAD_PVSHEET_FAILURE";

// P and G
export const UPLOAD_DISTRIBUTOR_CSV_SUCCESS = "upload_distributor_csv_success";
export const UPLOAD_DISTRIBUTOR_CSV_FAILURE = "upload_distributor_csv_failure";
export const UPLOAD_FUNCTIONALMANAGER_CSV_SUCCESS =
  "upload_functionalmanager_csv_success";
export const UPLOAD_FUNCTIONALMANAGER_CSV_FAILURE =
  "upload_functionalmanager_csv_failure";
export const UPLOAD_AGENCY_CSV_SUCCESS = "upload_agency_csv_success";
export const UPLOAD_AGENCY_CSV_FAILURE = "upload_agency_csv_failure";
export const UPLOAD_LOCATION_CSV_SUCCESS = "upload_location_csv_success";
export const UPLOAD_LOCATION_CSV_FAILURE = "upload_location_csv_failure";
export const FETCH_PANDGTEMPLATES_SUCCESS_ACTION =
  "FETCH_PANDGTEMPLATES_SUCCESS_ACTION";
export const FETCH_PANDGTEMPLATES_FAILURE_ACTION =
  "FETCH_PANDGTEMPLATES_FAILURE_ACTION";

// Preethi Photo audit
export const FETCH_PREETHI_PHOTO_TEMPLATE_SUCCESS_ACTION =
  "fetch_preethi_photo_template_success";
export const FETCH_PREETHI_PHOTO_TEMPLATE_FAILURE_ACTION =
  "fetch_preethi_photo_template_failure";
export const FETCH_PREETHI_PHOTO_AUDITS_SUCCESS_ACTION =
  "fetch_preethi_photo_audits_success";
export const FETCH_PREETHI_PHOTO_AUDITS_FAILURE_ACTION =
  "fetch_preethi_photo_audits_failure";
export const DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_SUCCESS_ACTION =
  "download_preethi_photo_audit_report_success";
export const DOWNLOAD_PREETHI_PHOTO_AUDIT_REPORT_FAILURE_ACTION =
  "download_preethi_photo_audit_report_failure";

// Godrej
export const UPLOAD_GODREJ_AUDIT_SUCCESS = "upload_godrej_audit_success";
export const UPLOAD_GODREJ_AUDIT_FAILURE = "upload_godrej_audit_failure";
export const GODREJ_AUDITS_FETCH_SUCCESS = "godrej_audits_fetch_success";
export const GODREJ_AUDITS_FETCH_FAILURE = "godrej_audits_fetch_failure";
export const GODREJ_PRODUCTS_FETCH_SUCCESS = "godrej_products_fetch_success";
export const GODREJ_PRODUCTS_FETCH_FAILURE = "godrej_products_fetch_failure";
export const GODREJ_AUDIT_UPDATE_SUCCESS = "godrej_audit_update_success";
export const GODREJ_AUDIT_UPDATE_FAILURE = "godrej_audit_update_failure";
export const GODREJ_REPORT_DOWNLOAD_SUCCESS = "godrej_report_download_success";
export const GODREJ_REPORT_DOWNLOAD_FAILURE = "godrej_report_download_failure";

// Yamaha
export const FETCH_YAMAHA_TEMPLATENAME_SUCCESS_ACTION =
  "fetch_yamaha_TEMPLATENAME_success";
export const FETCH_YAMAHA_TEMPLATENAME_FAILURE_ACTION =
  "fetch_yamaha_TEMPLATENAME_failure";

export const FETCH_YAMAHA_DEALERCOUNT_SUCCESS_ACTION =
  "fetch_yamaha_DEALERCOUNT_success";
export const FETCH_YAMAHA_DEALERCOUNT_FAILURE_ACTION =
  "fetch_yamaha_DEALERCOUNT_failure";

export const FETCH_YAMAHA_DEALERCODE_SUCCESS_ACTION =
  "fetch_yamaha_DEALERCODE_success";
export const FETCH_YAMAHA_DEALERCODE_FAILURE_ACTION =
  "fetch_yamaha_DEALERCODE_failure";

export const FETCH_YAMAHA_DEALERS_SUCCESS_ACTION =
  "FETCH_YAMAHA_DEALERS_SUCCESS_ACTION";
export const FETCH_YAMAHA_DEALERS_FAILURE_ACTION =
  "FETCH_YAMAHA_DEALERS_FAILURE_ACTION";

export const FETCH_YAMAHA_AUDITS_SUCCESS_ACTION =
  "fetch_yamaha_audits_success_action";
export const FETCH_YAMAHA_AUDITS_FAILURE_ACTION =
  "fetch_yamaha_audits_failure_action";

export const UPDATE_YAMAHA_LINEITEM_SUCCESS_ACTION =
  "update_yamaha_lineitem_success_action";
export const UPDATE_YAMAHA_LINEITEM_FAILURE_ACTION =
  "update_yamaha_lineitem_failure_action";

export const DOWNLOAD_YAMAHA_AUDIT_REPORT_SUCCESS_ACTION =
  "download_yamaha_audit_report_success_action";
export const DOWNLOAD_YAMAHA_AUDIT_REPORT_FAILURE_ACTION =
  "download_yamaha_audit_report_failure_action";

export const SAVE_YAMAHA_AUDITS_SUCCESS_ACTION =
  "save_yamaha_audits_success_action";
export const SAVE_YAMAHA_AUDITS_FAILURE_ACTION =
  "save_yamaha_audits_failure_action";

//Diamler_key_Management
export const FETCH_DIAMLER_KEY_TEMPLATE_SUCCESS_ACTION =
  "fetch_diamler_key_template_success_action";
export const FETCH_DIAMLER_KEY_TEMPLATE_FAILURE_ACTION =
  "fetch_diamler_key_template_failure_action";
export const FETCH_DIAMLER_KEY_DEALERS_SUCCESS_ACTION =
  "fetch_diamler_key_dealers_success_action";
export const FETCH_DIAMLER_KEY_DEALERS_FAILURE_ACTION =
  "fetch_diamler_key_dealers_failure_action";
export const FETCH_DIAMLER_KEY_AUDITS_SUCCESS_ACTION =
  "fetch-diamler-key-audits-success-action";
export const FETCH_DIAMLER_KEY_AUDITS_FAILURE_ACTION =
  "fetch-diamler-key-audits-failure-action";
export const FETCH_DIAMLER_KEY_VEHICLES_SUCCESS_ACTION =
  "fetch-diamler-key-vehicles-success-action";
export const FETCH_DIAMLER_KEY_VEHICLES_FAILURE_ACTION =
  "fetch-diamler-key-vehicles-failure-action";
export const SAVE_DIAMLER_KEY_AUDITS_SUCCESS_ACTION =
  "save-diamler-key-audits-success-action";
export const SAVE_DIAMLER_KEY_AUDITS_FAILURE_ACTION =
  "save-diamler-key-audits-failure-action";
export const DOWNLOAD_DIAMLER_KEY_REPORT_SUCCESS_ACTION =
  "download_keymanagement_audit_report_success_action";
export const DOWNLOAD_DIAMLER_KEY_REPORT_FAILURE_ACTION =
  "download_keymanagement_audit_report_failure_action";
export const UPLOAD_DIAMLER_KEY_PHOTO_SUCCESS =
  "upload_diamler_key_photo_success";
export const UPLOAD_DIAMLER_KEY_PHOTO_FAILURE =
  "upload_diamler_key_photo_failure";
export const SHOW_LOADING_SCREEN_KEY_MGMT = "SHOW_LOADING_SCREEN_KEY_MGMT";
export const TO_CHECK_PHOTOS = "to_check_photos";
export const NO_PHOTOS = "no_photos";
export const FETCH_VINS_COUNTS_SUCCESS_ACTION =
  "FETCH_VINS_COUNTS_SUCCESS_ACTION";
export const FETCH_VINS_COUNTS_FAILURE_ACTION =
  "FETCH_VINS_COUNTS_FAILURE_ACTION";

// salesFundamentals
export const FETCH_SALES_TEMPLATE_SUCCESS_ACTION =
  "fetch_sales_template_success_action";
export const FETCH_SALES_TEMPLATE_FAILURE_ACTION =
  "fetch_sales_template_failure_action";
export const FETCH_STORE_SUCCESS_ACTION = "fetch_store_success_action";
export const FETCH_STORE_FAILURE_ACTION = "fetch_store_failure_action";
export const FETCH_PRODUCT_SUCCESS_ACTION = "fetch_product_success_action";
export const FETCH_PRODUCT_FAILURE_ACTION = "fetch_product_failure_action";
export const DOWNLOAD_SALES_REPORT_SUCCESS_ACTION =
  "fetch_report_success_action";
export const DOWNLOAD_SALES_REPORT_FAILURE_ACTION =
  "fetch_report_failure_action";
export const FETCH_SALES_AUDITS_SUCCESS_ACTION = "fetch_audits_success_action";
export const FETCH_SALES_AUDITS_FAILURE_ACTION = "fetch_audits_failure_action";
export const UPLOAD_SALES_MASTER_CSV_SUCCESS = "fetch_sales_master_success";
export const UPLOAD_SALES_MASTER_CSV_FAILURE = "fetch_sales_master_failure";
export const UPLOAD_SALES_COUNTRY_CSV_SUCCESS = "fetch_sales_country_success";
export const UPLOAD_SALES_COUNTRY_CSV_FAILURE = "fetch_sales_country_failure";
export const UPLOAD_SALES_USER_CSV_SUCCESS = "fetch_sales_user_success";
export const UPLOAD_SALES_USER_CSV_FAILURE = "fetch_sales_user_failure";
export const SAVE_SALES_AUDITS_SUCCESS_ACTION = "fetch_sales_audit_success";
export const SAVE_SALES_AUDITS_FAILURE_ACTION = "fetch_sales_audit_failure";
export const FETCH_SALES_AUDIT_IMAGES_SUCCESS_ACTION =
  "fetch_sales_images_success";
export const FETCH_SALES_AUDIT_IMAGES_FAILURE_ACTION =
  "fetch_sales_images_failure";
export const GET_ALL_PHOTOS_SUCCESS = "get_all_photos_success";
export const GET_ALL_PHOTOS_FAILURE = "get_all_photos_failure";
export const save_photo_success = "save_photo_success";
export const save_photo_failure = "save_photo_failure";
export const get_store_photo_success = "get_store_photo_success";
export const get_store_photo_failure = "get_store_photo_failure";
export const get_audit_dates_success = "get_audit_dates_success";
export const get_audit_dates_failure = "get_audit_dates_failure";
export const get_leaflet_store_success = "get_leaflet_store_success";
export const get_leaflet_store_failure = "get_leaflet_store_failure";
export const CLEAR_STORE_ACTION = "CLEAR_STORE_ACTION";
export const GET_ALL_CATEGORYPHOTOS_SUCCESS = "get_category_photos_success";
export const GET_ALL_CATEGORYPHOTOS_FAILURE = "get_category_photos_failure";
export const GET_ALL_DISPLAYPHOTOS_SUCCESS = "get_display_photos_sucess";
export const GET_ALL_DISPLAYPHOTOS_FAILURE = "get_display_photos_failure";
export const GET_ALL_MANUALPHOTOS_SUCCESS = "get_manual_photo_success";
export const GET_ALL_MANUALPHOTOS_FAILURE = "get_manual_photo_failure";
export const get_audited_store_success = "get_audited_store_success";
export const get_audited_store_failure = "get_audited_store_failure";
export const get_photo_success = "get_photo_success";
export const get_photo_failure = "get_photo_failure";
//Furlenco

export const FETCH_FURLENCO_TEMPLATE_SUCCESS_ACTION =
  "furlenco-template-success-action";
export const FETCH_FURLENCO_TEMPLATE_FAILURE_ACTION =
  "furlenco-template-failure-action";
export const FETCH_FURLENCO_CUSTOMER_SUCCESS_ACTION =
  "furlenco-customer-success-action";
export const FETCH_FURLENCO_CUSTOMER_FAILURE_ACTION =
  "furlenco-customer-failure-action";
export const FETCH_FURLENCO_AUDITS_SUCCESS_ACTION =
  "furlenco-audits-success-action";
export const FETCH_FURLENCO_AUDITS_FAILURE_ACTION =
  "furlenco-audits-failure-action";
export const SAVE_FURLENCO_AUDIT_SUCCESS_ACTION =
  "furlenco-save-audits-success-action";
export const SAVE_FURLENCO_AUDIT_FAILURE_ACTION =
  "furlenco-save-audits-failure-action";
export const DOWNLOAD_FURLENCO_REPORT_SUCCESS_ACTION =
  "furlenco-report-success-action";
export const DOWNLOAD_FURLENCO_REPORT_FAILURE_ACTION =
  "furlenco-report-failure-action";
export const UPLOAD_FURLENCO_USER_CSV_SUCCESS =
  "furlenco-user-csv-success-action";
export const UPLOAD_FURLENCO_USER_CSV_FAILURE =
  "furlenco-user-csv-failure-action";
export const UPLOAD_FURLENCO_CUSTOMER_CSV_SUCCESS =
  "furlenco-customer-csv-success-action";
export const UPLOAD_FURLENCO_CUSTOMER_CSV_FAILURE =
  "furlenco-customer-csv-failure-action";
export const FILTER_CUSTOMERS_SUCCESS_ACTION =
  "furlenco-filter-customers-success-action";
export const FILTER_CUSTOMERS_FAILURE_ACTION =
  "furlenco-filter-customers-failure-action";
export const ASSIGN_USER_TO_CUSTOMER_SUCCESS_ACTION =
  "assign_user_to_customer_success_action";
export const ASSIGN_USER_TO_CUSTOMER_FAILURE_ACTION =
  "assign_user_to_customer_failure_action";

//johnson and johnson
export const jandj_get_templates_success = "jandj_get_templates_success";
export const jandj_get_templates_failure = "jandj_get_templates_failure";
export const jandj_get_dealers_success = "jandj_get_dealers_success";
export const jandj_get_dealers_failure = "jandj_get_dealers_failure";
export const jandj_get_audit_success = "jandj_get_audit_success";
export const jandj_get_audit_failure = "jandj_get_audit_failure";
export const jandj_save_audit_success = "jandj_save_audit_success";
export const jandj_save_audit_failure = "jandj_save_audit_failure";
export const jandj_get_audit_report_success = "jandj_get_audit_report_success";
export const jandj_get_audit_report_failure = "jandj_get_audit_report_failure";
export const jandj_download_audit_report_success =
  "jandj_download_audit_report_success";
export const jandj_download_audit_report_failure =
  "jandj_download_audit_report_failure";
export const UPLOAD_JANDJ_MASTER_CSV_SUCCESS =
  "jandj-master-csv-success-action";
export const UPLOAD_JANDJ_MASTER_CSV_FAILURE =
  "jandj-master-csv-failure-action";
export const UPLOAD_JANDJ_CUSTOMER_CSV_SUCCESS =
  "jandj-customer-csv-success-action";
export const UPLOAD_JANDJ_CUSTOMER_CSV_FAILURE =
  "jandj-customer-csv-failure-action";

//udaan
export const FETCH_UDAAN_TEMPLATE_SUCCESS_ACTION =
  "FETCH_UDAAN_TEMPLATE_SUCCESS_ACTION";
export const FETCH_UDAAN_TEMPLATE_FAILURE_ACTION =
  "FETCH_UDAAN_TEMPLATE_FAILURE_ACTION";
export const FETCH_UDAAN_STORE_SUCCESS_ACTION =
  "FETCH_UDAAN_STORE_SUCCESS_ACTION";
export const FETCH_UDAAN_STORE_FAILURE_ACTION =
  "FETCH_UDAAN_STORE_FAILURE_ACTION";
export const ASSIGN_AUDITS_DETAILS_SUCCESS_ACTION =
  "ASSIGN_AUDITS_DETAILS_SUCCESS_ACTION";
export const ASSIGN_AUDITS_FAILIURE_ACTION = "ASSIGN_AUDITS_FAILIURE_ACTION";
export const DOWNLOAD_UDAAN_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_UDAAN_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_UDAAN_REPORT_FAILURE_ACTION =
  "DOWNLOAD_UDAAN_REPORT_FAILURE_ACTION";
export const FETCH_UDAAN_AUDIT_SUCCESS_ACTION =
  "FETCH_UDAAN_AUDIT_SUCCESS_ACTION";
export const FETCH_UDAAN_AUDIT_FAILURE_ACTION =
  "FETCH_UDAAN_AUDIT_FAILURE_ACTION";
export const FILTER_UDAAN_STORE_SUCCESS_ACTION =
  "FILTER_UDAAN_STORE_SUCCESS_ACTION";
export const FILTER_UDAAN_STORE_FAILURE_ACTION =
  "FILTER_UDAAN_STORE_FAILURE_ACTION";
export const UPLOAD_UDAAN_MASTER_CSV_SUCCESS =
  "udaan-master-csv-success-action";
export const UPLOAD_UDAAN_MASTER_CSV_FAILURE =
  "udaan-master-csv-failure-action";

//hersheys
export const FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION =
  "FETCH_HERSHEYS_TEMPLATE_SUCCESS_ACTION";
export const FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION =
  "FETCH_HERSHEYS_TEMPLATE_FAILURE_ACTION";
export const FETCH_HERSHEYS_STORE_SUCCESS_ACTION =
  "FETCH_HERSHEYS_STORE_SUCCESS_ACTION";
export const FETCH_HERSHEYS_STORE_FAILURE_ACTION =
  "FETCH_HERSHEYS_STORE_FAILURE_ACTION";
export const FILTER_HERSHEYS_STORE_SUCCESS_ACTION =
  "FILTER_HERSHEYS_STORE_SUCCESS_ACTION";
export const FILTER_HERSHEYS_STORE_FAILURE_ACTION =
  "FILTER_HERSHEYS_STORE_FAILURE_ACTION";
export const ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION =
  "ASSIGN_HERSHEYS_AUDITS_DETAILS_SUCCESS_ACTION";
export const ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION =
  "ASSIGN_HERSHEYS_AUDITS_FAILIURE_ACTION";
export const FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION =
  "FETCH_HERSHEYS_AUDIT_SUCCESS_ACTION";
export const FETCH_HERSHEYS_AUDIT_FAILURE_ACTION =
  "FETCH_HERSHEYS_AUDIT_FAILURE_ACTION";
export const SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION =
  "SAVE_HERSHEYS_AUDIT_SUCCESS_ACTION";
export const SAVE_HERSHEYS_AUDIT_FAILURE_ACTION =
  "SAVE_HERSHEYS_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_HERSHEYS_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION =
  "DOWNLOAD_HERSHEYS_REPORT_FAILURE_ACTION";
export const DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION =
  "DOWNLOAD_HERSHEYS_AUDIT_REPORT_FAILURE_ACTION";
export const FETCH_AUDITORSDETAILS_SUCCESS_ACTION =
  "FETCH_AUDITORSDETAILS_SUCCESS_ACTION";
export const FETCH_AUDITORSDETAILS_FAILIURE_ACTION =
  "FETCH_AUDITORSDETAILS_FAILIURE_ACTION";
export const get_audit_reports_success = "get_audit_reports_success";
export const get_audit_reports_failure = "get_audit_reports_failure";
export const get_audit_count_success = "get_audit_count_success";
export const get_audit_count_failure = "get_audit_count_failure";
export const UPLOAD_HERSHEYS_MASTER_CSV_SUCCESS =
  "hersheys-master-csv-success-action";
export const UPLOAD_HERSHEYS_MASTER_CSV_FAILURE =
  "hersheys-master-csv-failure-action";

//hersheys competition
export const FETCH_HERSHEYS_COMPETITION_TEMPLATE_SUCCESS_ACTION =
  "FETCH_HERSHEYS_COMPETITION_TEMPLATE_SUCCESS_ACTION";
export const FETCH_HERSHEYS_COMPETITION_TEMPLATE_FAILURE_ACTION =
  "FETCH_HERSHEYS_COMPETITION_TEMPLATE_FAILURE_ACTION";
export const FETCH_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION =
  "FETCH_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION";
export const FETCH_HERSHEYS_COMPETITION_STORE_FAILURE_ACTION =
  "FETCH_HERSHEYS_COMPETITION_STORE_FAILURE_ACTION";
export const FILTER_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION =
  "FILTER_HERSHEYS_COMPETITION_STORE_SUCCESS_ACTION";
export const FILTER_HERSHEYS_COMPETITION_STORE_FAILURE_ACTION =
  "FILTER_HERSHEYS_COMPETITION_STORE_FAILURE_ACTION";
export const ASSIGN_HERSHEYS_COMPETITION_AUDITS_DETAILS_SUCCESS_ACTION =
  "ASSIGN_HERSHEYS_COMPETITION_AUDITS_DETAILS_SUCCESS_ACTION";
export const ASSIGN_HERSHEYS_COMPETITION_AUDITS_FAILIURE_ACTION =
  "ASSIGN_HERSHEYS_COMPETITION_AUDITS_FAILIURE_ACTION";
export const FETCH_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION =
  "FETCH_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION";
export const FETCH_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION =
  "FETCH_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION";
export const SAVE_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION =
  "SAVE_HERSHEYS_COMPETITION_AUDIT_SUCCESS_ACTION";
export const SAVE_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION =
  "SAVE_HERSHEYS_COMPETITION_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_HERSHEYS_COMPETITION_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_HERSHEYS_COMPETITION_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_HERSHEYS_COMPETITION_REPORT_FAILURE_ACTION =
  "DOWNLOAD_HERSHEYS_COMPETITION_REPORT_FAILURE_ACTION";
export const DOWNLOAD_HERSHEYS_COMPETITION_AUDIT_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_HERSHEYS_AUDIT_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_HERSHEYS_COMPETITION_AUDIT_REPORT_FAILURE_ACTION =
  "DOWNLOAD_HERSHEYS_COMPETITION_AUDIT_REPORT_FAILURE_ACTION";
export const FETCH_AUDITORS_DETAILS_SUCCESS_ACTION =
  "FETCH_AUDITORS_DETAILS_SUCCESS_ACTION";
export const FETCH_AUDITORS_DETAILS_FAILIURE_ACTION =
  "FETCH_AUDITORS_DETAILS_FAILIURE_ACTION";
export const get_hc_audit_reports_success = "get_hc_audit_reports_success";
export const get_hc_audit_reports_failure = "get_hc_audit_reports_failure";
export const get_hc_audit_count_success = "get_hc_audit_count_success";
export const get_hc_audit_count_failure = "get_hc_audit_count_failure";
export const UPLOAD_HERSHEYS_COMPETITION_MASTER_CSV_SUCCESS =
  "hersheys-competition-master-csv-success-action";
export const UPLOAD_HERSHEYS_COMPETITION_MASTER_CSV_FAILURE =
  "hersheys-competition-master-csv-failure-action";

//mr-retail

export const FETCH_MR_TEMPLATE_SUCCESS_ACTION =
  "MR_RETAIL_TEMPLATE_SUCCESS_ACTION";
export const FETCH_MR_TEMPLATE_FAILURE_ACTION =
  "MR_RETAIL_TEMPLATE_FAILURE_ACTION";
export const FETCH_MR_STORE_SUCCESS_ACTION = "MR_STORE_SUCCESS_ACTION";
export const FETCH_MR_STORE_FAILURE_ACTION = "MR_STORE_FAILURE_ACTION";
export const FETCH_MR_AUDITS_SUCCESS_ACTION = "MR_AUDITS_SUCCESS_ACTION";
export const FETCH_MR_AUDITS_FAILURE_ACTION = "MR_AUDITS_FAILURE_ACTION";
export const FETCH_MR_PHOTO_SUCCESS_ACTION = "MR_PHOTO_SUCCESS_ACTION";
export const FETCH_MR_PHOTO_FAILURE_ACTION = "MR_PHOTO_FAILURE_ACTION";
export const CLEAR_AUDIT_ACTION = "clear_audit_action";
export const SAVE_MRRETAIL_AUDIT_SUCCESS_ACTION = "save_audit_success_action";
export const SAVE_MRRETAIL_AUDIT_FAILURE_ACTION = "save_audit_failure_action";
export const DOWNLOAD_MRREPORT_SUCCESS_ACTION = "mr_report_success_action";
export const DOWNLOAD_MRREPORT_FAILURE_ACTION = "mr_report_failure_action";
export const DOWNLOAD_MRREPORT_PHOTOS_SUCCESS_ACTION =
  "mr_report_photos_sucess_action";
export const DOWNLOAD_MRREPORT_PHOTOS_FAILURE_ACTION =
  "mr_report_photos_failure_action";
export const MR_DOWNLOAD_PHOTOS_SUCCESS = "mr_download_photos_success";
export const MR_DOWNLOAD_PHOTOS_FAILURE = "mr_download_photos_failure";
export const DOWNLOAD_MRSIGNOFF_SUCCESS_ACTION =
  "DOWNLOAD_MRSIGNOFF_SUCCESS_ACTION";
export const DOWNLOAD_MRSIGNOFF_FAILURE_ACTION =
  "DOWNLOAD_MRSIGNOFF_FAILURE_ACTION";
export const UPLOAD_MRRETAIL_MASTER_CSV_SUCCESS =
  "mrretail-master-csv-success-action";
export const UPLOAD_MRRETAIL_MASTER_CSV_FAILURE =
  "mrretail-master-csv-failure-action";
//CANNON

export const FETCH_CANNON_TEMPLATE_SUCCESS_ACTION =
  "cannon_template_succcess_action";
export const FETCH_CANNON_TEMPLATE_FAILURE_ACTION =
  "cannon_template_failure_action";
export const FETCH_CANNON_STORE_SUCCESS_ACTION = "cannon_store_Success";
export const FETCH_CANNON_STORE_FAILURE_ACTION = "cannon_Store_failure";
export const FETCH_CANNON_PRODUCT_SUCCESS_ACTION = "cannon_product_success";
export const FETCH_CANNON_PRODUCT_FAILURE_ACTION = "cannon_product_failure";
export const FETCH_CANNON_HYGIENE_SUCCESS_ACTION =
  "fetch_cannon_hygiene_success";
export const FETCH_CANNON_HYGIENE_FAILURE_ACTION =
  "fetch_cannon_hygiene_failure";
export const FETCH_CANNON_APPLIANCES_SUCCESS_ACTION =
  "fetch_cannon_appliances_success";
export const FETCH_CANNON_APPLIANCES_FAILURE_ACTION =
  "fetch_cannon_appliances_failure";
export const SAVE_CANNON_AUDIT_SUCCESS_ACTION = "save_cannon_audit_success";
export const SAVE_CANNON_AUDIT_FAILURE_ACTION = "save_cannon_audit_failure";
export const FETCH_AUDITORS_SUCCESS_ACTION = "auditors_success";
export const FETCH_AUDITORS_FAILURE_ACTION = "auditors_failure";
export const ASSIGN_CANNON_AUDITS_SUCCESS_ACTION = "assign_audits_success";
export const ASSIGN_CANNON_AUDITS_FAILURE_ACTION = "assign_audits_failure";
export const FETCH_DASHBOARD_SUCCESS_ACTION = "region_success_action";
export const FETCH_DASHBOARD_FAILURE_ACTION = "region_failure_action";
export const CLEAR_CANNON_GRAPH_DATA = "clear_data_graph";
export const DOWNLOAD_CANNON_REPORT_SUCCESS_ACTION = "cannon_report_success";
export const DOWNLOAD_CANNON_REPORT_FAILURE_ACTION = "cannon_report_failure";
export const FETCH_CANNON_AUDITS_SUCCESS_ACTION = "cannon_audits_success";
export const FETCH_CANNON_AUDITS_FAILURE_ACTION = "cannon_audits_failure";
export const GET_ALL_CANONPHOTOS_SUCCESS = "GET_ALL_CANONPHOTOS_SUCCESS";
export const GET_ALL_CANONPHOTOS_FAILURE = "GET_ALL_CANONPHOTOS_FAILURE";
export const FETCH_CANNON_DASHBOARD_SCORE_SUCCESS_ACTION =
  "DashboardScore_Success";
export const FETCH_CANNON_DASHBOARD_SCORE_FAILURE_ACTION =
  "DashboardScore_failure";
export const FETCH_CANNON_DISPLAY_PHOTO_SUCCESS_ACTION =
  "Display_photo_success";
export const FETCH_CANNON_DISPLAY_PHOTO_FAILURE_ACTION =
  "Display_photo_failure";
export const FETCH_CANNON_PRODUCTDISPLAY_SUCCESS_ACTION =
  "Cannon_productDisplay_Succcess";
export const FETCH_CANNON_PRODUCTDISPLAY_FAILURE_ACTION =
  "Cannon_productDisplay_Failure";
export const FETCH_DASHBOARD_CATEGORY_AUDITS_SUCCESS_ACTION =
  "dashboard_Cat_audits_success";
export const FETCH_DASHBOARD_CATEGORY_AUDITS_FAILURE_ACTION =
  "dashboard_Cat_audits_failure";
export const FETCH_CANNON_PERCENTAGE_SUCCESS_ACTION = "percentage_success";
export const FETCH_CANNON_PERCENTAGE_FAILURE_ACTION = "percentage_failure";
export const FETCH_WIDGETS_SUCCESS_ACTION = "widgets_success";
export const FETCH_WIDGETS_FAILURE_ACTION = "widgets_failure";
export const DOWNLOAD_COMPILED_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_COMPILED_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_COMPILED_REPORT_FAILURE_ACTION =
  "DOWNLOAD_COMPILED_REPORT_FAILURE_ACTION";
export const FETCH_DASHBOARD_DISPLAY_AUDIT_SUCCESS_ACTION =
  "FETCH_DASHBOARD_DISPLAY_AUDIT_SUCCESS_ACTION";
export const FETCH_DASHBOARD_DISPLAY_AUDIT_FAILURE_ACTION =
  "FETCH_DASHBOARD_DISPLAY_AUDIT_FAILURE_ACTION";
export const UPLOAD_CANNON_MASTER_CSV_SUCCESS =
  "cannon-master-csv-success-action";
export const UPLOAD_CANNON_MASTER_CSV_FAILURE =
  "cannon-master-csv-failure-action";
//ATM
export const FETCH_ATM_TEMPLATE_SUCCESS_ACTION = "fetch_atm_template_success";
export const FETCH_ATM_TEMPLATE_FAILURE_ACTION = "fetch_atm_template_failure";
export const FETCH_ATM_STORE_SUCCESS_ACTION = "fetch_atm_store_success";
export const FETCH_ATM_STORE_FAILURE_ACTION = "fetch_atm_store_failure";
export const FETCH_ATM_AUDITS_SUCCESS_ACTION = "fetch_atm_audits_success";
export const FETCH_ATM_AUDITS_FAILURE_ACTION = "fetch_atm_audits_failure";
export const ASSIGN_ATM_AUDITS_SUCCESS_ACTION = "assign_atm_audits_success";
export const ASSIGN_ATM_AUDITS_FAILURE_ACTION = "assign_atm_audits_failure";
export const FILTER_ATM_SUCCESS_ACTION = "filter_atm_success";
export const FILTER_ATM_FAILURE_ACTION = "filter_atm_failure";
export const FETCH_ATM_PRODUCTS_SUCCESS_ACTION = "fetch_atm_products_success";
export const FETCH_ATM_PRODUCTS_FAILURE_ACTION = "fetch_atm_products_failure";
export const SAVE_ATM_AUDITS_SUCCESS_ACTION = "save_atm_audits_success";
export const SAVE_ATM_AUDITS_FAILURE_ACTION = "save_atm_audits_failure";
export const DOWNLOAD_ATMREPORT_SUCCESS_ACTION = "download_atmreport_success";
export const DOWNLOAD_ATMREPORT_FAILURE_ACTION = "download_atmreport_failure";
export const DOWNLOAD_ATMAUDITS_REPORT_SUCCESS_ACTION =
  "download_atmaudits_report_success";
export const DOWNLOAD_ATMAUDITS_REPORT_FAILURE_ACTION =
  "download_atmaudits_report_failure";
export const SEND_ATMAUDITS_MAIL_SUCCESS_ACTION = "send_atmaudits_mail_success";
export const SEND_ATMAUDITS_MAIL_FAILURE_ACTION = "send_atmaudits_mail_failure";
export const DOWNLOAD_ATMAUDITS_PHOTOS_SUCCESS_ACTION =
  "DOWNLOAD_ATMAUDITS_PHOTOS_SUCCESS_ACTION";
export const DOWNLOAD_ATMAUDITS_PHOTOS_FAILURE_ACTION =
  "DOWNLOAD_ATMAUDITS_PHOTOS_FAILURE_ACTION";
export const UPLOAD_ATM_PHOTO_SUCCESS_ACTION =
  "UPLOAD_ATM_PHOTO_SUCCESS_ACTION";
export const UPLOAD_ATM_PHOTO_FAILURE_ACTION =
  "UPLOAD_ATM_PHOTO_FAILURE_ACTION";
export const UPLOAD_ATM_MASTER_CSV_SUCCESS = "atm-master-csv-success-action";
export const UPLOAD_ATM_MASTER_CSV_FAILURE = "atm-master-csv-failure-action";

//GRB
export const UPLOAD_GRB_MASTER_CSV_SUCCESS = "grb-master-csv-success-action";
export const UPLOAD_GRB_MASTER_CSV_FAILURE = "grb-master-csv-failure-action";

//TIMESHEETS
export const UPLOAD_TIMESHEET_MASTER_CSV_SUCCESS =
  "timesheet-master-csv-success-action";
export const UPLOAD_TIMESHEET_MASTER_CSV_FAILURE =
  "timesheet-master-csv-failure-action";
export const ADD_TIMESHEET_MASTER_CSV_SUCCESS =
  "add_timesheet-master-csv-success-action";
export const ADD_TIMESHEET_MASTER_CSV_FAILURE =
  "add_timesheet-master-csv-failure-action";
export const EDIT_TIMESHEET_MASTER_CSV_SUCCESS =
  "edit_timesheet-master-csv-success-action";
export const EDIT_TIMESHEET_MASTER_CSV_FAILURE =
  "edit_timesheet-master-csv-failure-action";
export const DELETE_TIMESHEET_MASTER_CSV_SUCCESS =
  "delete_timesheet-master-csv-success-action";
export const DELETE_TIMESHEET_MASTER_CSV_FAILURE =
  "delete_timesheet-master-csv-failure-action";
export const CREATEDDATA_TIMESHEET_MASTER_CSV_SUCCESS =
  "createddata_timesheet-master-csv-success-action";
export const CREATEDDATA_TIMESHEET_MASTER_CSV_FAILURE =
  "createddata_timesheet-master-csv-failure-action";
export const FETCH_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_timesheet_data_success";
export const FETCH_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_timesheet_data_failure";
export const FETCH_CLIENTNAME_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_clientname_timesheet_data_success";
export const FETCH_CLIENTNAME_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_clientname_timesheet_data_failure";
export const FETCH_AUDITTYPE_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_audittype_timesheet_data_success";
export const FETCH_AUDITTYPE_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_audittype_timesheet_data_failure";
export const FETCH_DATE_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_date_timesheet_data_success";
export const FETCH_DATE_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_date_timesheet_data_failure";
export const REPORT_TIMESHEET_DATA_SUCCESS_ACTION =
  "report_timesheet_data_success";
export const REPORT_TIMESHEET_DATA_FAILURE_ACTION =
  "report_timesheet_data_failure";
export const FETCH_MASTER_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_master_timesheet_data_success";
export const FETCH_MASTER_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_master_timesheet_data_failure";
export const FETCH_FILTERED_MASTER_TIMESHEET_DATA_SUCCESS_ACTION =
  "fetch_filtered_master_timesheet_data_success";
export const FETCH_FILTERED_MASTER_TIMESHEET_DATA_FAILURE_ACTION =
  "fetch_filtered_master_timesheet_data_failure";
export const FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_SUCCESS_ACTION =
  "FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_SUCCESS_ACTION";
export const FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_FAILURE_ACTION =
  "FETCH_CONTROL_PANEL_MASTER_TIMESHEET_DATA_FAILURE_ACTION";
export const SAVE_TIMESHEET_DATA_SUCCESS_ACTION =
  "SAVE_TIMESHEET_DATA_SUCCESS_ACTION";
export const SAVE_TIMESHEET_DATA_FAILURE_ACTION =
  "SAVE_TIMESHEET_DATA_FAILURE_ACTION";
export const EXPORT_TIMESHEET_MASTER_DATA_SUCCESS_ACTION =
  "EXPORT_TIMESHEET_MASTER_DATA_SUCCESS_ACTION";
export const EXPORT_TIMESHEET_MASTER_DATA_FAILURE_ACTION =
  "EXPORT_TIMESHEET_MASTER_DATA_FAILURE_ACTION";

//hershysCheck

export const FETCH_HERSHYS_CHECK_TEMPLATE_SUCCESS_ACTION =
  "FETCH_HERSHYS_CHECK_TEMPLATE_SUCCESS_ACTION";
export const FETCH_HERSHYS_CHECK_TEMPLATE_FAILURE_ACTION =
  "FETCH_HERSHYS_CHECK_TEMPLATE_FAILURE_ACTION";
export const FETCH_HERSHYS_CHECK_MASTER_SUCCESS_ACTION =
  "FETCH_HERSHYS_CHECK_MASTER_SUCCESS_ACTION";
export const FETCH_HERSHYS_CHECK_MASTER_FAILURE_ACTION =
  "FETCH_HERSHYS_CHECK_MASTER_FAILURE_ACTION";
export const SET_SELECTED_TEMPLATE_DATA = "SET_SELECTED_TEMPLATE_DATA";
export const SET_SELECTED_MASTER_DATA = "SET_SELECTED_MASTER_DATA";
export const UPLOAD_HERSHYS_CHECK_SUCCESS_ACTION =
  "UPLOAD_HERSHYS_CHECK_SUCCESS_ACTION";
export const UPLOAD_HERSHYS_CHECK_FAILURE_ACTION =
  "UPLOAD_HERSHYS_CHECK_FAILURE_ACTION";
export const SAVE_HERSHYS_CHECK_SUCCESS_ACTION =
  "SAVE_HERSHYS_CHECK_SUCCESS_ACTION";
export const SAVE_HERSHYS_CHECK_FAILURE_ACTION =
  "SAVE_HERSHYS_CHECK_FAILURE_ACTION";
export const FETCH_EXISTING_AUDITS_SUCCESS_ACTION =
  "FETCH_EXISTING_AUDITS_SUCCESS_ACTION";
export const FETCH_EXISTING_AUDITS_FAILURE_ACTION =
  "FETCH_EXISTING_AUDITS_FAILURE_ACTION";
export const CLEAR_FETCHED_AUDITS = "CLEAR_FETCHED_AUDITS";
export const DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_FAILURE_ACTION =
  "DOWNLOAD_HERSHYSCHECKAUDITS_REPORT_FAILURE_ACTION";

export const GET_TANISHQ_TEMPLATE_SUCCESS_ACTION =
  "GET_TANISHQ_TEMPLATE_SUCCESS_ACTION";
export const GET_TANISHQ_TEMPLATE_FAILURE_ACTION =
  "GET_TANISHQ_TEMPLATE_FAILURE_ACTION";

//OLA
export const FETCH_OLA_TEMPLATE_SUCCESS_ACTION =
  "FETCH_OLA_TEMPLATE_SUCCESS_ACTION";
export const FETCH_OLA_TEMPLATE_FAILURE_ACTION =
  "FETCH_OLA_TEMPLATE_FAILURE_ACTION";
export const FETCH_OLA_MASTER_DATA_SUCCESS_ACTION =
  "FETCH_OLA_MASTER_DATA_SUCCESS_ACTION";
export const FETCH_OLA_MASTER_DATA_FAILURE_ACTION =
  "FETCH_OLA_MASTER_DATA_FAILURE_ACTION";
export const FETCH_OLA_AUDITS_DATA_SUCCESS_ACTION =
  "FETCH_OLA_AUDITS_DATA_SUCCESS_ACTION";
export const FETCH_OLA_AUDITS_DATA_FAILURE_ACTION =
  "FETCH_OLA_AUDITS_DATA_FAILURE_ACTION";
export const SAVE_OLA_AUDITS_DATA_SUCCESS_ACTION =
  "SAVE_OLA_AUDITS_DATA_SUCCESS_ACTION";
export const SAVE_OLA_AUDITS_DATA_FAILURE_ACTION =
  "SAVE_OLA_AUDITS_DATA_FAILURE_ACTION";
export const REPORT_OLA_DATA_SUCCESS_ACTION = "REPORT_OLA_DATA_SUCCESS_ACTION";
export const REPORT_OLA_DATA_FAILURE_ACTION = "REPORT_OLA_DATA_FAILURE_ACTION";
export const FETCH_OLA_AUDITS_LOCATIONS_SUCCESS_ACTION =
  "FETCH_OLA_AUDITS_LOCATIONS_SUCCESS_ACTION";
export const FETCH_OLA_AUDITS_LOCATIONS_FAILURE_ACTION =
  "FETCH_OLA_AUDITS_LOCATIONS_FAILURE_ACTION";
export const REPORT_OLA_DATA_LOCATIONWISE_SUCCESS_ACTION =
  "REPORT_OLA_DATA_LOCATIONWISE_SUCCESS_ACTION";
export const REPORT_OLA_DATA_LOCATIONWISE_FAILURE_ACTION =
  "REPORT_OLA_DATA_LOCATIONWISE_FAILURE_ACTION";

export const DELETE_TIMESHEET_DATA_SUCCESS_ACTION =
  "DELETE_TIMESHEET_DATA_SUCCESS_ACTION";
export const DELETE_TIMESHEET_DATA_FAILURE_ACTION =
  "DELETE_TIMESHEET_DATA_FAILURE_ACTION";

export const FETCH_DTC_TEMPLATE_SUCCESS_ACTION =
  "FETCH_DTC_TEMPLATE_SUCCESS_ACTION";
export const FETCH_DTC_TEMPLATE_FAILURE_ACTION =
  "FETCH_DTC_TEMPLATE_FAILURE_ACTION";
export const FETCH_DTC_MASTER_SUCCESS_ACTION =
  "FETCH_DTC_MASTER_SUCCESS_ACTION";
export const FETCH_DTC_MASTERS_FAILURE_ACTION =
  "FETCH_DTC_MASTERS_FAILURE_ACTION";
export const UPLOAD_DTC_PHOTO_SUCCESS_ACTION =
  "UPLOAD_DTC_PHOTO_SUCCESS_ACTION";
export const UPLOAD_DTC_PHOTO_FAILURE_ACTION =
  "UPLOAD_DTC_PHOTO_FAILURE_ACTION";
export const SAVE_DTC_TEMPLATES_SUCCESS_ACTION =
  "SAVE_DTC_TEMPLATES_SUCCESS_ACTION";
export const SAVE_DTC_TEMPLATES_FAILURE_ACTION =
  "SAVE_DTC_TEMPLATES_FAILURE_ACTION";
export const UPDATE_DTC_MASTER_SUCCESS_ACTION =
  "UPDATE_DTC_MASTER_SUCCESS_ACTION";
export const UPDATE_DTC_MASTER_FAILURE_ACTION =
  "UPDATE_DTC_MASTER_FAILURE_ACTION";
export const FETCH_DTC_SKU_PHOTOS_SUCCESS_ACTION =
  "FETCH_DTC_SKU_PHOTOS_SUCCESS_ACTION";
export const FETCH_DTC_SKU_PHOTOS_FAILURE_ACTION =
  "FETCH_DTC_SKU_PHOTOS_FAILURE_ACTION";

export const FETCH_PGD_TEMPLATE_SUCCESS_ACTION =
  "FETCH_PGD_TEMPLATE_SUCCESS_ACTION";
export const FETCH_PGD_TEMPLATE_FAILURE_ACTION =
  "FETCH_PGD_TEMPLATE_FAILURE_ACTION";
export const FETCH_PGD_MASTER_SUCCESS_ACTION =
  "FETCH_PGD_MASTER_SUCCESS_ACTION";
export const FETCH_PGD_MASTERS_FAILURE_ACTION =
  "FETCH_PGD_MASTERS_FAILURE_ACTION";
export const FETCH_PGD_AUDIT_SUCCESS_ACTION = "FETCH_PGD_AUDIT_SUCCESS_ACTION";
export const FETCH_PGD_AUDIT_FAILURE_ACTION = "FETCH_PGD_AUDIT_FAILURE_ACTION";
export const SAVE_PGD_AUDIT_SUCCESS_ACTION = "SAVE_PGD_AUDIT_SUCCESS_ACTION";
export const SAVE_PGD_AUDIT_FAILURE_ACTION = "SAVE_PGD_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_PGD_AUDIT_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_PGD_AUDIT_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_PGD_AUDIT_REPORT_FAILURE_ACTION =
  "DOWNLOAD_PGD_AUDIT_REPORT_FAILURE_ACTION";
export const FETCH_PGD_CITIES_SUCCESS_ACTION =
  "FETCH_PGD_CITIES_SUCCESS_ACTION";
export const FETCH_PGD_CITIES_FAILURE_ACTION =
  "FETCH_PGD_CITIES_FAILURE_ACTION";

// Bikano
export const FETCH_BIKANO_TEMPLATE_SUCCESS_ACTION =
  "FETCH_BIKANO_TEMPLATE_SUCCESS_ACTION";
export const FETCH_BIKANO_TEMPLATE_FAILURE_ACTION =
  "FETCH_BIKANO_TEMPLATE_FAILURE_ACTION";
export const FETCH_BIKANO_STORE_SUCCESS_ACTION =
  "FETCH_BIKANO_STORE_SUCCESS_ACTION";
export const FETCH_BIKANO_STORE_FAILURE_ACTION =
  "FETCH_BIKANO_STORE_FAILURE_ACTION";
export const FETCH_BIKANO_AUDITORLIST_SUCCESS_ACTION =
  "FETCH_BIKANO_USERLIST_FAILURE_ACTION";
export const FETCH_BIKANO_AUDITORLIST_FAILURE_ACTION =
  "FETCH_BIKANO_USERLIST_FAILURE_ACTION";
export const ASSIGN_BIKANO_AUDITS_DETAILS_SUCCESS_ACTION =
  "ASSIGN_BIKANO_AUDITS_DETAILS_SUCCESS_ACTION";
export const ASSIGN_BIKANO_AUDITS_FAILIURE_ACTION =
  "ASSIGN_BIKANO_AUDITS_FALIURE_ACTION";
export const FETCH_BIKANO_AUDIT_SUCCESS_ACTION =
  "FETCH_BIKANO_AUDIT_SUCCESS_ACTION";
export const FETCH_BIKANO_AUDIT_FAILURE_ACTION =
  "FETCH_BIKANO_AUDIT_FAILURE_ACTION";
export const SAVE_BIKANO_AUDIT_SUCCESS_ACTION =
  "SAVE_BIKANO_AUDIT_SUCCESS_ACTION";
export const SAVE_BIKANO_AUDIT_FAILURE_ACTION =
  "SAVE_BIKANO_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION =
  "DOWNLOAD_BIKANO_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION =
  "DOWNLOAD_BIKANO_REPORT_FAILURE_ACTION";


export const FETCH_PGC_TEMPLATE_SUCCESS_ACTION = "FETCH_PGC_TEMPLATE_SUCCESS_ACTION";
export const FETCH_PGC_TEMPLATE_FAILURE_ACTION = "FETCH_PGC_TEMPLATE_FAILURE_ACTION";
export const FETCH_PGC_MASTER_SUCCESS_ACTION = "FETCH_PGC_MASTER_SUCCESS_ACTION";
export const FETCH_PGC_MASTERS_FAILURE_ACTION = "FETCH_PGC_MASTERS_FAILURE_ACTION";
export const ASSIGN_PGC_AUDITS_DETAILS_SUCCESS_ACTION = "ASSIGN_PGC_AUDITS_DETAILS_SUCCESS_ACTION";
export const ASSIGN_PGC_AUDITS_FAILIURE_ACTION = "ASSIGN_PGC_AUDITS_FAILIURE_ACTION";
export const FETCH_PGC_AUDITORSDETAILS_SUCCESS_ACTION = "FETCH_PGC_AUDITORSDETAILS_SUCCESS_ACTION";
export const FETCH_PGC_AUDITORSDETAILS_FAILIURE_ACTION = "FETCH_PGC_AUDITORSDETAILS_FAILIURE_ACTION";
export const FETCH_PGC_AUDIT_SUCCESS_ACTION = "FETCH_PGC_AUDIT_SUCCESS_ACTION";
export const FETCH_PGC_AUDIT_FAILURE_ACTION = "FETCH_PGC_AUDIT_FAILURE_ACTION";
export const SAVE_PGC_AUDIT_SUCCESS_ACTION = "SAVE_PGC_AUDIT_SUCCESS_ACTION";
export const SAVE_PGC_AUDIT_FAILURE_ACTION = "SAVE_PGC_AUDIT_FAILURE_ACTION";
export const DOWNLOAD_PGC_AUDIT_REPORT_SUCCESS_ACTION = "DOWNLOAD_PGC_AUDIT_REPORT_SUCCESS_ACTION";
export const DOWNLOAD_PGC_AUDIT_REPORT_FAILURE_ACTION = "DOWNLOAD_PGC_AUDIT_REPORT_FAILURE_ACTION";

export const FETCH_PRODUCTSDETAILS_SUCCESS_ACTION = "FETCH_PRODUCTSDETAILS_SUCCESS_ACTION";
export const FETCH_PRODUCTSDETAILS_FAILIURE_ACTION = "FETCH_PRODUCTSDETAILS_FAILIURE_ACTION";