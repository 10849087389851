import axios from "axios";
import * as FileSaver from "file-saver";
import * as types from "./types";

export const fetchCannonTemplatesAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/canon/getTemplates")
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchCannonPercentageAction = () => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/canon/percentage")
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_PERCENTAGE_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_PERCENTAGE_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchCannonDashboardScoreAction = ({
  storeId,
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/dashboard/modifiedDashboardData?storeId=${storeId}&templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_DASHBOARD_SCORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_DASHBOARD_SCORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDashboardDisplay = ({ storeId, display, category }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/dashboard/getProducts?storeId=${storeId}`,
        { display: display }
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_PRODUCTDISPLAY_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_PRODUCTDISPLAY_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchDashboardDisplayAudit = ({
  displayDetails,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL + "/auditor/v1/canon/dashboard/getDisplayAudits",
        displayDetails
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_DISPLAY_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_DISPLAY_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchDashboardCategoryAuditsAction = ({
  storeId,
  display,
  category,
  onSuccess,
  onFailure,
}) => {
  let data = {};
  if (category === "") {
    data.product = display;
    data.category = category;
  } else {
    data.product = display;
    data.category = category;
  }
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/dashboard/getCategories?storeId=${storeId}`,
        data
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_CATEGORY_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_CATEGORY_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure(error);
      });
  };
};

export const fetchDashboardDisplayPhotoAction = ({
  storeId,
  display,
  category,
}) => {
  let data = {};
  if (category === "") {
    data.product = display;
    data.category = category;
  } else {
    data.product = display;
    data.category = category;
  }
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/dashboard/getPhotos?storeId=${storeId}`,
        data
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_DISPLAY_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_DISPLAY_PHOTO_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchAuditorsAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/canon/auditors")
      .then(function (response) {
        dispatch({
          type: types.FETCH_AUDITORS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_AUDITORS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const AssignCannonAuditsAction = ({ data, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/canon/assignAudits", data)
      .then(function (response) {
        dispatch({
          type: types.ASSIGN_CANNON_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.ASSIGN_CANNON_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

//dashboard

//used for mainly on widgets
export const fetchWidgetsAction = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + `/auditor/v1/canon/dashboard/graph`)
      .then(function (response) {
        dispatch({
          type: types.FETCH_WIDGETS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_WIDGETS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

//used for metrics purposes dont remove
export const fetchDashboardAction = ({ templateId, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/canon/regionGraph?templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};
export const fetchStateGraphAction = ({
  templateId,
  region,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/stateGraph?templateId=${templateId}&region=${region}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchCountryGraphAction = ({
  templateId,
  region,
  state,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/cityGraph?templateId=${templateId}&region=${region}&state=${state}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const clearCannonGraphData = (data) => {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_CANNON_GRAPH_DATA,
      payload: {
        data: data,
      },
    });
  };
};

export const downloadCannonReportAction = ({
  templateId,
  storeId,
  storeName,
  distributorId,
  city
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/report?templateId=${templateId}&storeId=${storeId}`,
        { responseType: "blob" }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, `${distributorId}-${storeName}-${city}.xlsx`);
        dispatch({
          type: types.DOWNLOAD_CANNON_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_CANNON_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadCompiledReportAction = ({ templateId }) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/compiledReport?templateId=${templateId}`,
        { responseType: "blob" }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, `canonCompiledReport.xlsx`);
        dispatch({
          type: types.DOWNLOAD_COMPILED_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.DOWNLOAD_COMPILED_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchStoreGraphAction = ({
  templateId,
  region,
  state,
  city,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/storeGraph?templateId=${templateId}&region=${region}&state=${state}&city=${city}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchProductGraphAction = ({
  templateId,
  region,
  state,
  city,
  store,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/productGraph?templateId=${templateId}&region=${region}&state=${state}&city=${city}&store=${store}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_DASHBOARD_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_DASHBOARD_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchCannonStoreAction = ({
  templateId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + `/auditor/v1/canon/getStores?templateId=${templateId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_STORE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_STORE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchCannonAuditsAction = ({
  templateId,
  storeId,
  product,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/getAuditedData?templateId=${templateId}&storeId=${storeId}`,
        { product: product }
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data.audits);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const fetchCanonPhotosAction = ({
  templateId,
  storeId,
  product,
  category,
}) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/getPhotos?templateId=${templateId}&storeId=${storeId}`,
        { product: product, category: category }
      )
      .then(function (response) {
        dispatch({
          type: types.GET_ALL_CANONPHOTOS_SUCCESS,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.GET_ALL_CANONPHOTOS_FAILURE,
          payload: error,
        });
      });
  };
};

export const fetchCannonProductAction = ({
  templateId,
  storeId,
  onSuccess,
  onFailure,
}) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        `/auditor/v1/canon/getProducts?templateId=${templateId}&storeId=${storeId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_PRODUCT_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response);
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_PRODUCT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};
export const fetchCannonHygineAction = ({ templateId, storeId }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/getstoreHygieneData?templateId=${templateId}&storeId=${storeId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_HYGIENE_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_HYGIENE_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};
export const fetchCannonApplianceAction = ({ templateId, storeId }) => {
  return function (dispatch) {
    axios
      .post(
        types.API_URL +
        `/auditor/v1/canon/getAppliancesData?templateId=${templateId}&storeId=${storeId}`
      )
      .then(function (response) {
        dispatch({
          type: types.FETCH_CANNON_APPLIANCES_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_CANNON_APPLIANCES_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const saveCannonAuditAction = (audit) => {
  return function (dispatch) {
    axios
      .post(types.API_URL + "/auditor/v1/canon/save", audit)
      .then(function (response) {
        alert("Audit Updated Successfully");
        dispatch({
          type: types.SAVE_CANNON_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
        //   onSuccess();
      })
      .catch(function (error) {
        dispatch({
          type: types.SAVE_CANNON_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        //   onFailure();
      });
  };
};
