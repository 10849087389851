import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Grid,
  Card,
  Form,
  Image,
  Divider,
  Accordion,
  Menu,
} from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import {
  saveCannonAuditAction,
  downloadCannonReportAction,
  fetchCannonAuditsAction,
  fetchCanonPhotosAction,
} from "../../actions/cannon_action";
import config from "../../config.js";
import { Header } from "semantic-ui-react";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
import TextInput from "../commonViews/TextInput.jsx";
import ModalView from "../commonViews/ModalView.jsx";
const storageBaseUrl = config["storage_base_url"];
export class CannonProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      hygiene: [],
      appliances: [],
      appliances: [],
      cannonProducts: [],
      cannonMatchingProducts: [],
      originalAudits: [],
      displayStatus: "",
      remarks: "",
      prodId: "",
      questions: false,
      editMode: false,
      editMode2: false,
      editMode3: false,
      productChoosed: "",
      photoTakenDate: "",
      photoUrl: "",
      url: "",
      openModal: false,
      photoModal: false,
      activeIndex: 0,
      originalProduct: "",
      displayValue: "",
      templateId: "",
    };
  }
  componentDidMount() {
    let options = this.state.options;

    if (this.props.cannon.cannonProducts.length !== 0) {
      this.props.cannon.cannonProducts.forEach((data) => {
        options.push({
          text: data,
          value: data,
        });
      });
      this.setState({
        options,
      });
    } else {
      this.setState({
        options: [],
        // cannonProducts: this.props.cannon.cannonProducts
      });
    }
  }

  componentWillReceiveProps() {
    let hygiene = this.state.hygiene;
    if (this.props.cannon.cannonHygiene != undefined) {
      hygiene = [];
      this.props.cannon.cannonHygiene.map((data) => {
        hygiene.push(data);
      });
      this.setState({
        hygiene,
      });
    }

    let appliances = this.state.appliances;
    if (this.props.cannon.cannonAppliances != undefined) {
      appliances = [];
      this.props.cannon.cannonAppliances.map((data) => {
        appliances.push(data);
      });
      this.setState({
        appliances,
      });
    }
    return;
  }

  onClose = () => {
    this.props.onClose();
    this.setState({
      options: [],
    });
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  onProductSelected = (combo) => {
    let value = combo;
    let matchingProducts = [];
    if (combo !== undefined) {
      this.props.fetchCannonAuditsAction({
        templateId: this.props.selectedTemplate._id,
        storeId: this.props.SelectedStore._id,
        product: combo,
        onSuccess: (audits) => {
          if (audits.length !== 0) {
            audits.forEach((el) => {
              if (el.productName === value) {
                if (el.categoryName !== "") {
                  matchingProducts.push({
                    text: el.categoryName,
                    value: el.categoryName,
                  });
                  this.setState({
                    cannonMatchingProducts: matchingProducts,
                    originalAudits: audits,
                    questions: false,
                  });
                } else {
                  this.setState({
                    questions: true,
                    cannonMatchingProducts: [],
                    originalAudits: audits,
                    photoUrl: el.photourl,
                    photoTakenDate: el.photoTakenDate,
                  });
                }
              }
            });
          }
        },
        onFailure: () => { },
      });
    }
    this.setState({
      displayValue: combo,
      originalProduct: "",
      templateId: this.props.selectedTemplate._id,
    });
  };

  onOriginalProductSelected = (combo) => {
    let originalAudits = this.state.originalAudits;
    if (originalAudits.length > 0) {
      originalAudits.forEach((data) => {
        if (data.categoryName === combo) {
          this.setState({
            questions: true,
            displayStatus: data.displayStatus,
            remarks: data.remarks,
            prodId: data._id,
            photoUrl: data.photourl,
            photoTakenDate: data.photoTakenDate,
          });
        }
      });
    }
    this.setState({
      originalProduct: combo,
    });
  };

  edit = () => {
    this.setState({
      editMode: true,
    });
  };
  edit2 = () => {
    this.setState({
      editMode2: true,
    });
  };
  edit3 = () => {
    this.setState({
      editMode3: true,
    });
  };
  cancel = () => {
    this.setState({
      editMode: false,
    });
  };
  cancel2 = () => {
    this.setState({
      editMode2: false,
    });
  };
  cancel3 = () => {
    this.setState({
      editMode3: false,
    });
  };
  handleChangeApplianceText = (e, Id) => {
    let appliances = this.state.appliances;
    let appliancesData = [];
    appliances.map((data) => {
      if (data._id === Id) {
        data.remarks = e.target.value;
        appliancesData.push(data);
      }
    });
    this.setState({
      appliances,
      appliancesData,
    });
  };

  handleChangeApplianceDrop = (Combo, Id) => {
    let appliances = this.state.appliances;
    let appliancesData = [];
    appliances.map((data) => {
      if (data._id === Id) {
        data.appliancesWorkingCondition = Combo;
        appliancesData.push(data);
      }
    });
    this.setState({
      appliances,
      appliancesData,
    });
  };

  handleChangeHygieneDrop = (Combo, Id) => {
    console.log(Combo, Id, "idddddddddddddd")
    let hygiene = this.state.hygiene;
    let hygieneData = [];
    hygiene.map((data) => {
      if (data._id === Id) {
        data.dustfreeAndCleanCondition = Combo;
        hygieneData.push(data);
      }
    });
    this.setState({
      hygiene,
      hygieneData,
    });
  };

  saveHygiene = () => {
    let hygiene = this.state.hygiene;
    let auditType = "Store Hygiene";
    let hygieneData = this.state.hygieneData[0];
    this.props.saveCannonAuditAction(hygieneData, {
      onSuccess: () => {
        this.setState({
          editMode2: false,
          editMode: false,
          editMode3: false,
        });
      },
      onFailure: () => { },
    });
    this.setState({
      editMode: false,
      editMode2: false,
      editMode3: false,
    });
  };

  saveAppliance = () => {
    let appliances = this.state.appliances;
    let appliancesData = this.state.appliancesData[0];
    let auditType = "Appliances";
    let audit = {
      appliances,
      auditType,
    };
    this.props.saveCannonAuditAction(appliancesData, {
      onSuccess: () => {
        this.setState({
          editMode3: false,
          editMode: false,
          editMode2: false,
        });
      },
      onFailure: () => { },
    });
    this.setState({
      editMode: false,
      editMode2: false,
      editMode3: false,
    });
  };

  handleChangeText = (e, Id) => {
    let originalAudits = this.state.originalAudits;
    originalAudits.map((data) => {
      if (data._id === Id) {
        data.remarks = e.target.value;
      }
    });

    this.setState({
      originalAudits,
      remarks: e.target.value,
    });
  };

  handleChangeHygieneText = (e, Id) => {
    let hygiene = this.state.hygiene;
    let hygieneData = [];
    hygiene.map((data) => {
      if (data._id === Id) {
        data.remarks = e.target.value;
        hygieneData.push(data);
      }
    });
    this.setState({
      hygiene,
      hygieneData,
    });
  };

  handleChangeDrop = (Combo, Id) => {
    let originalAudits = this.state.originalAudits;
    originalAudits.map((data) => {
      if (data._id === Id) {
        data.displayStatus = Combo;
      }
    });

    this.setState({
      displayStatus: Combo,
    });
  };
  savePlanogram = () => {
    let originalAudits = this.state.originalAudits;
    if (originalAudits.length > 0) {
      originalAudits.forEach((data) => {
        let auditType = "Planogram";
        let audit = {
          auditType,
          data,
        };
        if (this.state.cannonMatchingProducts.length === 0) {
          if (data.productName === this.state.displayValue) {
            this.props.saveCannonAuditAction(data, {
              onSuccess: () => {
                this.setState({
                  editMode: false,
                  editMode2: false,
                  editMode3: false,
                });
              },
              onFailure: () => { },
            });
          }
          this.setState({
            editMode: false,
            editMode2: false,
            editMode3: false,
          });
        } else {
          if (data.categoryName === this.state.originalProduct) {
            this.props.saveCannonAuditAction(data, {
              onSuccess: () => {
                this.setState({
                  editMode: false,
                });
              },
              onFailure: () => { },
            });
          }
        }
      });
    }
    this.setState({
      editMode: false,
      editMode2: false,
      editMode3: false,
    });
  };

  triggerModal = () => {
    this.props.fetchCanonPhotosAction({
      templateId: this.state.templateId,
      storeId: this.props.SelectedStore._id,
      product: this.state.displayValue,
      category: this.state.originalProduct,
    });
    this.setState({
      openModal: true,
    });
  };
  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };
  downloadStoreReport = () => {
    this.props.downloadCannonReportAction({
      templateId: this.props.selectedTemplate._id,
      storeId: this.props.SelectedStore._id,
      storeName: this.props.SelectedStore.partnersName,
      distributorId: this.props.SelectedStore.distributorId,
      city: this.props.SelectedStore.city
    });
  };
  openPhotoModal = (product) => {
    this.props.fetchCanonPhotosAction({
      templateId: this.state.templateId,
      storeId: this.props.SelectedStore._id,
      product: product,
      category: "",
    });
    this.setState({
      openModal: true,
    });
    // this.setState({
    //   photoModal: true,
    //   url: photoUrl,
    // });
  };
  ClosephotoModal = () => {
    this.setState({
      photoModal: false,
    });
  };
  changeComments = (e, value) => {
    let questions = this.state.questions;
    questions.forEach((data) => {
      if (data.question === value) {
        data.comments = e.target.value;
        data.status = "Completed";
      }
    });
    this.setState({
      questions,
    });
  };
  render() {
    var displayStatus = this.state.displayStatus;
    var remarks = this.state.remarks;

    var options = [
      {
        value: "Available on display or demo",
        text: "Available on display or demo",
        key: "Available on display or demo",
      },
      {
        value: "Not available",
        text: "Not available",
        key: "Not available",
      },
      {
        value: "Not applicable for the category",
        text: "Not applicable for the category",
        key: "Not applicable for the category",
      },
    ];
    var Products = [
      {
        value: "0",
        text: "0",
        key: "0",
      },
      {
        value: "1",
        text: "1",
        key: "1",
      },
    ];
    let buttonStyle = {
      cursor: "pointer",
      backgroundColor: getMainBackground(this.props.color),
      color: getFontColor(this.props.color)
    }
    var Photos = [];
    if (this.props.cannon.canonPhotos != undefined) {
      if (this.props.cannon.canonPhotos.length > 0) {
        let photosUrl = this.props.cannon.canonPhotos;
        photosUrl.map((e) => {
          return Photos.push(
            <Grid.Column>
              <Image.Group size="medium" style={{ marginBottom: "10px" }}>
                <Image
                  src={storageBaseUrl + e.photoUrl}
                // onClick={() => this.zoomPics(e.photoUrl)}
                />
              </Image.Group>
              {/* <div
                style={{
                  position: "absolute",
                  top: 8,
                  left: 100,
                  color: "yellow",
                }}
              >
                {e.photoTakenDate}
              </div>
              <div
                style={{
                  position: "absolute"
                  top: 20,
                  left: 100,
                  color: "yellow",
                }}
              >
                {e.storeName}
              </div> */}
            </Grid.Column>
          );
        });
      }
    }

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        <div style={{ padding: 20, display: "inline-block" }}>
          <IconPicker
            name="arrow left"

            click={this.onClose}
            styles={{
              color: getTextColor(this.props.color),
              float: "right",
              cursor: "pointer",
              marginTop: 10,
              display: "inline-block",
              // top:"15",
              position: "absolute",
              right: 20,
            }}
          />
          <div>
            {this.props.status === "Completed" ? (
              <div
                style={{
                  display: "inline-block",
                  width: "91%",
                  alignContent: "center",
                }}
              >
                <h1
                  style={{
                    //   paddingLeft: 30,
                    color: getTextColor(this.props.color),
                    display: "inline-block",
                    width: "80%",
                  }}
                >
                  {`${this.props.SelectedStore.storeName.split("-")[0]}(${this.props.SelectedStore.distributorId
                    })-Store Score`}{" "}
                  -{" "}
                  <span
                    style={{
                      backgroundColor: getMainBackground(this.props.color),
                      borderRadius: "50%",
                      height: "39px",
                      width: "39px",
                      lineHeight: "39px",
                      color: getFontColor(this.props.color),
                      display: "inline-block",
                      textAlign: "center",
                      marginRight: "6px",
                    }}
                  >
                    {this.props.cannon.cannonStoreScore}
                  </span>
                </h1>
                <ConstantLabel
                  title={"Download Report"}
                  size={"medium"}
                  onClick={this.downloadStoreReport}
                  styles={{
                    cursor: "pointer",
                    backgroundColor: getMainBackground(this.props.color),
                    color: getFontColor(this.props.color),
                  }}
                  iconName={"download"}
                />
              </div>
            ) : this.props.status === "InProgress" ? (
              <h1
                style={{
                  //   paddingLeft: 30,
                  color: getTextColor(this.props.color),
                  display: "inline-block",
                }}
              >
                {`${this.props.SelectedStore.storeName.split("-")[0]}(${this.props.SelectedStore.distributorId
                  })`}{" "}
              </h1>
            ) : null}
          </div>
        </div>
        <Divider />
        <div style={{ padding: 20 }}>
          <h1
            style={{
              //   paddingLeft: 30,
              color: getTextColor(this.props.color),

              display: "inline-block",
            }}
          >
            Planogram
          </h1>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <DropDownPicker
                  placeholder={"Select Display"}
                  options={this.state.options}
                  value={this.state.displayValue}
                  onChangeDropDown={(value) => {
                    this.onProductSelected(value);
                  }}
                />

              </Grid.Column>

              {this.state.cannonMatchingProducts.length > 0 ? (
                <Grid.Column width={4}>
                  <DropDownPicker
                    placeholder={"Select Product"}
                    options={this.state.cannonMatchingProducts}
                    value={this.state.originalProduct}
                    onChangeDropDown={(value) => {
                      this.onOriginalProductSelected(value);
                    }}
                  />


                </Grid.Column>
              ) : null}
              {this.state.questions ? (
                <Grid.Column width={4} >
                  <ButtonHandler
                    styles={buttonStyle}
                    title={'Product Photo'}
                    click={this.triggerModal}
                  />

                </Grid.Column>
              ) : null}

              {this.state.questions && (
                <Grid.Column
                  floated="right"

                >
                  {this.state.editMode === false && (
                    <ButtonHandler styles={buttonStyle}
                      title={'Edit'}
                      click={this.edit}
                    />

                  )}
                </Grid.Column>
              )}

              {this.state.questions && this.state.editMode === true && (
                <Grid.Column >
                  <ButtonHandler styles={buttonStyle}
                    title={'Save'}
                    click={this.savePlanogram}
                  />

                </Grid.Column>
              )}

              {this.state.questions && this.state.editMode === true && (
                <Grid.Column>
                  <ButtonHandler color={'red'}
                    title={'Cancel'}
                    click={this.cancel}
                  />


                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
        <ModalView
          size={'medium'}
          open={this.state.photoModal}
          close={this.ClosephotoModal}
          content={
            <Image.Group size="medium" style={{ marginBottom: "10px" }}>
              <Image src={storageBaseUrl + this.state.url} />
            </Image.Group>
          }
          showSave={false}
        />
        <ModalView
          size={'medium'}
          open={this.state.openModal}
          close={this.closeModal}
          content={
            <Grid>
              <Grid.Row columns={3} style={{ marginLeft: "100px" }}>
                {Photos}
              </Grid.Row>
            </Grid>
          }
          showSave={false}
        />


        {this.state.options.length === 0 ? <div /> : null}
        {this.state.displayStatus && this.state.prodId ? (
          <div style={{ padding: 20 }}>
            <Card style={{ width: "100%" }}>
              <Card.Content>
                <Form.Field>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        width={5}
                        style={{ display: "inline-block", marginLeft: 20 }}
                      >
                        <a
                          className="ui teal left ribbon label"
                          style={{ marginLeft: 15 }}
                        >
                          Display Status
                        </a>
                        <DropDownPicker
                          placeholder={"Select status"}
                          options={options}
                          value={displayStatus}
                          onChangeDropDown={(combo) => {
                            this.handleChangeDrop(combo, this.state.prodId);
                          }}
                          disabled={!this.state.editMode}
                        />


                      </Grid.Column>

                      <Grid.Column
                        width={5}
                        style={{
                          display: "inline-block",
                          paddingTop: 10,
                          paddingLeft: 35,
                        }}
                      >
                        <TextInput
                          icon={"comment outline"}
                          position={"left"}
                          value={remarks}
                          placeholder={'Comments'}
                          disabled={!this.state.editMode}
                          onChangeTextInput={(e) => { this.handleChangeText(e, this.state.prodId); }}
                          transparent={'true'}
                        />

                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Card.Content>
            </Card>
          </div>
        ) : null}
        <Divider />
        <div style={{ padding: 20 }}>
          <h1
            style={{
              //   paddingLeft: 30,
              color: "orange",
              display: "inline-block",
            }}
          >
            Store Hygiene
          </h1>
          <br />
          {this.state.editMode2 === false && (
            <ButtonHandler styles={buttonStyle}
              title={'Edit'}
              click={this.edit2}
            />

          )}
          {this.state.editMode2 === true && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <ButtonHandler styles={buttonStyle}
                title={'Save'}
                click={this.saveHygiene}
              />
              <ButtonHandler color="red"
                title={'Cancel'}
                click={this.cancel2}
              />

            </div>
          )}

          {this.state.hygiene != undefined &&
            this.state.hygiene.map((Hyg, index) => (
              <Accordion as={Menu} vertical fluid>
                <Menu.Item>
                  <Accordion.Title
                    active={this.state.activeIndex === index}
                    index={index}
                    onClick={this.handleClick}
                  >
                    <Header>{Hyg.productName}</Header>
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === index}>
                    <Grid>
                      <Grid.Row style={{ display: "inline-block" }}>
                        <Grid.Column
                          width={8}
                          style={{ display: "inline-block" }}
                        >
                          <DropDownPicker
                            placeholder={"Select Type"}
                            options={Products}
                            value={Hyg.dustfreeAndCleanCondition}
                            onChangeDropDown={(combo) => {
                              this.handleChangeHygieneDrop(combo, Hyg._id);
                            }}
                            disabled={!this.state.editMode2}
                          />

                        </Grid.Column>
                        <Grid.Column
                          width={5}
                          style={{
                            display: "inline-block",
                            paddingTop: 15,
                            paddingLeft: 35,
                          }}
                        >
                          <TextInput
                            icon={"comment outline"}
                            position={"left"}
                            value={Hyg.remarks}
                            placeholder={'Comments'}
                            disabled={!this.state.editMode2}
                            onChangeTextInput={(e) => { this.handleChangeHygieneText(e, Hyg._id); }}
                            transparent={'true'}
                          />

                        </Grid.Column>
                        <Grid.Column
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                        >
                          <IconPicker
                            name={"camera"}
                            size={"large"}
                            // onClick={this.triggerModal(Hyg.productName)}
                            click={(e) => {
                              this.openPhotoModal(Hyg.productName);
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Menu.Item>
              </Accordion>
            ))}
        </div>
        <Divider />
        <div style={{ padding: 20 }}>
          <h1
            style={{
              //   paddingLeft: 30,
              color: "orange",
              display: "inline-block",
            }}
          >
            Appliances Working Condition
          </h1>
          <br />
          {this.state.editMode3 === false && (
            <ButtonHandler styles={buttonStyle}
              title={'Edit'}
              click={this.edit3}
            />

          )}
          {this.state.editMode3 === true && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <ButtonHandler styles={buttonStyle}
                title={'Save'}
                click={this.saveAppliance}
              />

              <ButtonHandler color={'red'}
                title={'Cancel'}
                click={this.cancel3}
              />


            </div>
          )}
          {this.state.appliances != undefined &&
            this.state.appliances.map((app, Index) => (
              <Accordion as={Menu} vertical fluid>
                <Menu.Item>
                  <Accordion.Title
                    active={this.state.activeIndex === Index}
                    index={Index}
                    onClick={this.handleClick}
                  >
                    <Header>{app.productName}</Header>
                  </Accordion.Title>
                  <Accordion.Content active={this.state.activeIndex === Index}>
                    <Grid>
                      <Grid.Row style={{ display: "inline-block" }}>
                        <Grid.Column
                          width={8}
                          style={{ display: "inline-block" }}
                        >
                          <DropDownPicker
                            placeholder={"Select Type"}
                            options={Products}
                            value={app.appliancesWorkingCondition}
                            onChangeDropDown={(value) => {
                              this.handleChangeApplianceDrop(value, app._id);
                            }}
                            disabled={!this.state.editMode3}

                          />

                        </Grid.Column>
                        <Grid.Column
                          width={5}
                          style={{
                            display: "inline-block",
                            paddingTop: 15,
                            paddingLeft: 35,
                          }}
                        >
                          <TextInput
                            icon={"comment outline"}
                            position={"left"}
                            value={app.remarks}
                            placeholder={'Comments'}
                            disabled={!this.state.editMode3}
                            onChangeTextInput={(e) => { this.handleChangeApplianceText(e, app._id); }}
                            transparent={'true'}
                          />

                        </Grid.Column>
                        <Grid.Column
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                        >
                          <IconPicker
                            name={"camera"}
                            size={"large"}
                            // onClick={(e) => {
                            //   this.openPhotoModal(app.photoUrl);
                            // }}
                            click={(e) => {
                              this.openPhotoModal(app.productName);
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Menu.Item>
              </Accordion>
            ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveCannonAuditAction,
      downloadCannonReportAction,
      fetchCannonAuditsAction,
      fetchCanonPhotosAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CannonProduct)
);
