import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn, isClient } from "../../util";
import {
  fetchDaimlerTemplatesAction,
  fetchDaimlerConsolidatedReportAction,
  fetchDaimlerClientTemplatesAction,
  clearDealersAction,
} from "../../actions/vehicle_verification_actions";
import DaimlerAudits from "./DaimlerAudits.jsx";
import MailPopup from "../MailPopup.jsx";
import TableView from "../commonViews/TableView";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
} from "../../ThemeHandlers/theme";
import DropDownPicker from '../commonViews/DropDownPicker.jsx'
import IconPicker from "../commonViews/IconPicker";
class DaimlerAuditsByMonth extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: "",
      isTemplatesView: true,
      isMailPopupView: false,
      year: new Date().getFullYear(),
      template: [],
      color: "",
    };
    this.activeConsolidatedTemplateId = null;
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }

    if (this.isClientProfile())
      this.props.fetchDaimlerClientTemplates(this.state.year);
    else {
      this.props.fetchDaimlerTemplates(this.state.year);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.templates !== prevProps.templates) {
      this.setState({ template: this.props.templates });
    }
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  isClientProfile = () => {
    return isClient(this.props.auth);
  };

  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };

  handleCloseClick = () => {
    this.props.clearPreviousData();
    this.setState({ isTemplatesView: true });
  };

  handleTableViewAuditClick = (template) => {
    this.props.clearPreviousData();
    if (!template._id) return;

    this.setState({ selectedTemplate: template, isTemplatesView: false });
  };

  handleDownloadAgentReportClick = (template, email) => {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }

    if (!template._id || !email) {
      return;
    }
    this.props.fetchDaimlerConsolidatedReport(template._id, email);
  };
  handleSendMailReportClick = (m) => {
    this.setState({ isMailPopupView: !this.state.isMailPopupView, mailIds: m });
    //this.props.mailReport(this.state.selectedAuditId, m);
    this.handleDownloadAgentReportClick(this.activeConsolidatedTemplateId, m);
  };

  handleMailReportClick = (e) => {
    this.activeConsolidatedTemplateId = e;
    this.setState({ isMailPopupView: !this.state.isMailPopupView });
  };

  onTemplateYearChange = (data) => {
    this.props.fetchDaimlerTemplates(data);
    this.setState({ year: data });
  };

  render() {
    var years = [
      {
        value: 2018,
        text: "2018",
        key: "2018",
      },
      {
        value: 2019,
        text: "2019",
        key: "2019",
      },
      {
        value: 2020,
        text: "2020",
        key: "2020",
      },
      {
        value: 2021,
        text: "2021",
        key: "2021",
      },
      {
        value: 2022,
        text: "2022",
        key: "2022",
      },
      {
        value: 2023,
        text: "2023",
        key: "2023",
      },
    ];
    let templateArray = Array.isArray(this.state.template);

    var template =
      templateArray === true
        ? this.state.template.length !== 0
          ? this.state.template
          : []
        : [];
    var col = [
      {
        title: "Template Name",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.auditName}
            </span>
          );
        },
        searchable: true,
        customFilterAndSearch: (term, rowData) =>
          rowData.auditName.toLowerCase().includes(term.toLowerCase()),
      },
      {
        title: "Dealers Count",
        field: "",

        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.dealerCount}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => term == rowData.dealerCount,
      },
      {
        title: "Vehicles Count",
        field: "",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.vehicleCount}
            </span>
          );
        },
        customFilterAndSearch: (term, rowData) => term == rowData.vehicleCount,
      },
    ];

    if (!this.isClientProfile()) {
      col.push({
        title: "Report",
        field: "",
        render: (rowData) => {
          if (rowData._id) {
            return (
              <IconPicker
                size={"large"}
                styles={{ color: getTextColor(this.state.color), cursor: 'pointer' }}
                name={"file excel outline"}
                click={() => this.handleMailReportClick(rowData)}
              />
            );
          }
        },
      });
    }

    return (
      <div style={{ flexGrow: 1, display: "flex", flexFlow: "column" }}>
        {this.state.isTemplatesView && (
          <div>
            <div
              style={{
                display: "inline-block",
                marginBottom: 20,
              }}
            >
              <h1
                style={{
                  display: "inline-block",
                  color: getTextColor(this.state.color),
                }}
              >
                Templates
              </h1>
              {!this.isClientProfile() && (
                <DropDownPicker styles={{
                  width: "20%",
                  zIndex: "100",
                  width: 300
                }}
                  placeholder={"Select Year"}
                  value={this.state.year}
                  options={years}
                  onChangeDropDown={(data) => { this.onTemplateYearChange(data) }}

                />

              )}
            </div>

            <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
              <div>
                <TableView selection={false} data={template} columns={col} />
                {/* <Table
                  // defaultFilterMethod={this.searchFilter}
                  data={template}
                  columns={columns}
                  rowClick={this.handleTableViewAuditClick}
                /> */}
              </div>
            </div>
          </div>
        )}
        {!this.state.isTemplatesView && (
          <div style={{ flexGrow: 1, display: "flex" }}>
            <DaimlerAudits
              template={this.state.selectedTemplate}
              onClose={this.handleCloseClick}
            />
          </div>
        )}
        {this.state.isMailPopupView ? (
          <MailPopup
            open={this.state.isMailPopupView}
            mailIds={this.state.mailIds}
            closePopup={this.handleMailReportClick.bind(this)}
            sendMailAndClosePopup={this.handleSendMailReportClick}
            getColor={getMainBackground}
            color={this.state.color}
            getCellColor={getMatchingTint}
          />
        ) : null}
      </div>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    templates: state.audits.templates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDaimlerTemplates: fetchDaimlerTemplatesAction,
      fetchDaimlerClientTemplates: fetchDaimlerClientTemplatesAction,
      fetchDaimlerConsolidatedReport: fetchDaimlerConsolidatedReportAction,
      clearPreviousData: clearDealersAction,
    },
    dispatch
  );
};

// DaimlerAuditsByMonth.PropTypes = {
//     dealers: PropTypes.array
// };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DaimlerAuditsByMonth)
);
