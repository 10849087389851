import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import Question from "./Question.jsx";
import { Segment, Input, Dropdown } from "semantic-ui-react";

class QuestionnaireDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: "",
      query: "",
    };
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  handleChangeSection = (e, data) => {
    this.setState({
      selectedSection: this.props.questionnaire.sections.filter(
        (s) => s.id === data.value
      )[0],
    });
  };

  searchFilter = (e, data) => {
    this.setState({ query: data.value });
  };

  matches = (question, query) => {
    if (!query) {
      return true;
    }

    var flatData = JSON.stringify(question);
    return flatData.toLowerCase().includes(query.toLowerCase());
  };

  render() {
    var questionnaire = this.props.questionnaire;
    var sections = [];
    var questions = [];

    for (var s of questionnaire.sections) {
      sections.push({
        key: s.id,
        value: s.id,
        text: s.name,
      });
    }

    if (!this.state.selectedSection) {
      this.state.selectedSection = questionnaire.sections[0];
    }

    if (this.state.selectedSection) {
      for (var category of this.state.selectedSection.categories) {
        for (var question of category.questions) {
          if (this.matches(question, this.state.query)) {
            question.categoryName = category.name;
            questions.push(question);
          }
        }
      }
    }

    return (
      <Segment
        style={{ backgroundColor: this.props.backColor(this.props.color) }}
      >
        <Dropdown
          placeholder="Section"
          search
          selection
          defaultValue={sections[0].value}
          options={sections}
          onChange={this.handleChangeSection}
        />
        <Input
          icon="search"
          style={{ float: "right" }}
          onChange={this.searchFilter}
          placeholder="Search..."
        />

        {questions.map((q, i) => {
          return (
            <Question
              key={questionnaire.auditId + q.id}
              question={q}
              editMode={this.props.editMode}
              buttonColor={this.props.buttonColor}
              backColor={this.props.backColor}
              color={this.props.color}
              fontColor={this.props.fontColor}
            />
          );
        })}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionnaireDetail)
);
