var config = {
  // api_url: "https://auditpro.matrixbscloud.com/apis/",
  // storage_base_url: "http://storage.matrixbscloud.com/auditpro/",

  api_url: "https://apstaging.matrixbscloud.com/apis/",
  storage_base_url: "https://auditpro-staging.s3.amazonaws.com/",

  // "api_url": 'http://localhost:2014',
  google_map_api_key: "AIzaSyBGpsUgr9JdDogY2dnR2Z2vR_u2LJYy_nU",
  //confirmation_emails: "ebenezerkumar718@gmail.com",
  confirmation_emails:
    "shaij.s@matrixbsindia.com;dtcteam@matrixbsindia.com;dtc.claims@matrixbsindia.com",
};

export default config;
