import React, { Component } from "react";
import CheckboxView from "../commonTables/CheckboxView.jsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Checkbox,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from "../commonViews/ButtonHandler.jsx";
import CannonProduct from "./CannonProduct.jsx";
import ConstantLabel from "../commonViews/ConstantLabel.jsx";
import {
  fetchCannonStoreAction,
  fetchCannonProductAction,
  fetchCannonHygineAction,
  fetchCannonApplianceAction,
  fetchAuditorsAction,
  AssignCannonAuditsAction,
} from "../../actions/cannon_action";
import TableView from "../commonViews/TableView.jsx";
import Table from "../commonTables/Table.jsx";
import CheckboxforAssign from "./CheckboxforAssign.jsx";
import {
  getFontColor,
  getMainBackground,
  getTextColor,
} from "../../ThemeHandlers/theme.js";
import { Toast } from "../commonViews/Notify.jsx";

// const CheckboxTable = selectTable(ReactTable);
export class CannonStore extends Component {
  constructor(props) {
    super(props);
    this.checkboxTable = null;

    this.setCannonCheckboxRef = (element) => {
      this.checkboxTable = element;
    };
    this.state = {
      selectedStore: {},
      isStoreView: true,
      storeId: "",
      value: "Initial Stores",
      initialAndAssignedStores: [],
      pendingAndCompletedStores: [],
      selection: {},
      userId: [],
      selectAll: false,
      userSelectionAll: false,
      openAssignModal: false,
      cannonStoreList: [],
      storeStatus: "",
      auditorList: [],
      userList: [],
      assignemailid: [],
      assignId: [],
      assignname: null,
      assignedcheckmethod: null,
      userList: [],
      id: [],
      comingData: [],
    };
  }
  componentDidMount() {
    this.updateTableData()
    // this.props.fetchCannonTemplatesAction()
    // console.log("Cannon Stores : ", this.props.cannon.cannonStoreList)
  }

  updateTableData () {
    let initialAndAssignedStores = this.state.initialAndAssignedStores;
    if (this.props.cannon.cannonStoreList) {
      initialAndAssignedStores = [];
      this.props.fetchAuditorsAction();
      this.props.cannon.cannonStoreList.forEach((data) => {
        if (data.status === "Initial") {
          initialAndAssignedStores.push(data);
        }
      });
      this.setState({
        initialAndAssignedStores: initialAndAssignedStores,
        cannonStoreList: this.props.cannon.cannonStoreList,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cannon.cannonAuditors !== prevProps.cannon.cannonAuditors) {
      this.setState({ auditorList: this.props.cannon.cannonAuditors });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.cannon.cannonStoreList !== this.props.cannon.cannonStoreList
    ) {
      this.setState({
        cannonStoreList: nextProps.cannon.cannonStoreList,
      });
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  handleTableViewAuditClick = (store) => {
    if (store.status !== "Initial") {
      this.setState({ selection: store });

      this.props.fetchCannonProductAction({
        templateId: this.props.template._id,
        storeId: store._id,
        onSuccess: (data) => {
          this.setState({ isStoreView: false, storeStatus: store.status });
        },
        onFailure: () => { },
      });
      this.setState({
        selectedStore: store,
      });
      this.props.fetchCannonHygineAction({
        templateId: this.props.template._id,
        storeId: store._id,
        onSuccess: (data) => { },
        onFailure: () => { },
      });
      this.props.fetchCannonApplianceAction({
        templateId: this.props.template._id,
        storeId: store._id,
        onSuccess: (data) => { },
        onFailure: () => { },
      });
    }
  };
  searchFilter = (filter, row) => {
    var flatData = JSON.stringify(row._original);
    return flatData.toLowerCase().includes(filter.value.toLowerCase());
  };
  backtoStore = () => {
    this.setState({
      isStoreView: true,
    });
  };
  handleChange = (e, { value }) => {
    let stores = this.state.cannonStoreList;
    let initialAndAssignedStores = this.state.initialAndAssignedStores;
    let pendingAndCompletedStores = this.state.pendingAndCompletedStores;
    if (stores.length !== 0) {
      if (value === "Initial Stores") {
        initialAndAssignedStores = [];
        pendingAndCompletedStores = [];
        stores.forEach((data) => {
          if (data.status === "Initial") {
            initialAndAssignedStores.push(data);
          }
        });
        this.props.fetchAuditorsAction();
      } else if (value === "Assigned Stores") {
        initialAndAssignedStores = [];
        pendingAndCompletedStores = [];
        stores.forEach((data) => {
          if (data.status === "Assigned") {
            initialAndAssignedStores.push(data);
          }
        });
        this.props.fetchAuditorsAction();
      } else if (value === "InProgress Stores") {
        initialAndAssignedStores = [];
        pendingAndCompletedStores = [];
        stores.forEach((data) => {
          if (data.status === "InProgress") {
            pendingAndCompletedStores.push(data);
          }
        });
      } else if (value === "Completed Stores") {
        pendingAndCompletedStores = [];
        initialAndAssignedStores = [];
        stores.forEach((data) => {
          if (data.status === "Completed") {
            pendingAndCompletedStores.push(data);
          }
        });
      }
    }
    this.setState({
      value,
      pendingAndCompletedStores,
      initialAndAssignedStores,
      selection: [],
    });
  };

  openAssignModal = () => {
    this.setState({
      openAssignModal: true,
    });
  };

  closeAssignModal = () => {
    this.setState({
      openAssignModal: false,
    });
  };

  saveandexit = (rowData, checkedValue) => {
    var selectedData = this.state.comingData;
    let data = {
      userId: rowData[0]._id,
      storeId: selectedData,
    };
    
    this.props.AssignCannonAuditsAction({
      data: data,
      onSuccess: () => {
        this.setState({
          openAssignModal: false,
          selection: [],
          comingData: []
        });
        this.props.fetchCannonStoreAction({
          templateId: this.props.template._id,
          onSuccess: () => { console.log("Success List : ", this.props.cannon.cannonStoreList); this.updateTableDate() },
          onFailure: () => {  console.log("failed") },
        });
        // this.componentDidMount()
        Toast.fire({
          icon: 'success',
          title: 'Store assigned successfully'
        })
      },
      onFailure: () => {
        console.log("Store not Assigned");
      },
    });
    this.setState({
      openAssignModal: false,
      comingData: [],
    });
  };

  sendedData = (rowData, checkedValue) => {
    let ids = [];

    if (rowData.length !== 0) {
      rowData.forEach((data) => {
        ids.push(data._id);
      });
    }
    this.setState({
      comingData: ids,
    });
  };

  render() {
    var userList =
      this.state.auditorList.length !== 0 ? this.state.auditorList : [];

    var col = [
      {
        title: "Region",
        field: "region",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.region}
            </span>
          );
        },
      },
      {
        title: "State",
        field: "state",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.state}
            </span>
          );
        },
      },
      {
        title: "City",
        field: "city",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.city}
            </span>
          );
        },
      },

      {
        title: "CIS Version",
        field: "cisVersion",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.cisVersion}
            </span>
          );
        },
      },
      {
        title: "Customer Number",
        field: "customerNo",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.customerNo}
            </span>
          );
        },
      },
      {
        title: "Distributor Id",
        field: "distributorId",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.distributorId}
            </span>
          );
        },
      },
      {
        title: "Store Name",
        field: "partnersName",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.partnersName}
            </span>
          );
        },
      },
      {
        title: "Ship Site Location",
        field: "shipSiteLocation",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.shipSiteLocation}
            </span>
          );
        },
      },
      {
        title: "Store Total score",
        field: "totalScore",
        render: (rowData) => {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.handleTableViewAuditClick(rowData);
              }}
            >
              {rowData.totalScore}
            </span>
          );
        },
      },
    ];

    const UserColumn = [
      {
        width: 25,
        Header: "E-Mail",
        accessor: "email",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.email}</span>;
        },
      },
    ];

    return (
      <>
        {this.state.isStoreView && (
          <div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <IconPicker
                name="arrow left"
                click={this.onClose}
                styles={{
                  color: getTextColor(this.props.color),
                  float: "right",
                  cursor: "pointer",
                  display: "inline-block",
                  position: "absolute",
                  right: 10,
                }}
              />

              <h1
                style={{
                  color: getTextColor(this.props.color),
                  display: "inline-block",
                }}
              >
                Canon Stores
              </h1>
              <div style={{ display: "inline-block" }}>
                <Checkbox
                  radio
                  label={
                    <ConstantLabel
                      title={"Initial Stores"}
                      size={"medium"}
                      styles={{
                        cursor: "pointer",
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                      }}
                    />

                  }
                  value="Initial Stores"
                  checked={this.state.value === "Initial Stores"}
                  onChange={this.handleChange}
                  style={{ marginLeft: 10 }}
                />
                <Checkbox
                  radio
                  label={
                    <ConstantLabel
                      title={"Assigned Stores"}
                      size={"medium"}
                      styles={{
                        cursor: "pointer",
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                      }}
                    />

                  }
                  value="Assigned Stores"
                  checked={this.state.value === "Assigned Stores"}
                  onChange={this.handleChange}
                  style={{ marginLeft: 10 }}
                />
                <Checkbox
                  radio
                  // label="InProgress Stores"
                  label={
                    <ConstantLabel
                      title={"InProgress Stores"}
                      size={"medium"}
                      styles={{
                        cursor: "pointer",
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                      }}
                    />

                  }
                  value="InProgress Stores"
                  checked={this.state.value === "InProgress Stores"}
                  onChange={this.handleChange}
                  style={{ marginLeft: 10 }}
                />
                <Checkbox
                  radio
                  label={
                    <ConstantLabel
                      title={"Completed Stores"}
                      size={"medium"}
                      styles={{
                        cursor: "pointer",
                        backgroundColor: getMainBackground(this.props.color),
                        color: getFontColor(this.props.color),
                      }}
                    />

                  }
                  value="Completed Stores"
                  checked={this.state.value === "Completed Stores"}
                  onChange={this.handleChange}
                  style={{ marginLeft: 10 }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: 10 }}>
              {this.state.initialAndAssignedStores.length > 0 ? (
                <div style={{ marginLeft: "auto" }}>
                  {this.state.value === "Initial Stores" && (
                    <ButtonHandler
                      color={'teal'}
                      click={this.openAssignModal}
                      size={'medium'}
                      title={'Assign Stores'}
                      disabled={this.state.comingData.length === 0}
                    />

                  )}
                  {this.state.value === "Assigned Stores" && (
                    <ButtonHandler
                      color={'teal'}
                      click={this.openAssignModal}
                      size={'medium'}
                      title={'ReAssign Stores'}
                      disabled={this.state.comingData.length === 0}
                    />

                  )}
                </div>
              ) : null}
            </div>
            <ModalView
              open={this.state.openAssignModal}
              close={this.closeAssignModal}
              size={'small'}
              content={
                <CheckboxforAssign
                  data={userList}
                  columns={UserColumn}
                  rowClick={this.handleTableViewAuditClick}
                  sendedData={this.saveandexit}
                />
              }
              showSave={false}
            />

          </div>
        )}
        {this.state.isStoreView && (
          <>
            {(this.state.initialAndAssignedStores.length === 0 &&
              this.state.value === "All") ||
              this.state.value === "InProgress Stores" ||
              this.state.value === "Completed Stores" ? (
              <TableView
                selection={false}
                data={
                  this.state.value === "All"
                    ? this.state.cannonStoreList
                    : this.state.value === "InProgress Stores"
                      ? this.state.pendingAndCompletedStores
                      : this.state.value === "Completed Stores"
                        ? this.state.pendingAndCompletedStores
                        : []
                }
                columns={col}
              />
            ) : (
              <TableView
                selection={true}
                data={this.state.initialAndAssignedStores}
                columns={col}
                onSelectionChange={(rows, row) => this.sendedData(rows, row)}
              />
            )}
          </>
        )}
        {!this.state.isStoreView && (
          <CannonProduct
            onClose={this.backtoStore}
            selectedTemplate={this.props.template}
            SelectedStore={this.state.selectedStore}
            status={this.state.storeStatus}
            color={this.props.color}
          />
        )}
      </>
    );
  }
}

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //reducer props to state
    cannon: state.cannon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCannonProductAction,
      fetchAuditorsAction,
      AssignCannonAuditsAction,
      fetchCannonStoreAction,
      fetchCannonHygineAction,
      fetchCannonApplianceAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CannonStore)
);

// export default CannonIndex;
