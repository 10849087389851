import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";

export const fetchAuditsAction = ({
  queryObejct,
  audits,
  month,
  clearFilter,
  onSuccess,
  onFailure,
}) => {
  if (clearFilter) {
    return {
      type: types.CLEAR_SEARCHED_AUDITS_ACTION,
    };
  }

  let activePageKey, isSearchAction;
  let appliedFilters;
  let filtered;
  if (!queryObejct.pageIndex.directNaviationKey) {
    let { pageIndex, pageSize } = queryObejct;
    let skip = pageIndex > 0 ? pageIndex * pageSize : 0;
    activePageKey = `/auditor/v1/audits?skip=${skip}&limit=${pageSize}&month=${month}`;
    filtered = queryObejct.filtered;
    isSearchAction = filtered && filtered.length > 0;
  } else {
    activePageKey = queryObejct.pageIndex.directNaviationKey;
    isSearchAction = queryObejct.pageIndex.isSearchAction;
  }

  //Filter maping and already fetched data logic start
  // Bellow action only for already fetched data

  let action = {
    type: isSearchAction
      ? types.ALL_READY_SEARCHED_AUDITS_ACTION
      : types.ALL_READY_FETCHED_AUDITS_ACTION,
    appliedFilters,
  };

  if (isSearchAction && !queryObejct.directNaviationKey) {
    appliedFilters = {};
    filtered = filtered.map((filter) => {
      let formated = {};
      switch (filter.value.type) {
        case "date":
          formated = {
            id: filter.id,
            type: filter.value.type,
            start: filter.value.start,
          };
          break;
        case "number":
        case "objectId":
        case "stringEq":
          formated = {
            id: filter.id,
            type: filter.value.type,
            value: filter.value.data,
          };
          break;
        default:
          formated = {
            id: filter.id,
            value: filter.value.data,
          };
      }
      appliedFilters[filter.value.filterName] = formated;
      return formated;
    });
    activePageKey = `${activePageKey}&q=${JSON.stringify(filtered)}`;
  }

  console.log(`%c${activePageKey}`, "color:blue;");

  if (audits[activePageKey]) {
    console.log(
      "%creturing already fetched data",
      "color:red;font-weight: bold;"
    );
    action.navigation = {
      activePageKey,
    };
    return action;
  }
  // Filter maping and already fetched data logic end
  return function (dispatch) {
    let start = Date.now();
    axios
      .get(encodeURI(`${types.API_URL}${activePageKey}`))
      .then(function (response) {
        console.log(
          `%cAPI Fetch complete in ${Date.now() - start}ms.`,
          "color:red;font-weight: bold;"
        );
        dispatch({
          type: isSearchAction
            ? types.SEARCH_AUDITS_SUCCESS_ACTION
            : types.FETCH_AUDITS_SUCCESS_ACTION,
          payload: response.data,
          navigation: {
            activePageKey,
          },
          appliedFilters,
        });
        onSuccess();
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: types.FETCH_AUDITS_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const Users = () => {
  return function (dispatch) {
    fetch("https://5f5ca5325e3a4d0016249893.mockapi.io/users")
      .then((response) => response.json())
      .then((data) => console.log(data));
  };
};

export const updateAuditAction = ({ auditId, audit, onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + "/auditor/v1/audits/" + auditId, audit)
      .then(function (response) {
        onSuccess(response);
        dispatch({
          type: types.UPDATE_AUDIT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.UPDATE_AUDIT_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};

export const downloadAuditReportAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/report/export?auditId=" + auditId, {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.xls");
        dispatch({
          type: types.DOWNLOAD_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAuditReportPhotoAction = (auditId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/report/audit_photos?auditId=" + auditId,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report-Photos.pdf");
        dispatch({
          type: types.DOWNLOAD_REPORT_PHOTO_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_REPORT_PHOTO_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const exportReportsAction = () => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/audits/export", {
        responseType: "blob",
      })
      .then(function (response) {
        FileSaver.saveAs(response.data, "Audit-Report.xlsx");
        dispatch({
          type: types.DOWNLOAD_AUDITS_DETAILS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_AUDITS_DETAILS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const mailAuditReportAction = (auditId, mailIds, ccIds) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL +
        "/auditor/v1/report/sendmail?auditId=" +
        auditId +
        "&mailIds=" +
        mailIds +
        "&ccIds=" +
        ccIds
      )
      .then(function (response) {
        dispatch({
          type: types.MAIL_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.MAIL_REPORT_SUCCESS_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchAgentAuditsAction = (templateType) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/audits/agent/" + templateType)
      .then(function (response) {
        dispatch({
          type: types.FETCH_AGENT_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_AGENT_AUDITS_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAgentReportAction = (agent) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/report/agents/" + agent._id + "/export",
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        var filename =
          agent["code"].toUpperCase() + "-" + agent["name"] + ".pdf";
        FileSaver.saveAs(response.data, filename);
        dispatch({
          type: types.DOWNLOAD_AGENT_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_AGENT_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const changeAgentStatusAction = (agentId) => {
  return function (dispatch) {
    axios
      .put(types.API_URL + "/auditor/v1/agent?agentId=" + agentId, {
        status: "notVerified",
      })
      .then(function (response) {
        dispatch({
          type: types.DOWNLOAD_AGENT_STATUS_CHANGE_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_AGENT_STATUS_CHANGE_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const downloadAgentVerificationStatusReportAction = (orgId) => {
  return function (dispatch) {
    axios
      .get(
        types.API_URL + "/auditor/v1/report/agent_verification_status/export",
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        FileSaver.saveAs(
          response.data,
          "Audit-Verification-Status-Report.xlsx"
        );
        dispatch({
          type: types.DOWNLOAD_REPORT_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.DOWNLOAD_REPORT_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};

export const fetchImagesOfAudit = (AuditId, tag) => {
  let endPoint = types.API_URL + "/auditor/v1/report/get_images/" + AuditId;
  if (tag) {
    endPoint = `${endPoint}?tag=${tag}`;
  }
  return function (dispatch) {
    axios
      .get(endPoint)
      .then(function (response) {
        dispatch({
          type: types.FETCH_AUDIT_IMAGES_SUCCESS_ACTION,
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.FETCH_AUDIT_IMAGES_FAILURE_ACTION,
          payload: error,
        });
      });
  };
};


export const getTanishqTemplate = ({ onSuccess, onFailure }) => {
  return function (dispatch) {
    axios
      .get(types.API_URL + "/auditor/v1/getTanishqTemplate")
      .then(function (response) {
        dispatch({
          type: types.GET_TANISHQ_TEMPLATE_SUCCESS_ACTION,
          payload: response.data,
        });
        onSuccess(response.data.templates);
      })
      .catch(function (error) {
        dispatch({
          type: types.GET_TANISHQ_TEMPLATE_FAILURE_ACTION,
          payload: error,
        });
        onFailure();
      });
  };
};
