import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isLoggedIn } from "../../util";
import { Card } from "semantic-ui-react";
import IconPicker from "../commonViews/IconPicker";
import { Table } from "semantic-ui-react";
import { horizontalBar } from "react-chartjs-2";
import ConstantLabel from "../commonViews/ConstantLabel";
import {
  getMainBackground,
  getMatchingTint,
  getFontColor,
  getTextColor,
} from "../../ThemeHandlers/theme";

class AuditScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return false;
    }
    return true;
  }

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  render() {
    var audit = this.props.audit;

    var dataLabels = [];
    var chartOptions = {
      maintainAspectRatio: true,
      responsive: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: "single",
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              tooltipItem.xLabel + " " + dataLabels[tooltipItem.datasetIndex]
            );
          },
        },
      },
    };

    var questionnaireScore = {
      labels: [],
      datasets: [
        {
          label: "# of Votes",
          data: [],
          backgroundColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderColor: [
            "rgba(255,99,132,1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    if (audit) {
      for (var score of audit.score) {
        questionnaireScore.labels.push(score.name);
        questionnaireScore.datasets[0].data.push(score.totalScoreInPercentage);
        dataLabels.push(score.name);
      }
    }

    return (
      <div style={{ flexGrow: 1, padding: 15 }}>
        <IconPicker
          styles={{
            color: "#808080",
            // marginTop: 5,
            marginRight: 20,
            float: "right",
            cursor: "pointer",
          }}
          click={this.props.onClose}
          size={"large"}
          name={"arrow left"}
        />

        <div style={{ marginRight: 50 }}>
          <div>
            <div style={{ display: 'flex', flexDirection: "row", flex: 1, alignItems: 'center' }}>
              <h1 style={{ color: getTextColor(this.state.color) }}>
                {audit.auditName}
              </h1>
            </div>
            <div
              style={{
                color: getTextColor(this.state.color),
                fontsize: "20px",
                fontWeight: "bold",
                paddingBottom: "15px",
                marginLeft: "5px",
                marginTop: "-5px",
              }}
            >
              {audit.location}
            </div>
            <ConstantLabel
              title={"View Details"}
              size={"medium"}
              onClick={this.props.onViewDetails}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.state.color),
                color: getFontColor(this.state.color),
              }}
            />
            <ConstantLabel
              title={"Download Report"}
              size={"medium"}
              onClick={this.props.onDownloadReport}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.state.color),
                color: getFontColor(this.state.color),
              }}
              iconName={'table'}
            />

            <ConstantLabel
              title={"Show Photos"}
              size={"medium"}
              onClick={this.props.onDownloadPhotos}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.state.color),
                color: getFontColor(this.state.color),
              }}
              iconName={'picture'}
            />

            <ConstantLabel
              title={"Mail Report"}
              size={"medium"}
              onClick={this.props.onMailReport}
              styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(this.state.color),
                color: getFontColor(this.state.color),
              }}
              iconName={'picture'}
            />

          </div>
          <ShowLsi
            audit={audit}
            color={this.state.color}
            getColor={getMainBackground}
            fontColor={getFontColor}
            textColor={getTextColor}
          />

          <h3 style={{ color: getTextColor(this.state.color) }}>
            Questionnaire Score
          </h3>
          <horizontalBar
            data={questionnaireScore}
            width={500}
            height={200}
            options={chartOptions}
          />

          <ScoreCard
            showFinalScore={true}
            title="Score By Questionnaire"
            scoreList={audit.score}
            color={this.state.color}
            getColor={getMainBackground}
            getRowColor={getMatchingTint}
            textColor={getTextColor}
            fontColor={getFontColor}
          />

          <ShowScores
            audit={audit}
            color={this.state.color}
            getColor={getMainBackground}
            getRowColor={getMatchingTint}
            textColor={getTextColor}
            fontColor={getFontColor}
          />
        </div>
      </div>
    );
  }
}

function ScoreCard(props) {
  var scoreList;

  if (props.scoreList) {
    scoreList = props.scoreList;
  }

  if (props.scoreMap) {
    scoreList = [];
    for (var name in props.scoreMap) {
      if (props.scoreMap.hasOwnProperty(name)) {
        scoreList.push(
          Object.assign(
            {
              name: name,
            },
            props.scoreMap[name]
          )
        );
      }
    }
  }

  return (
    <Card fluid>
      <Card.Content style={{ background: props.getColor(props.color) }}>
        <Card.Header style={{ color: props.fontColor(props.color) }}>
          {props.title}
        </Card.Header>
      </Card.Content>
      <Card.Content style={{ textAlign: "center" }}>
        <Table style={{ backgroundColor: props.getRowColor(props.color) }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{
                  backgroundColor: props.getColor(props.color),
                  color: props.fontColor(props.color),
                }}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  textAlign: "center",
                  backgroundColor: props.getColor(props.color),
                  color: props.fontColor(props.color),
                }}
              >
                Max Score
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  textAlign: "center",
                  backgroundColor: props.getColor(props.color),
                  color: props.fontColor(props.color),
                }}
              >
                Total Score
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  textAlign: "center",
                  backgroundColor: props.getColor(props.color),
                  color: props.fontColor(props.color),
                }}
              >
                Percentage Score
              </Table.HeaderCell>
              {props.showFinalScore && (
                <Table.HeaderCell
                  style={{
                    textAlign: "center",
                    backgroundColor: props.getColor(props.color),
                    color: props.fontColor(props.color),
                  }}
                >
                  Final Score
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {scoreList.map((s, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell style={{ width: 300 }}>{s.name}</Table.Cell>
                  <Table.Cell style={{ width: 100, textAlign: "center" }}>
                    {s.maxScore}
                  </Table.Cell>
                  <Table.Cell style={{ width: 100, textAlign: "center" }}>
                    {s.totalScore}
                  </Table.Cell>
                  <Table.Cell style={{ width: 100, textAlign: "center" }}>
                    {s.totalScoreInPercentage}
                  </Table.Cell>
                  {props.showFinalScore && (
                    <Table.Cell style={{ width: 100, textAlign: "center" }}>
                      {s.finalScore}
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  );
}

function ShowLsi(props) {
  if (props.audit.auditName != "World of Titan Audit") {
    return (
      <h1 style={{ color: props.textColor(props.color) }}>
        LSI{" "}
        <span style={{ color: props.textColor(props.color) }}>
          {props.audit.lsi}
        </span>
      </h1>
    );
  } else {
    return <div />;
  }
}

function ShowScores(props) {
  if (props.audit.auditName != "World of Titan Audit") {
    return (
      <div>
        <ScoreCard
          title="Score By Section"
          scoreMap={props.audit.scoreBySection}
          color={props.color}
          getColor={props.getColor}
          getRowColor={props.getRowColor}
          textColor={props.textColor}
          fontColor={props.fontColor}
        />

        <ScoreCard
          title="Best Practice Score"
          scoreMap={props.audit.scoreByTag}
          color={props.color}
          getColor={props.getColor}
          getRowColor={props.getRowColor}
          textColor={props.textColor}
          fontColor={props.fontColor}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditScore)
);
