import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  Input,
  Divider,
  Select,
  Radio,
  Image,
  Form,
} from "semantic-ui-react";
import ModalView from "../commonViews/ModalView.jsx";
import IconPicker from "../commonViews/IconPicker.jsx";
import ButtonHandler from '../commonViews/ButtonHandler.jsx'
import {
  fetchExcessMaster,
  saveTrackerAuditAction,
  uploadDamagesPvSheetAction,
} from "../../actions/damages_actions";
import config from "../../config.js";
import moment from "moment";
import Table from "../commonTables/Table.jsx";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import ConstantLabel from "../commonViews/ConstantLabel";
import TextInput from "../commonViews/TextInput";

const storageBaseUrl = config["storage_base_url"];
class DamagesAuditsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTc: null,
      showPopup: false,
      editMode: false,
      selectedPhoto: "",
      showPhotoSlider: false,
      damagescurrentAudit: this.props.selectedAudit || {},
      currentTemplate: this.props.selectedTemplate,
      modalOpen: false,
      trackerOpen: false,
      pvOpen: false,
      awCode: "",
      skuCode: "",
      skuName: "",
      qty: "",
      pkd: "",
      settlement: "",
      claim: "",
      tracker: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedAudit._id != prevProps.selectedAudit._id) {
      this.setState({
        damagescurrentAudit: this.props.selectedAudit,
        editMode: false,
      });
    }
    if (this.props.damages.tracker !== undefined) {
      if (this.props.damages.tracker !== prevProps.damages.tracker) {
        this.setState({
          tracker: this.props.damages.tracker,
        });
      }
    }
  }

  componentDidMount() {
    this.props.fetchExcessMaster();
    this.setState({ damagescurrentAudit: this.props.selectedAudit });
  }

  damageshandlePhotoClick = (e, p) => {
    this.setState({
      selectedPhoto: p,
      showPhotoSlider: true,
    });
  };

  damagesphotoSliderClose = (e) => {
    this.setState({
      showPhotoSlider: false,
    });
  };

  damageschangeAnswer = (damagesquestion, damagesanswerObject) => {
    var damagesanswer = "";
    let damagesaudit = this.state.damagescurrentAudit;
    if (damagesquestion.answerType == "text") {
      damagesanswer = damagesanswerObject.target.value;
    }
    if (damagesquestion.answerType == "number") {
      damagesanswer = damagesanswerObject.target.value;
    }
    if (damagesquestion.answerType == "yes/no") {
      damagesanswer = damagesanswerObject;
      if (damagesquestion.templateStatus) {
        damagesaudit.templateStatus =
          damagesanswer.toLowerCase() == "yes"
            ? damagesquestion.templateStatus.yes
            : null;
      }
    }
    if (damagesquestion.answerType == "dropdown") {
      damagesanswer = damagesanswerObject;
    }
    damagesaudit.questions.map((damageseachQuestion) => {
      if (damageseachQuestion.id == damagesquestion.id) {
        damageseachQuestion.answer = damagesanswer;
      }
    });
    this.setState({ damagescurrentAudit: damagesaudit });
  };

  damageseditAudit = () => {
    this.setState({ editMode: true });
  };

  damagescancelEditAudit = () => {
    this.setState({ editMode: false });
  };

  damagessaveAudit = () => {
    this.props.saveAudit(this.state.damagescurrentAudit);
    this.setState({ editMode: false });
  };

  damagesonDownloadPhotos = (id) => {
    let open = `#/images/${id}?tag=damages`;
    if (this.state.damagescurrentAudit.masterInfo) {
      let awCode = "";
      let awName = "";
      if (this.state.damagescurrentAudit.masterInfo.awCode === undefined) {
        awCode = " ";
      } else {
        awCode = this.state.damagescurrentAudit.masterInfo.awCode;
      }

      if (this.state.damagescurrentAudit.masterInfo.awName === undefined) {
        awName = " ";
      } else {
        awName = this.state.damagescurrentAudit.masterInfo.awName;
      }
      open = `${open}&awCode=${awCode}&awName=${awName}`;
    }
    window.open(open);
  };

  damagesonDownloadReport = (id) => {
    this.props.downloadAuditReport(id);
  };
  excessmaster = () => {
    this.setState({ modalOpen: true });
  };
  closeEditUser = () => {
    // this.props.clearUserAction();
    this.setState({ modalOpen: false, trackerOpen: false, pvOpen: false });
  };
  excesstracker = () => {
    this.setState({ trackerOpen: true });
  };
  awCode = (e) => {
    this.setState({ awCode: e });
  };
  skuCode = (e) => {
    this.setState({ skuCode: e });
  };
  skuName = (e) => {
    this.setState({ skuName: e });
  };
  qty = (e) => {
    this.setState({ qty: e });
  };
  pkd = (e) => {
    this.setState({ pkd: e });
  };
  settlement = (e) => {
    this.setState({ settlement: e });
  };
  claim = (e) => {
    this.setState({ claim: e });
  };
  saveTracker = () => {
    var audits = {
      awCode: this.state.awCode,
      skuCode: this.state.skuCode,
      skuName: this.state.skuName,
      qty: this.state.qty,
      pkd: this.state.pkd,
      settlement: this.state.settlement,
      claim: this.state.claim,
      orgId: this.state.damagescurrentAudit.orgId,
      templateId: this.state.damagescurrentAudit.templateId,
      masterId: this.state.damagescurrentAudit.masterId,
    };
    this.props.saveTrackerAuditAction(audits);
  };

  uploadpvsheet = () => {
    this.setState({ pvOpen: true });
  };
  selectPvSheets = (e) => {
    this.setState({ selectPvSheets: e.target.files });
  };
  uploadPvSheets = () => {
    this.props.uploadDamagesPvSheetAction(
      this.state.selectPvSheets,
      this.state.damagescurrentAudit.masterId,
      {
        onSuccess: () => {
          this.setState({ pvOpen: false })
        }, onFailure: () => {
          this.setState({ pvOpen: true })

        }
      }
    );
  };

  render() {
    console.log(this.state.damagescurrentAudit, "audited data");
    var damagesselectedAudit = this.state.damagescurrentAudit;
    var damagesactiveTemplateStatus = damagesselectedAudit.templateStatus;
    var template = this.state.currentTemplate;
    if (this.state.tracker != undefined) {
      var tracker = this.state.tracker.length !== 0 ? this.state.tracker : [];
    }
    const column = [
      {
        Header: "Product Code",
        accessor: "product",
        width: 150,
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.product}</span>;
        },
      },
      {
        Header: "SKU",
        accessor: "sku",
        width: 170,
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.sku}</span>;
        },
      },
      {
        Header: "Brand",
        accessor: "brand",
        width: 200,
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.brand}</span>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        style: { textAlign: "left", cursor: "pointer" },
        Cell: ({ row: { original } }) => {
          return <span>{original.description}</span>;
        },
      },
    ];
    return (
      <div>
        <ModalView
          open={this.state.pvOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                type="file"
                onChange={this.selectPvSheets}
                multiple
                style={{ marginLeft: 70 }}
              />
              <ButtonHandler
                color="blue"
                styles={{ marginTop: 10, marginRight: 30, marginLeft: 40 }}
                click={this.uploadPvSheets}
                title={'Upload'}

              />

            </div>
          }
          showSave={false}
        />

        <ModalView
          open={this.state.modalOpen}
          close={this.closeEditUser}
          size={'large'}
          content={
            <div>
              <Table
                rowClick={() => {
                  return;
                }}
                data={tracker}
                columns={column}
              />
            </div>
          }
          showSave={false}
        />


        <ModalView
          open={this.state.trackerOpen}
          close={this.closeEditUser}
          size={'small'}
          content={
            <Form>
              <Form.Field>
                <label>AW Code</label>
                <TextInput placeholder={"Aw Code"} value={this.state.awCode} onChangeTextInput={(e) => { this.awCode(e.target.value) }} />

              </Form.Field>
              <Form.Field>
                <label>SKU Code</label>
                <TextInput placeholder={"Sku Code"} value={this.state.skuCode} onChangeTextInput={(e) => { this.skuCode(e.target.value) }} />


              </Form.Field>
              <Form.Field>
                <label>SKU Name</label>
                <TextInput placeholder={"Sku Name"} value={this.state.skuName} onChangeTextInput={(e) => { this.skuName(e.target.value) }} />


              </Form.Field>
              <Form.Field>
                <label>Qty</label>
                <TextInput placeholder={"Qty"} value={this.state.qty} onChangeTextInput={(e) => { this.qty(e.target.value) }} />


              </Form.Field>
              <Form.Field>
                <label>PKD</label>
                <TextInput placeholder={"Pkd"} value={this.state.pkd} onChangeTextInput={(e) => { this.pkd(e.target.value) }} />


              </Form.Field>
              <Form.Field>
                <label>% of Settlement(0%/50%/100%)</label>
                <TextInput placeholder={"% of Settlement"} value={this.state.settlement} onChangeTextInput={(e) => { this.settlement(e.target.value) }} />


              </Form.Field>
              <Form.Field>
                <label>Claim reference number</label>
                <TextInput placeholder={"Claim reference number"} value={this.state.claim} onChangeTextInput={(e) => { this.claim(e.target.value) }} />


              </Form.Field>
            </Form>
          }
          saveText={'Add'}
          saveClick={this.saveTracker}
          showSave={true}
        />



        {this.props.selectedAudit && (
          <div>
            <DamagesHeaderView
              selectedAudit={this.props.selectedAudit}
              role={this.props.auth.role}
              editMode={this.state.editMode}
              damagesonDownloadReport={this.damagesonDownloadReport}
              damagesonDownloadPhotos={this.damagesonDownloadPhotos}
              damageseditAudit={this.damageseditAudit}
              damagessaveAudit={this.damagessaveAudit}
              uploadpvsheet={this.uploadpvsheet}
              excessmaster={this.excessmaster}
              excesstracker={this.excesstracker}
              damagescancelEditAudit={this.damagescancelEditAudit}
              color={this.props.color}
            />
            <ModalView
              open={this.state.showPhotoSlider}
              close={this.damagesphotoSliderClose}
              content={
                <Image
                  fuild
                  centered
                  src={
                    storageBaseUrl + this.state.selectedPhoto.uploadedImageUrl
                  }
                />

              }
              showSave={false}
            />

            {damagesselectedAudit.questions != null &&
              damagesselectedAudit.questions
                .filter((damageseachQuestion) => {
                  if (damageseachQuestion.hasOwnProperty("dependency")) {
                    let damagesquestion = null;
                    let damagesquestionId =
                      damageseachQuestion.dependency.questionId;
                    let damagesanswerNeeded =
                      damageseachQuestion.dependency.answer;
                    damagesselectedAudit.questions.map((damages) => {
                      if (damages.id === damagesquestionId) {
                        damagesquestion = damages;
                      }
                    });
                    if (damagesquestion.answer != damagesanswerNeeded) {
                      return false;
                    }
                    return true;
                  } else {
                    return true;
                  }
                })
                .map((damageseachQuestion, index) => {
                  if (
                    damagesactiveTemplateStatus &&
                    template.templateStatus[damagesactiveTemplateStatus] &&
                    !template.templateStatus[
                      damagesactiveTemplateStatus
                    ].includes(damageseachQuestion.id)
                  ) {
                    return;
                  }
                  return (
                    <div key={index}>
                      <div
                        key={damageseachQuestion.id}
                      // style={{ paddingTop: "20px", paddingBottom: "10px" }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            paddingBottom: "20px",
                            color: getTextColor(this.props.color),
                          }}
                        >
                          {damageseachQuestion.question}
                        </div>
                        {damageseachQuestion.answerType == "text" && (
                          <TextInput
                            disabled={!this.state.editMode}
                            value={damageseachQuestion.answer}
                            onChangeTextInput={(e) => {
                              this.damageschangeAnswer(damageseachQuestion, e)

                            }}
                            styles={{ width: 300 }}

                          />

                        )}
                        {damageseachQuestion.answerType == "number" && (
                          <TextInput
                            disabled={!this.state.editMode}
                            value={damageseachQuestion.answer}
                            onChangeTextInput={(e) => {
                              this.damageschangeAnswer(damageseachQuestion, e)

                            }}
                            styles={{ width: 300 }}
                          />

                        )}

                        {damageseachQuestion.answerType == "yes/no" && (
                          <div>
                            <Radio
                              style={{
                                marginRight: "20px",
                                fontWeight: "bold",
                              }}
                              label={"Yes"}
                              value={"Yes"}
                              checked={damageseachQuestion.answer === "Yes"}
                              disabled={!this.state.editMode}
                              onChange={(e, { value }) =>
                                this.damageschangeAnswer(
                                  damageseachQuestion,
                                  value
                                )
                              }
                            />
                            <Radio
                              style={{ marginLeft: "20px", fontWeight: "bold" }}
                              label={"No"}
                              value={"No"}
                              checked={damageseachQuestion.answer === "No"}
                              disabled={!this.state.editMode}
                              onChange={(e, { value }) =>
                                this.damageschangeAnswer(
                                  damageseachQuestion,
                                  value
                                )
                              }
                            />
                          </div>
                        )}
                        {damageseachQuestion.answerType == "dropdown" && (
                          <div>
                            <Select
                              disabled={!this.state.editMode}
                              options={damageseachQuestion.options.map(
                                (eachOption, i) => {
                                  return {
                                    value: eachOption.value,
                                    text: eachOption.label,
                                    key: i,
                                  };
                                }
                              )}
                              placeholder={"Select any option"}
                              value={damageseachQuestion.answer}
                              onChange={(i, { value }) =>
                                this.damageschangeAnswer(
                                  damageseachQuestion,
                                  value
                                )
                              }
                            />
                          </div>
                        )}
                        {damageseachQuestion.answerType == "photo" && (
                          <Image.Group
                            size="mini"
                            style={{ cursor: "pointer" }}
                          >
                            {damageseachQuestion.photos != undefined &&
                              damageseachQuestion.photos.map((p, i) => {
                                if (p.uploadedImageUrl != null) {
                                  return (
                                    <Image
                                      key={i}
                                      src={storageBaseUrl + p.uploadedImageUrl}
                                      onClick={((k) => {
                                        return (e) =>
                                          this.damageshandlePhotoClick(e, k);
                                      })(p)}
                                    />
                                  );
                                }
                              })}
                          </Image.Group>
                        )}
                        <br></br>
                        {damageseachQuestion.fileUpload == true &&
                          damageseachQuestion.documents &&
                          damageseachQuestion.documents.length > 0 && (
                            <a
                              href={
                                storageBaseUrl +
                                damageseachQuestion.documents[0]
                                  .uploadedImageUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconPicker
                                size={"large"}
                                color={"red"}
                                name={"file pdf outline"}
                              />
                            </a>
                          )}
                        {damageseachQuestion.answeredTime && (
                          <div
                            style={{ marginTop: "10px", marginLeft: "16px" }}
                          >
                            On -{" "}
                            <span
                              style={{
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {moment(damageseachQuestion.answeredTime).format(
                                "llll"
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <Divider />
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    );
  }
}

const DamagesHeaderView = function (props) {
  let {
    selectedAudit,
    editMode,
    damagesonDownloadPhotos,
    damagesonDownloadReport,
    uploadpvsheet,
    excesstracker,
    excessmaster,
    damageseditAudit,
    damagessaveAudit,
    damagescancelEditAudit,
    role,
  } = props;
  return (
    <div>
      <h1 style={{ color: getTextColor(props.color) }}>
        {selectedAudit.auditName}
      </h1>
      {role !== "viewer" && (
        <div>
          <div
            style={{
              color: "#494949",
              fontsize: "15px",
              fontWeight: "100",
              paddingBottom: "10px",
              marginLeft: "10px",
              marginTop: "-15px",
            }}
          >
            {selectedAudit.location}
          </div>

          <ConstantLabel
            title={"Show Photos"}
            size={"medium"}
            onClick={() => {
              damagesonDownloadPhotos(selectedAudit._id);
            }} styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
            }}
            iconName={"picture"}
          />

          {!editMode && (

            <ConstantLabel
              title={"Edit Audit"}
              size={"medium"}
              onClick={damageseditAudit} styles={{
                cursor: "pointer",
                backgroundColor: getMainBackground(props.color),
                color: getFontColor(props.color),
              }}
              iconName={"edit"}
            />
          )}

          {editMode && (
            <div style={{ marginTop: "5px", marginBottom: 10 }}>

              <ConstantLabel
                title={"Save Audit"}
                size={"medium"}
                onClick={damagessaveAudit} styles={{
                  cursor: "pointer",
                  marginRight: "5px",
                  backgroundColor: getMainBackground(props.color),
                  color: getFontColor(props.color),
                }}
                iconName={"save"}
              />

              <ConstantLabel
                title={"Cancel"}
                size={"medium"}
                onClick={damagescancelEditAudit} styles={{
                  cursor: "pointer", marginLeft: "5px"
                }}
                iconName={"cancel"}
              />
            </div>
          )}


          <ConstantLabel
            title={"SKU Master"}
            size={"medium"}
            onClick={() => {
              excessmaster();
            }} styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
            }}
            iconName={"table"}
          />





          <ConstantLabel
            title={"Upload Pv Sheet"}
            size={"medium"}
            onClick={() => {
              uploadpvsheet();
            }} styles={{
              cursor: "pointer",
              backgroundColor: getMainBackground(props.color),
              color: getFontColor(props.color),
            }}
            iconName={"edit"}
          />
        </div>
      )}
      <div>
        By{" "}
        {selectedAudit.auditorId != null && selectedAudit.auditorId.displayName}{" "}
        on {moment(selectedAudit.createdAt).format("DD-MM-YYYY  HH:MM:SS")}
      </div>
    </div>
  );
};

function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    damages: state.damages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      saveAudit: ownProps.apis.saveAuditAction,
      downloadAuditReport: ownProps.apis.downloadReportAction,
      fetchExcessMaster: fetchExcessMaster,
      saveTrackerAuditAction: saveTrackerAuditAction,
      uploadDamagesPvSheetAction: uploadDamagesPvSheetAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DamagesAuditsDetail)
);
