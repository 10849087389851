import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Grid,
  Icon,
  Modal,
  Image,
} from "semantic-ui-react";
import ButtonHandler from "../commonViews/ButtonHandler";
import Chip from "@mui/material/Chip";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import {
  downloadtimesheetreportAction,
  fetchDateAction,
  fetchtimesheetMasterData,
  filterMasterDataAction,
  saveTimesheetData,
  saveTimeSheetRow,
  timesheetTemplateFilter,
} from "../../actions/timesheet_action";
import { isLoggedIn } from "../../util";
import MaterialTable from "material-table";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";
import config from "../../config.js";
import {
  getTextColor,
  getMainBackground,
  getMatchingTint,
  getFontColor,
} from "../../ThemeHandlers/theme";
import { getOptions } from "../../util";
import DatePicker from "../commonViews/DatePicker";
import DropDownPicker from "../commonViews/DropDownPicker.jsx";
const storageBaseUrl = config["storage_base_url"];
var startPhoto = "";
var endPhoto = "";
// var auditvalue = []
var clientvalue = [{ text: "", value: "", key: "" }];

class TimeSheetTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsforaudittype: "",
      optionsforlocation: "",
      optionsformanager: "",
      optionsforauditor: "",
      optionsforclientname: "",
      timesheettemplate: [],
      fromDate: null,
      toDate: null,
      photoModal: false,
      url: "",
      historyModal: false,
      historyActionTime: [],
      starttime: [],
      endtime: [],
      startPhoto: "",
      endPhoto: "",
      auditdropdown: false,
      auditvalue: [],
      historyData: {},
      clientName: "",
      location: "",
      auditType: "",
      startTime: "",
      endTime: "",
      createdAt: "",
      modaleditData: false,
      clientNameChange: "",
      auditTypeChange: "",
      locationChange: "",
      startTimeChange: "",
      endTimeChange: "",
      filtered: [],
      color: "",
      location: [],
      manager: [],
      auditor: [],
      dateError: false,
      load: false,
    };
  }

  processData = (audits) => {
    if (audits !== undefined && audits.length !== 0) {
      audits.map((eachItem) => {
        if (eachItem.plannedDate !== undefined) {
          let startDate = eachItem.plannedDate;
          let startTime =
            eachItem.startedTime !== undefined
              ? eachItem.startedTime
              : "00:00:00";
          let endTime =
            eachItem.endedTime !== undefined ? eachItem.endedTime : "00:00:00";
          let reversedDate = startDate.split("-").reverse().join("-");
          let formParsedDate = `${reversedDate}T${startTime}`;
          let formEndDate = `${reversedDate}T${endTime}`;
          if (!("formStartTime" in eachItem)) {
            eachItem["formStartTime"] = `${formParsedDate}`;
          } else {
            eachItem["formStartTime"] = `${formParsedDate}`;
          }
          if (!("formEndTime" in eachItem)) {
            eachItem["formEndTime"] = `${formEndDate}`;
          } else {
            eachItem["formEndTime"] = `${formEndDate}`;
          }
        }
      });
    }
    return audits;
  };

  componentDidMount() {
    if (!isLoggedIn(this.props.auth)) {
      this.props.history.push(`/login`);
      return;
    }
    if (this.props.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
    this.props.fetchmasterData({
      onSuccess: (data) => {
        let audit = this.processData(data.audits);
        this.setState({ timesheettemplate: audit });
      },
      onFailure: () => { },
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.auth.color !== prevProps.auth.color) {
      this.setState({ color: this.props.auth.color });
    }
  }

  handleFromDate = (date) => {
    let change = {
      clientName:
        this.state.optionsforclientname !== ""
          ? this.state.optionsforclientname
          : undefined,
      auditType:
        this.state.optionsforaudittype !== ""
          ? this.state.optionsforaudittype
          : undefined,
      location:
        this.state.optionsforlocation !== ""
          ? this.state.optionsforlocation
          : undefined,
      managerName:
        this.state.optionsformanager !== ""
          ? this.state.optionsformanager
          : undefined,
      auditorName:
        this.state.optionsforauditor !== ""
          ? this.state.optionsforauditor
          : undefined,

      plannedStartDate: moment(date).format("DD-MM-YYYY"),
    };
    var output = JSON.parse(JSON.stringify(change));
    this.props.timesheetTemplateFilter({
      data: output,
      onSuccess: (data) => {
        let audit = this.processData(data.audits);

        this.setState({
          load: false,
          timesheettemplate: audit,

          fromDate: date,
        });
      },
      onFailure: (err) => {
        this.setState({ load: false });
      },
    });
  };

  handleToDate = (date) => {
    let change = {
      clientName:
        this.state.optionsforclientname !== ""
          ? this.state.optionsforclientname
          : undefined,
      auditType:
        this.state.optionsforaudittype !== ""
          ? this.state.optionsforaudittype
          : undefined,
      location:
        this.state.optionsforlocation !== ""
          ? this.state.optionsforlocation
          : undefined,
      managerName:
        this.state.optionsformanager !== ""
          ? this.state.optionsformanager
          : undefined,
      auditorName:
        this.state.optionsforauditor !== ""
          ? this.state.optionsforauditor
          : undefined,

      plannedStartDate:
        this.state.fromDate !== null
          ? moment(this.state.fromDate).format("DD-MM-YYYY")
          : undefined,
      plannedEndDate: moment(date).format("DD-MM-YYYY"),
    };
    var output = JSON.parse(JSON.stringify(change));
    this.props.timesheetTemplateFilter({
      data: output,
      onSuccess: (data) => {
        let audit = this.processData(data.audits);

        this.setState({
          load: false,
          timesheettemplate: audit,

          toDate: date,
        });
      },
      onFailure: (err) => {
        this.setState({ load: false });
      },
    });
  };

  download = () => {
    this.props.reportDownload();
  };

  filterDate = (form) => {
    this.props.fetchDateDetails({
      forms: form,
      onSuccess: (data) => {
        this.setState({ filtered: data.audits });
      },
      onFailure: () => { },
    });
  };

  handleimage = (data) => {
    startPhoto = data.startPhoto;
    endPhoto = data.endPhoto;
    this.setState({ photoModal: true });
  };

  handlehistory = (data) => {
    this.setState({ historyModal: true });
    let datalength =
      data.PausedTime !== undefined ? data.PausedTime.length : null;

    var timepush = [];
    if (datalength !== null) {
      for (let i = 0; i < datalength; i++) {
        // if (i % 2 == 0) {
        if (data.PausedTime[i].resume) {
          timepush.push({
            key: "Resume Time",
            value: data.PausedTime[i].resume,
          });
        }
        if (data.PausedTime[i].pause) {
          timepush.push({
            key: "Paused Time",
            value: data.PausedTime[i].pause,
          });
        }
      }
      this.setState({ historyActionTime: timepush, historyData: data });
    }
  };

  ClosephotoModal = () => {
    this.setState({ photoModal: false });
  };

  closehistorymodal = () => {
    this.setState({
      historyModal: false,
      modaleditData: false,
      historyActionTime: [],
      historyData: [],
    });
  };

  emptyclick = () => { };

  editDataDetails = (data) => {
    this.setState({
      modaleditData: true,
      clientName: data.orgName,
      location: data.location,
      auditType: data.auditType,
      startTime: data.startedTime,
      endTime: data.endedTime,
      createdAt: data.createdAt,
    });
  };

  onChangeClient = (e) => {
    this.setState({ clientNameChange: e.target.value });
  };

  onChangeAuditType = (e) => {
    this.setState({ auditTypeChange: e.target.value });
  };

  onChangeLocation = (e) => {
    this.setState({ locationChange: e.target.value });
  };

  onChaneStartTime = (e) => {
    this.setState({ startTimeChange: e.target.value });
  };

  onChangeEndTime = (e) => {
    this.setState({ endTimeChange: e.target.value });
  };

  saveTimesheet = () => {
    let data = {
      orgName:
        this.state.clientNameChange == ""
          ? this.state.clientName
          : this.state.clientNameChange,
      auditType:
        this.state.auditTypeChange == ""
          ? this.state.auditType
          : this.state.auditTypeChange,
      location:
        this.state.locationChange == ""
          ? this.state.location
          : this.state.locationChange,
      startedTime:
        this.state.startTimeChange == ""
          ? this.state.startTime
          : this.state.startTimeChange,
      endedTime:
        this.state.endTimeChange == ""
          ? this.state.endTime
          : this.state.endTimeChange,
      createdAt: this.state.createdAt,
    };

    this.props.saveTimesheetData(data, {
      onSuccess: () => {
        this.setState({ modaleditData: false });
        alert("Timesheet Update Successfully");
      },
      onFailure: () => { },
    });
  };

  fetchFiltered = (data) => {
    this.setState({ load: true });
    this.props.timesheetTemplateFilter({
      data,
      onSuccess: (data) => {
        let audit = this.processData(data.audits);

        this.setState({ load: false, timesheettemplate: audit });
      },
      onFailure: (err) => {
        this.setState({ load: false });
      },
    });
  };

  changeAuditType = (data, currenttype) => {
    if (currenttype === "orgName") {
      this.setState({ load: true });
      let filter = { clientName: data };
      let answer = data;
      this.props.timesheetTemplateFilter({
        data: filter,
        onSuccess: (data) => {
          let audit = this.processData(data.audits);
          let auditType = getOptions(answer, audit, currenttype, "auditType");

          this.setState({
            load: false,
            timesheettemplate: audit,
            auditvalue: auditType,
            optionsforclientname: answer,
            optionsforaudittype: "",
            optionsforlocation: "",
            optionsformanager: "",
            optionsforauditor: "",
            fromDate: null,
            toDate: null,
          });
        },
        onFailure: (err) => {
          this.setState({ load: false });
        },
      });
    } else if (currenttype === "auditType") {
      this.setState({ load: true });
      let filter = {
        clientName: this.state.optionsforclientname,
        auditType: data,
      };
      let answer = data;
      this.props.timesheetTemplateFilter({
        data: filter,
        onSuccess: (data) => {
          let audit = this.processData(data.audits);
          let location = getOptions(answer, audit, currenttype, "location");
          this.setState({
            load: false,
            timesheettemplate: audit,
            location: location,
            optionsforaudittype: answer,
            optionsforlocation: "",
            optionsformanager: "",
            optionsforauditor: "",
            fromDate: null,
            toDate: null,
          });
        },
        onFailure: (err) => {
          this.setState({ load: false });
        },
      });
    } else if (currenttype === "location") {
      this.setState({ load: true });
      let filter = {
        clientName: this.state.optionsforclientname,
        auditType: this.state.optionsforaudittype,
        location: data,
      };
      let answer = data;
      this.props.timesheetTemplateFilter({
        data: filter,
        onSuccess: (data) => {
          let audit = this.processData(data.audits);
          let manager = getOptions(answer, audit, currenttype, "managerName");
          this.setState({
            load: false,
            timesheettemplate: audit,
            manager: manager,
            optionsforlocation: answer,
            optionsformanager: "",
            optionsforauditor: "",
            fromDate: null,
            toDate: null,
          });
        },
        onFailure: (err) => {
          this.setState({ load: false });
        },
      });
    } else if (currenttype === "managerName") {
      this.setState({ load: true });
      let filter = {
        clientName: this.state.optionsforclientname,
        auditType: this.state.optionsforaudittype,
        location: this.state.optionsforlocation,
        managerName: data,
      };
      let answer = data;
      this.props.timesheetTemplateFilter({
        data: filter,
        onSuccess: (data) => {
          let audit = this.processData(data.audits);
          let auditor = getOptions(answer, audit, currenttype, "auditorName");
          this.setState({
            load: false,
            timesheettemplate: audit,
            auditor: auditor,
            optionsformanager: answer,
            optionsforauditor: "",
            fromDate: null,
            toDate: null,
          });
        },
        onFailure: (err) => {
          this.setState({ load: false });
        },
      });
    } else if (currenttype === "auditor") {
      this.setState({ load: true });
      let filter = {
        clientName: this.state.optionsforclientname,
        auditType: this.state.optionsforaudittype,
        location: this.state.optionsforlocation,
        managerName: this.state.optionsformanager,
        auditorName: data,
        fromDate: null,
        toDate: null,
      };
      let answer = data;
      this.props.timesheetTemplateFilter({
        data: filter,
        onSuccess: (data) => {
          let audit = this.processData(data.audits);

          this.setState({
            load: false,
            timesheettemplate: audit,
            optionsforauditor: answer,
          });
        },
        onFailure: (err) => {
          this.setState({ load: false });
        },
      });
    }
  };

  reset = () => {
    this.setState({ load: true });
    this.props.fetchmasterData({
      onSuccess: (data) => {
        let audit = this.processData(data.audits);
        this.setState({
          timesheettemplate: audit,
          optionsforclientname: "",
          optionsformanager: "",
          optionsforauditor: "",
          optionsforaudittype: "",
          optionsforlocation: "",
          load: false,
          fromDate: null,
          toDate: null,
        });
      },
      onFailure: () => {
        this.setState({ load: false });
      },
    });
  };
  changeRequest = (row, answer) => {
    let res = this.state.timesheettemplate;

    if (res.length !== 0) {
      res.forEach((data) => {
        if (data._id === row._id) {
          if (data.formStartTime !== undefined) {
            data.formStartTime = answer;
          }
        }
      });
    }
    // return res;
    this.setState({ timesheettemplate: res });
  };

  render() {
    //filterclientname
    var filterclientname = this.state.timesheettemplate;
    var filterlength = filterclientname.length;

    for (let i = 0; i < filterlength; i++) {
      let optionvalue = filterclientname[i].orgName;
      let bool_val = true;
      clientvalue.map((data) => {
        if (data.key === optionvalue) {
          bool_val = false;
        }
      });
      if (bool_val) {
        clientvalue.push({
          key: optionvalue,
          text: optionvalue,
          value: optionvalue,
        });
      }
    }

    var col = [
      {
        title: "Client Name",
        field: "orgName",
        editable: "never",
      },
      {
        title: "Audit Type",
        field: "auditType",
        editable: "never",
      },
      {
        title: "Location",
        field: "city",
        editable: "never",
      },
      {
        title: "Manager Name",
        field: "managerName",
        editable: "never",
      },
      {
        title: "Auditor Name",
        field: "empName",
        editable: "never",
      },

      {
        title: "Planned Date (DD:MM:YYYY)",
        field: "plannedDate",
        editable: "never",
      },
      {
        title: "Audit Start Time (HH:MM:SS)",
        field: "startedTime",
        editable: "onUpdate",

        editComponent: (props) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
              ampm={false}
              views={["hours", "minutes", "seconds"]}
              format="HH:mm:ss"
              value={
                props.rowData.formStartTime !== undefined
                  ? new Date(props.rowData.formStartTime)
                  : ""
              }
              minutesStep={1}
              onChange={(date) => {
                var startTime = moment(
                  date._d.toTimeString().split(" ")[0],
                  "HH:mm:ss"
                );
                var endTime = moment(props.rowData.endedTime, "HH:mm:ss");
                var duration = moment.duration(endTime.diff(startTime));
                // duration in hours
                var hours = parseInt(duration.asHours());

                // duration in minutes
                var minutes = parseInt(duration.asMinutes()) % 60;
                var seconds = duration.seconds();

                let reversedDate = props.rowData.plannedDate
                  .split("-")
                  .reverse()
                  .join("-");
                let formParsedDate = `${reversedDate}T${date._d.toTimeString().split(" ")[0]
                  }`;
                if (hours < 10 && minutes < 10) {
                  props.rowData.totalHours = `0${hours}:0${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                    }`;
                } else if (hours < 10 && minutes > 10) {
                  props.rowData.totalHours = `0${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                    }`;
                } else if (hours > 10 && minutes < 10) {
                  props.rowData.totalHours = `${hours}:0${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                    }`;
                } else if (hours > 10 && minutes > 10) {
                  props.rowData.totalHours = `${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                    }`;
                } else {
                  props.rowData.totalHours = `${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                    }`;
                }
                props.rowData.formStartTime = new Date(formParsedDate);
                props.onChange(date._d.toTimeString().split(" ")[0]);
              }}
            />
          </MuiPickersUtilsProvider>
        ),
      },
      {
        title: "Audit End Time (HH:MM:SS)",
        field: "endedTime",
        editable: "onUpdate",
        editComponent: (props) => (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
              ampm={false}
              views={["hours", "minutes", "seconds"]}
              format="HH:mm:ss"
              value={
                props.rowData.formEndTime !== undefined
                  ? new Date(props.rowData.formEndTime)
                  : ""
              }
              minutesStep={1}
              onChange={(date) => {
                var str1 = props.rowData.startedTime;
                var str2 = date._d.toTimeString().split(" ")[0];

                str1 = str1.split(":");
                str2 = str2.split(":");
                let totalSeconds1 = parseInt(
                  str1[0] * 3600 + str1[1] * 60 + str1[0]
                );
                let totalSeconds2 = parseInt(
                  str2[0] * 3600 + str2[1] * 60 + str2[0]
                );

                if (totalSeconds1 < totalSeconds2) {
                  let reversedDate = props.rowData.plannedDate
                    .split("-")
                    .reverse()
                    .join("-");
                  let formParsedDate = `${reversedDate}T${date._d.toTimeString().split(" ")[0]
                    }`;
                  var startTime = moment(props.rowData.startedTime, "HH:mm:ss");
                  var endTime = moment(
                    date._d.toTimeString().split(" ")[0],
                    "HH:mm:ss"
                  );
                  var duration = moment.duration(endTime.diff(startTime));
                  // duration in hours
                  var hours = parseInt(duration.asHours());
                  var seconds = duration.seconds();

                  // duration in minutes
                  var minutes = parseInt(duration.asMinutes()) % 60;
                  if (hours < 10 && minutes < 10) {
                    props.rowData.totalHours = `0${hours}:0${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`;
                  } else if (hours < 10 && minutes > 10) {
                    props.rowData.totalHours = `0${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`;
                  } else if (hours > 10 && minutes < 10) {
                    props.rowData.totalHours = `${hours}:0${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`;
                  } else if (hours > 10 && minutes > 10) {
                    props.rowData.totalHours = `${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`;
                  } else {
                    props.rowData.totalHours = `${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`;
                  }

                  props.rowData.formEndTime = new Date(formParsedDate);
                  props.onChange(date._d.toTimeString().split(" ")[0]);
                } else {
                  alert(
                    "End Time should be greater than start time to calculate total hours"
                  );
                }
              }}
            />
          </MuiPickersUtilsProvider>
        ),
      },
      {
        title: "Total Hours (HH:MM:SS)",
        field: "totalHours",
        // hidden: true,
        render: (rowData) => {
          if (rowData.totalHours !== undefined) {
            return (
              <Chip
                label={
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      letterSpacing: 1,
                    }}
                  >
                    {rowData.totalHours}
                  </span>
                }
                color="primary"
                variant="outlined"
              />
            );
          }
        },
        // editable: "never",
        editComponent: ({ value, onChange }) => (
          <span></span>
          // <input
          //   type="text"
          //   value={value || ""}
          //   onChange={(e) => onChange(e.target.value)}
          // />
        ),
      },
    ];

    const steps = [
      "Select campaign settings",
      "Create an ad group",
      "Create an ad",
    ];
    let dropStyle = {
      zIndex: 200,
      width: "100%",
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          margin: "2px",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <NotificationContainer />
        <Grid>
          <Grid.Row columns={2} style={{ margin: 5 }}>
            <Grid.Column>
              <h1 style={{ color: getTextColor(this.state.color) }}>
                TimeSheet Tracker
              </h1>
            </Grid.Column>

            <Grid.Column textAlign="right">
              <ButtonHandler
                size={'medium'}
                styles={{
                  backgroundColor: getMainBackground(this.state.color),
                  color: getFontColor(this.state.color),
                  cursor: "pointer",
                }}
                click={this.download}
                title={'Download Report'}
                isIcon={true}
                iconName={'download'}
              />

            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={5}>
            <Grid.Column>
              <DropDownPicker
                placeholder={"Select ClientName"}
                options={clientvalue}
                value={this.state.optionsforclientname}
                styles={dropStyle}
                onChangeDropDown={(data) => {
                  this.changeAuditType(data, "orgName");
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <DropDownPicker
                placeholder={"Select AuditType"}
                options={this.state.auditvalue}
                styles={dropStyle}
                value={this.state.optionsforaudittype}
                onChangeDropDown={(data) => {
                  this.changeAuditType(data, "auditType");
                }}
              />

            </Grid.Column>
            <Grid.Column>
              <DropDownPicker
                placeholder={"Select Location"}
                options={this.state.location}
                styles={dropStyle}
                value={this.state.optionsforlocation}
                onChangeDropDown={(data) => {
                  this.changeAuditType(data, "location");
                }}
              />

            </Grid.Column>
            <Grid.Column>
              <DropDownPicker
                placeholder={"Select Manager"}
                options={this.state.manager}
                styles={dropStyle}
                value={this.state.optionsformanager}
                onChangeDropDown={(data) => {
                  this.changeAuditType(data, "managerName");
                }}
              />

            </Grid.Column>
            <Grid.Column>
              <DropDownPicker
                placeholder={"Select Auditor"}
                options={this.state.auditor}
                styles={dropStyle}
                value={this.state.optionsforauditor}
                onChangeDropDown={(data) => {
                  this.changeAuditType(data, "auditor");
                }}
              />

            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={5}>
            <Grid.Column>
              <DatePicker
                value={this.state.fromDate}
                handleDateChange={(date) => {
                  this.handleFromDate(date);
                }}
                label={"Planned Start Date"}
              />
            </Grid.Column>
            <Grid.Column>
              <DatePicker
                value={this.state.toDate}
                handleDateChange={(date) => {
                  this.handleToDate(date);
                }}
                label={"Planned End Date"}
                minDate={
                  this.state.fromDate !== null
                    ? new Date(this.state.fromDate._d)
                    : undefined
                }
              />
            </Grid.Column>

            <Grid.Column width={2}>
              <ButtonHandler
                size={'medium'}
                styles={{
                  backgroundColor: getMainBackground(this.state.color),
                  color: getFontColor(this.state.color),
                  cursor: "pointer",
                }}
                disabled={this.state.optionsforclientname === ""}
                click={this.reset}
                title={'Reset Filter'}
                isIcon={true}
                iconName={'redo'}
              />

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <MaterialTable
                columns={col}
                data={
                  this.state.filtered.length !== 0
                    ? this.state.filtered
                    : this.state.timesheettemplate
                }
                isLoading={this.state.load}
                options={{
                  actionsColumnIndex: -1,
                  showTitle: false,
                  toolbar: false,
                  filtering: true,
                  sorting: false,
                }}
                actions={[
                  {
                    icon: "history",
                    tooltip: "History",
                    onClick: (event, rowData) => {
                      // Do save operation
                      this.handlehistory(rowData);
                    },
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...this.state.timesheettemplate];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        // setData([...dataUpdate]);
                        this.props.saveTimeSheetRow({
                          data: newData,
                          onSuccess: (res) => {
                            console.log(res);
                            NotificationManager.success(
                              "Data updated successfully",
                              "Success",
                              3000,
                              true
                            );
                          },
                          onFailure: (err) => {
                            console.log(err);
                            NotificationManager.error(
                              "Data Updation Failed",
                              "Error",
                              3000,
                              true
                            );
                          },
                        });
                        this.setState({ timesheettemplate: [...dataUpdate] });
                        resolve();
                      }, 1000);
                    }),
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal
          size="Medium"
          open={this.state.photoModal}
          onClose={this.ClosephotoModal}
          centered={false}
        >
          <Modal.Content
            style={{ backgroundColor: getMatchingTint(this.state.color) }}
          >
            {startPhoto !== undefined && endPhoto !== undefined && (
              <Image.Group size="medium" style={{ marginBottom: "10px" }}>
                <Image src={storageBaseUrl + startPhoto} />
                <Image src={storageBaseUrl + endPhoto} />
              </Image.Group>
            )}
          </Modal.Content>
          <Modal.Actions>
            <ButtonHandler
              color={'red'}
              click={this.ClosephotoModal}
              title={'Close'}
            />

          </Modal.Actions>
        </Modal>

        <Modal
          size="medium"
          open={this.state.historyModal}
          onClose={this.closehistorymodal}
        // centered={false}
        >
          <Modal.Content
            style={{ backgroundColor: getMatchingTint(this.state.color) }}
          >
            <div>
              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ wordSpacing: 5 }}>
                  <Icon size={30} color="green" name="calendar alternate" />
                  <nsbp />
                  <nsbp />
                  Audit Date :{" "}
                  {moment(this.state.historyData.createdAt).format(
                    "DD-MM-YYYY"
                  )}
                </h2>
              </div>
              <h2 style={{ wordSpacing: 5 }}>
                <Icon size={30} color="orange" name="time" />
                Start Time : {this.state.historyData.startedTime}
              </h2>
              <h2 style={{ wordSpacing: 5 }}>
                <Icon size={30} color="orange" name="time" />
                Paused Time : {this.state.historyData.pauseTime}
              </h2>
              {this.state.historyActionTime.map((history) => {
                return (
                  <h2 style={{ wordSpacing: 5 }}>
                    <Icon size={30} color="orange" name="time" />
                    {history.key} : {history.value}
                  </h2>
                );
              })}
              <h2 style={{ wordSpacing: 5 }}>
                <Icon size={30} color="orange" name="time" />
                End Time : {this.state.historyData.endedTime}
              </h2>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ButtonHandler
              color={'red'}
              click={this.closehistorymodal}
              title={'Close'}
            />

          </Modal.Actions>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    time: state.time,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchmasterData: fetchtimesheetMasterData,
      fetchDateDetails: fetchDateAction,
      filteredMasterData: filterMasterDataAction,
      reportDownload: downloadtimesheetreportAction,
      saveTimesheetData: saveTimesheetData,
      saveTimeSheetRow: saveTimeSheetRow,
      timesheetTemplateFilter: timesheetTemplateFilter,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeSheetTemplate)
);
